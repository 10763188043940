import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MetaData from '../Layouts/MetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteAddress, getUserAddresses, setDefaultAddress } from '../../actions/addressAction';
import { clearDefaultAddress, clearDeleteAddress } from '../../slices/AddressSlice';
import { Slide, toast } from 'react-toastify';
import Loader from '../Layouts/Loader';
import LoaderButton from '../Layouts/LoaderButton';
import Sidebar from "./Sidebar";
import { getUser, loadUser } from '../../actions/userActions';
import CryptoJS from 'crypto-js';

const UserAddressList = ({ isActive, setIsActive }) => {
    // const { user, loading } = useSelector(state => state.authState);
    const { user, loading } = useSelector(state => state.userState);
    const { deleteSuccess, deleteloading, deleteerror, getdata, defaultloading, defaultSuccess, defaulterror } = useSelector(state => state.addressState);
    const [confirmDelete, setConfirmDelete] = useState(null); // track which address to delete
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [walletBalance, setWalletBalance] = useState(0);
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
   

    useEffect(() => {
        if (user && user._id !== id) {
            dispatch(getUser(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (!user) {
            dispatch(getUser(id));
        }
    }, [])


    useEffect(() => {
        if (deleteSuccess) {
            toast.dismiss();
            setTimeout(() => {
                toast.success(deleteSuccess.message, {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => {
                        dispatch(clearDeleteAddress());
                        dispatch(getUser(id));
                        setConfirmDelete(null);
                    },
                });
            }, 10);
        }
        if (deleteerror) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(deleteerror, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearDeleteAddress()); },
                });
            }, 10);
        }
        // if (defaultSuccess) {
        //     toast.dismiss();
        //     setTimeout(() => {
        //         toast.success(defaultSuccess.message, {
        //             position: 'bottom-center',
        //             type: 'success',
        //             autoClose: 700,
        //             transition: Slide,
        //             hideProgressBar: true,
        //             className: 'small-toast',
        //             onOpen: () => {
        //                 dispatch(clearDefaultAddress());
        //                 dispatch(getUserAddresses({ userId: id }));
        //             },
        //         });
        //     }, 10);
        // }
        // if (defaulterror) {
        //     toast.dismiss();
        //     setTimeout(() => {
        //         toast.error(defaulterror, {
        //             position: 'bottom-center',
        //             type: 'error',
        //             autoClose: 700,
        //             transition: Slide,
        //             hideProgressBar: true,
        //             className: 'small-toast',
        //             onOpen: () => { dispatch(clearDefaultAddress()); },
        //         });
        //     }, 10);
        // }
    }, [deleteSuccess, deleteerror, dispatch, user, defaultSuccess, defaulterror]);

    const handleDelete = (addressId) => {
        setConfirmDelete(addressId); // set the specific addressId for deletion confirmation
    };

    const handleConfirmDelete = () => {
        if (confirmDelete) {
            dispatch(deleteAddress({ userId: id, addressId: confirmDelete }));
        }
    };

    const handleCancelDelete = () => {
        setConfirmDelete(null);
    };

    const decryptData = (walletBalance, encryptionKey) => {
        const bytes = CryptoJS.AES.decrypt(walletBalance, encryptionKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    useEffect(() => {
        if (user?.walletBalance) {
            const walletbalance = decryptData(user?.walletBalance, encryptionKey);
            setWalletBalance(walletbalance);
        }
        if (!user?.walletBalance) {
            console.warn("User walletBalance is undefined or null");
            return;
        }

    }, [user]);


    return (
        <div className="row loader-parent">
            <div className="col-12 col-md-2">
                <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                    <Sidebar isActive={isActive} setIsActive={setIsActive} />
                </div>
            </div>

            <div className='col-12 col-md-10 smalldevice-space loader-parent'>
                <MetaData title="Address" />

                <h1 className="mb-4 admin-dashboard-x">User Details</h1>
                <div className="">
                    <span className="back-button" onClick={() => navigate(-1)}>
                        <ArrowBackIcon fontSize="small" />
                        <span>Back</span>
                    </span>

                </div>
                <div className="customer-details">
                    {/* <h2>User Details</h2> */}
                    <div className="customer-detail-item">
                        <strong>Name:</strong> {user?.name ? user.name : 'N/A'}
                    </div>
                    <div className="customer-detail-item">
                        <strong>Mobile/Email:</strong> {user?.email ? user.email : user?.mobile}
                    </div>
                    <div className="customer-detail-item">
                        <strong>Gender:</strong> {user?.gender || 'N/A'}
                    </div>
                    <div className="customer-detail-item">
                        {/* <strong>WalletBalance: <span style={{ color: walletBalance > 0 ? '#006400' : 'black' }}>{walletBalance || 0}</span></strong> */}
                        <strong>WalletBalance: <span style={{ color: Number(walletBalance) > 0 ? '#006400' : 'black' }}>{walletBalance ? Number(walletBalance).toFixed(2) : 0}</span></strong>
                        <Link to={`/admin/user/wallet/${id}`} style={{ color: 'blue', textDecoration: 'underline',marginLeft:'20px',fontSize:'13px' }}>
                            ViewTransactions...
                        </Link>
                    </div>
                </div>
                <hr />
                {/* <div className="address-container"> */}

                {/* <div className="address-wrapper">
                        {loading ? (
                            <Loader />
                        ) : user?.shippingInfo && user.shippingInfo.length > 0 ? (
                            <>
                                <h2>Address</h2>
                                {user.shippingInfo.map((address) => (
                                    <div className="address-card" key={address._id}>
                                        <div className="address-info">
                                            <div>{address.name}</div>
                                            <div>+91 {address.phoneNo}</div>
                                            <div className="address-formatted">{address.formattedAddress}</div>
                                        </div>
                                        <div className="address-actions">
                                            <DeleteIcon
                                                className="action-icon delete-icon"
                                                onClick={() => handleDelete(address._id)}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <div className="no-address-message">
                                No address found.
                            </div>
                        )}
                    </div> */}

                <div className="user-address-section-wrapper">
                    <div className="user-address-container">
                        {loading ? (
                            <Loader />
                        ) : user?.shippingInfo && user.shippingInfo.length > 0 ? (
                            <>
                                <h2 className="user-address-heading">Address List</h2>
                                <div className="user-address-grid">
                                    {user.shippingInfo.map((address) => (
                                        <div className="user-address-card" key={address._id}>
                                            <div className="user-address-content">
                                                <p className="user-address-name">{address.name}</p>
                                                <p className="user-address-phone">+91 {address.phoneNo}</p>
                                                <p className="user-address-text">{address.formattedAddress}</p>
                                            </div>
                                            {/* <div className="user-address-controls">
                                                    <DeleteIcon
                                                        className="user-delete-button"
                                                        onClick={() => handleDelete(address._id)}
                                                    />
                                                </div> */}
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <div className="user-no-address-message">
                                No address found.
                            </div>
                        )}
                    </div>

                    {/* </div> */}


                </div>

                {confirmDelete && (
                    <div className="modal" tabIndex="-1" role="dialog" style={modalStyle}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Confirm Delete</h5>
                                    <button type="button" className="close" onClick={handleCancelDelete}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure you want to delete this address?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" onClick={handleConfirmDelete} disabled={deleteloading}>
                                        {deleteloading ? <LoaderButton fullPage={false} size={20} /> : (
                                            <span> Delete</span>
                                        )

                                        }

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 1000
};

export default UserAddressList;