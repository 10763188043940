import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import Loader from '../Layouts/Loader';
import { orderDetail as orderDetailAction } from '../../actions/orderActions';
import { CancelOrderResponse, createPorterOrderResponse, getPackedOrder, getporterOrder, packedOrder } from "../../actions/porterActions";
import axios from 'axios';
import JasInvoice from '../Layouts/JasInvoice';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import ReactDOM from 'react-dom';
import Sidebar from './Sidebar';
import { porterClearData } from '../../slices/porterSlice';
import { clearError } from '../../slices/orderSlice';
import { Slide, toast } from 'react-toastify';
import MetaData from '../Layouts/MetaData';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export default function AdminOrderDetail({ isActive, setIsActive }) {
    const { error, orderDetail, loading } = useSelector(state => state.orderState)
    const { shippingInfo = {}, user = {}, orderStatus = "Processing", orderItems = [], totalPrice = 0, paymentInfo = {} } = orderDetail;
    const { porterOrderData, porterOrderResponse, porterCancelResponse, porterCancelError, portererror, getpackedOrderData } = useSelector((state) => state.porterState);
    const isPaid = paymentInfo && paymentInfo.status === "succeeded" ? true : false;
    const dispatch = useDispatch();
    const { id } = useParams();
    const [payment, setPayment] = useState(null)
    const invoiceRef = useRef();
    const location = useLocation();
    const [refreshData, setRefreshData] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(orderDetailAction(id));
        dispatch(getPackedOrder({ order_id: id }))
        dispatch(getporterOrder({ order_id: id }))
        setRefreshData(true)
    }, [dispatch, id])
    useEffect(() => {
        if (porterOrderData && refreshData) {
            dispatch(createPorterOrderResponse({ order_id: porterOrderData && porterOrderData.order_id, porterOrder_id: porterOrderData?.porterOrder?.order_id }))
        }
    }, [porterOrderData])

    useEffect(() => {
        if (error) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(error, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearError()) }
                });
            }, 300);
            return;
        }
    }, [error])

    useEffect(() => {
        if (refreshData && porterOrderResponse) {
            dispatch(getporterOrder({ order_id: id }))
            setRefreshData(false)
        }
    }, [refreshData, porterOrderResponse])
    // const handlePrint = useReactToPrint({
    //     content: () => invoiceRef.current,
    // });

    const getInvoiceHtmlWithStyles = () => {
        const invoiceHtml = invoiceRef.current.outerHTML;

        // Get the contents of the app.css file as a string
        const appCss = `
          <style>
            /* You can manually copy the CSS from your app.css or use inline styles */
            .invoice-container {
  width: 90%;
  margin: auto;
  font-family: 'Times New Roman', Times, serif;
  padding: 20px;
  font-size: 15px;
  font-weight: bold
}

.invoice-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}


.invoice-header .top-info {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.invoice-header .top-info div {
  margin-left: 20px;
}

.invoice-header .middle-info {
  display: flex;
  justify-content: space-between;
}

.invoice-header .middle-info div {
  flex: 1;
  margin: 0 10px;
}

.invoice-header .middle-info h4 {

  margin-bottom: 10px;
}

.invoice-header .bottom-info {
  display: flex;
  justify-content: space-between;
}

.invoice-header .bottom-info div {
  flex: 1;
  margin: 10px 10px;

}

.bottom-data {
  border: 1px solid #000;
  width: fit-content;
  font-family: Arial, sans-serif;
}

.bottom-data p {
  border-bottom: 1px solid black;
  text-align: center;
  font-weight: bolder;
}

.bottom-data p:last-child {
  border-bottom: none;
}

.invoice-header h4,
.invoice-header h5,
.invoice-header p {
  margin: 0;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  page-break-inside: auto;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #000;
  text-align: center;
}

.invoice-table td {
  font-weight: 800px;
}

.invoice-table tbody tr {
  page-break-inside: avoid;
  /* Prevent row splitting */
}

/* Footer should always appear at the end of the table */
.invoice-table tfoot {
  display: table-footer-group;
  /* Ensure footer is part of the table's final rendering */
}


.signature {
  text-align: right;
  margin-top: 50px;
}

.hidden {
  display: none;
}

.form-select:focus {
  border: 1px solid #ced4da;
  box-shadow: none;
}


            /* Include other styles from app.css here */
          </style>
        `;

        return appCss + invoiceHtml; // Combine CSS and HTML
    };

    const handlePrint = useReactToPrint({
        content: () => invoiceRef.current,
        documentTitle: `Invoice_${id || 'unknown'}`,
        onAfterPrint: () => {
            // Optionally, notify Android after print
        },
    });

    const triggerAndroidPrint = () => {
        if (window.Android) {
            // Send the HTML content of the invoice to Android
            //   const invoiceHtml = invoiceRef.current.outerHTML;
            const invoiceHtmlWithStyles = getInvoiceHtmlWithStyles();
            //   if (invoiceHtml.trim() === "") {
            //     console.log("Invoice content is empty!");
            //     return;
            //   }
            window.Android.printWebView(invoiceHtmlWithStyles);
        } else {
            handlePrint(); // Regular browser print if not running in WebView
        }
    };

    const subtotal = getpackedOrderData && getpackedOrderData.dispatchedTable.reduce((acc, item) => {
        return acc + item.pricePerKg * item.dispatchedWeight;
    }, 0);


    return (
        <Fragment>
            <MetaData
                title="Admin Order Detail"
                description="View detailed information about a specific order, including product details, customer information, payment status, and dispatch status."
            />


            <div className="row loader-parent">
                <div className="col-12 col-md-2 ">
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div>

                <div className="col-12 col-md-10 smalldevice-space container order-detail-container loader-parent">
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <ArrowBackIcon fontSize="small" />
                        <span>Back</span>
                    </div>

                    {
                        loading ? (<div className="container loader-loading-center">
                            <Loader />
                        </div>) : (
                            <div className="col-12 col-lg-12 mt-5 order-details">

                                <h1 className="order-id-title mb-4">Order ID: {orderDetail.order_id}</h1>

                                <h4 className="shipping-title mb-2">Shipping Info</h4>
                                <div className="shipping-info">
                                    <div><b>Name:</b> {shippingInfo.name}</div>
                                    <div><b>Phone:</b> {shippingInfo.phoneNo}</div>
                                    <div className='order-address-formatted'>
                                        <b>Address:</b>
                                        {shippingInfo.address && `${shippingInfo.address},`}
                                        {shippingInfo.area && `${shippingInfo.area},`}
                                        {shippingInfo.landmark && `${shippingInfo.landmark},`}
                                        {shippingInfo.city && `${shippingInfo.city}`}
                                        {shippingInfo.postalCode && `-${shippingInfo.postalCode}`}
                                    </div>

                                    <div><b>Total Amount:</b> ₹ {totalPrice}</div>
                                    <div><b>Online Payment:</b> ₹ {orderDetail?.paidOnline}</div>
                                    <div><b>Wallet Payment:</b> ₹ {orderDetail?.paidWallet}</div>
                                    {orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.payment_method ? (
                                        <div><b>Payment Mode:</b> {orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.payment_method}</div>

                                    ) : (
                                        <div><b>Payment Mode:</b> {orderDetail && orderDetail?.payment_method}</div>
                                    )

                                    }
                                </div>
                                <hr />


                                <div className="status-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', margin: '10px', whiteSpace: 'nowrap' }}>
                                        <div><b>Payment Status:</b></div>
                                        <div style={{ color: orderDetail && orderDetail.paymentStatus === 'CHARGED' ? 'green' : 'red', marginLeft: '10px' }}>
                                            <b>{orderDetail && orderDetail.paymentStatus === 'CHARGED' ? 'Success' : orderDetail ? orderDetail.paymentStatus : 'Pending'}</b>
                                        </div>

                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
                                        <div><b>Order Status:</b></div>
                                        {/* <div style={{ color: orderStatus && orderStatus.includes('Delivered') ? 'green' : 'red', marginLeft: '10px' }}>
                                            <b>{orderStatus}</b>
                                        </div> */}
                                         <div style={{
                                                color: orderStatus && orderStatus.includes('Delivered') ? 'green' : 'red',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                margin: '10px',
                                                padding: '0px'
                                            }}>
                                                <b>
                                                    {orderStatus && orderStatus.includes('Delivered')
                                                        ? orderStatus
                                                        : (orderDetail?.paymentStatus === 'CHARGED' ? orderStatus : 'Cancelled')}
                                                </b>
                                            </div>
                                    </div>
                                    {getpackedOrderData && getpackedOrderData.totalRefundableAmount > 0 && (
                                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
                                            <div><b>Refund Status:</b></div>
                                            {/* <div style={{ color: orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.refunds && orderDetail.statusResponse.refunds[0].status === 'SUCCESS' ? 'green' : 'red', marginLeft: '10px' }}>
                                                <b>{orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.refunds ? orderDetail.statusResponse.refunds[0].status : 'Processing'}</b>
                                            </div> */}
                                            <div style={{ color: orderDetail && orderDetail?.statusResponse && orderDetail?.statusResponse?.refunds && orderDetail?.statusResponse?.refunds[0]?.status === 'SUCCESS' ? 'green' : getpackedOrderData && getpackedOrderData?.refundStatus === 'SUCCESS' ? 'green' : 'red', marginLeft: '10px' }}>
                                                <b>{orderDetail && orderDetail?.statusResponse?.refunds ? (
                                                    orderDetail && orderDetail?.statusResponse && orderDetail?.statusResponse?.refunds ? orderDetail?.statusResponse?.refunds[0].status : 'Processing'
                                                ) : (
                                                    getpackedOrderData && getpackedOrderData?.refundStatus ? getpackedOrderData?.refundStatus : 'Processing'
                                                )

                                                }
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                    {/* {orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.amount_refunded && orderDetail.statusResponse.amount_refunded > 0 && orderDetail.statusResponse.refunds && orderDetail.statusResponse.refunds[0].status === 'SUCCESS' ? (
                                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
                                            <div><b>Amount Refunded:</b></div>
                                            <div style={{ marginLeft: '10px' }}>
                                                <b>{orderDetail.statusResponse && orderDetail.statusResponse.amount_refunded}</b>
                                            </div>
                                        </div>
                                    ) :
                                        <>
                                        </>
                                    } */}
                                    {(orderDetail && orderDetail?.statusResponse && orderDetail?.statusResponse.amount_refunded && orderDetail?.statusResponse.amount_refunded > 0 && orderDetail?.statusResponse?.refunds && orderDetail?.statusResponse?.refunds[0].status === 'SUCCESS' || getpackedOrderData?.refundStatus === 'SUCCESS') ? (
                                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
                                            <div><b>Amount Refunded:</b></div>
                                            {/* <div style={{ marginLeft: '10px' }}>
                                                    <b>{(getpackedOrderData?.refundedWallet) + (orderDetail?.statusResponse && orderDetail?.statusResponse?.amount_refunded)}</b>
                                                </div> */}
                                            {
                                                orderDetail.statusResponse && orderDetail.statusResponse.amount_refunded ? (
                                                    <strong>
                                                        ₹ {orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.amount_refunded ? orderDetail.statusResponse.amount_refunded + getpackedOrderData?.refundedWallet : 0
                                                        }
                                                    </strong>
                                                ) : (
                                                    <strong>
                                                        ₹ {getpackedOrderData && getpackedOrderData?.refundedWallet ? getpackedOrderData?.refundedWallet : 0}
                                                    </strong>

                                                )
                                            }
                                        </div>
                                    ) :
                                        <>
                                        </>
                                    }
                                </div>
                                <hr />
                                <h4 className="order-items my-4">Order Items:</h4>

                                <div className="invoice-table-container">
                                    <div className="updatetable-responsive">
                                        <table className="updatetable updatetable-bordered">
                                            <thead>
                                                <tr>
                                                    {getpackedOrderData && getpackedOrderData.dispatchedTable ? (
                                                        <>
                                                            <th>S.No</th>
                                                            <th>Image</th>
                                                            <th>Name</th>
                                                            <th>Ordered Quantity</th>
                                                            <th>Price per kg</th>
                                                            <th>Dispatched Quantity</th>
                                                            <th>Refundable Quantity</th>
                                                            <th>Amount</th>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <th>S.No</th>
                                                            <th>Image</th>
                                                            <th>Name</th>
                                                            <th>Price per kg</th>
                                                            <th>Ordered Quantity</th>
                                                            <th>Total Price</th>
                                                        </>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getpackedOrderData && getpackedOrderData.dispatchedTable ? (
                                                    getpackedOrderData.dispatchedTable.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <img src={item.image} alt={item.name} className="updateTableproduct-image" />
                                                            </td>
                                                            <td>{item.name} {item.range}</td>
                                                            <td>{item.orderedWeight} {item.measurement}</td>
                                                            <td>₹ {item.pricePerKg}</td>
                                                            <td>{item.dispatchedWeight} {item.measurement}</td>
                                                            <td>{item.refundableWeight} {item.measurement}</td>
                                                            <td>₹ {parseFloat(item.pricePerKg * item.dispatchedWeight).toFixed(2)}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    orderItems.map((item, index) => {

                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <img src={item.image} alt={item.name} className="updateTableproduct-image" />
                                                                </td>
                                                                <td>{item.name} {item.range}</td>
                                                                <td>₹ {(item?.finalPrice ? item.finalPrice : item.price).toFixed(2)}</td>
                                                                <td>{item.productWeight} {item.measurement}</td>
                                                                <td>₹{(item.productWeight * (item?.finalPrice ? item.finalPrice : item.price)).toFixed(2)}</td>

                                                            </tr>
                                                        );
                                                    })
                                                )}

                                            </tbody>
                                            <tfoot>
                                                {getpackedOrderData && getpackedOrderData.dispatchedTable ? (
                                                    <>
                                                        <tr>
                                                            <td colSpan="7" style={{ textAlign: 'right' }}>
                                                                <strong>Subtotal</strong>
                                                            </td>
                                                            <td>
                                                                ₹ {subtotal.toFixed(2)}

                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan="7" style={{ textAlign: 'right' }}>
                                                                <strong>Shipping</strong>
                                                            </td>
                                                            <td>
                                                                {/* Rs. {parseFloat(orderDetail.shippingPrice).toFixed(2)} */}
                                                                {
                                                                    getpackedOrderData && getpackedOrderData.totalDispatchedAmount > 0 ?
                                                                        <>
                                                                            ₹ {parseFloat(orderDetail.shippingPrice).toFixed(2)}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            ₹ 0
                                                                        </>

                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="7" style={{ textAlign: 'right' }}>
                                                                <strong>Dispatched Amount</strong>
                                                            </td>
                                                            {
                                                                getpackedOrderData && getpackedOrderData.totalDispatchedAmount > 0 ?
                                                                    <td>
                                                                        ₹ {parseFloat(subtotal + orderDetail.shippingPrice).toFixed(2)}
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        ₹ {parseFloat(subtotal).toFixed(2)}
                                                                    </td>


                                                            }
                                                        </tr>

                                                    </>


                                                ) : (
                                                    <>
                                                        <tr>
                                                            <td colSpan="5" style={{ textAlign: 'right' }}>
                                                                <strong>Subtotal</strong>
                                                            </td>
                                                            <td>
                                                                ₹ {orderItems.reduce((total, item) => total + parseFloat(item.productWeight * (item?.finalPrice ? item.finalPrice : item.price)), 0).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="5" style={{ textAlign: 'right' }}>
                                                                <strong>Shipping</strong>
                                                            </td>
                                                            <td>
                                                                ₹ {parseFloat(orderDetail.shippingPrice).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="5" style={{ textAlign: 'right' }}>
                                                                <strong>Total Amount</strong>
                                                            </td>
                                                            <td>
                                                                ₹ {(orderItems.reduce((total, item) => total + parseFloat(item.productWeight * (item?.finalPrice ? item.finalPrice : item.price)), 0) + parseFloat(orderDetail.shippingPrice)).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div>

                                    {/* {orderStatus && orderStatus === 'Delivered' && (
                                        <div style={{ marginTop: '20px' }}>
                                            <button onClick={handlePrint} className='btn btn-primary'>Download Invoice</button>
                                            {ReactDOM.createPortal(
                                                <div style={{ position: 'absolute', top: '-9999px', left: '-9999px', zIndex: '-9999999999' }}>
                                                    <JasInvoice ref={invoiceRef} invoice={getpackedOrderData} />
                                                </div>,
                                                document.body
                                            )}
                                        </div>

                                    )

                                    } */}

                                    {(getpackedOrderData && porterOrderData) && (
                                        <div style={{ marginTop: '20px' }}>
                                            <button onClick={triggerAndroidPrint} className='btn btn-primary'>Download Invoice</button>
                                            {ReactDOM.createPortal(
                                                <div style={{ position: 'absolute', top: '-9999px', left: '-9999px', zIndex: '-9999999999' }}>
                                                    <JasInvoice ref={invoiceRef} invoice={getpackedOrderData} porterData={porterOrderData} />
                                                </div>,
                                                document.body
                                            )}
                                        </div>

                                    )
                                    }
                                </div>

                            </div>
                        )}
                </div>

            </div>
        </Fragment>
    )
}