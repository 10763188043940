import React, { Fragment, useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getCategories } from "../../actions/categoryAction";
import MetaData from "../Layouts/MetaData";
import Select from "react-select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Sidebar from "../admin/Sidebar";
import Loader from "../Layouts/Loader";
import NumberInput from "../Layouts/NumberInput";
import { createDiscount, deleteSingleDiscounts, getDiscounts } from "../../actions/discountAction";
import { clearDiscountDelete, clearGetdiscounts, clearNewDiscount } from "../../slices/discountSlices";
import { Slide, toast } from "react-toastify";
import LoaderButton from "../Layouts/LoaderButton";

const NewDiscount = ({ isActive, setIsActive }) => {
    const { products } = useSelector((state) => state.productsState);
    const { getcategory, getloading } = useSelector((state) => state.categoryState);
    const { discountLoading, discountData, discountError, discounts: alldiscounts, deleteSingleloading, discountDeleteSuccess, discountDeleteError } = useSelector((state) => state.discountState);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedTypes, setSelectedTypes] = useState([]);
    const [existingDiscountProducts, setExistingDiscountProducts] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [discounts, setDiscounts] = useState({});
    const [globalDiscount, setGlobalDiscount] = useState();
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [tableSearchQuery, setTableSearchQuery] = useState("");
    const [existingTableSearchQuery, setExistingTableSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [discountTitle, setDiscountTitle] = useState(""); // State for discount title
    const [existingProducts, setExistingProducts] = useState(null);
    const [existingDiscountValues, setExistingDiscountValues] = useState(null);
    const [productToDelete, setProductToDelete] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        dispatch(getCategories());
    }, [dispatch]);

    useEffect(() => {
        if (selectedCategories.length > 0) {
            const selectedCategoryNames = selectedCategories.map((cat) => cat.value);
            const filtered = products.filter((product) =>
                selectedCategoryNames.includes(product.category) &&
                !existingDiscountProducts.includes(product._id)
            );
            const existingProducts = products.filter((product) =>
                selectedCategoryNames.includes(product.category) &&
                existingDiscountProducts.includes(product._id)
            );
            const existingDiscountwithValue = [];
            alldiscounts?.forEach((discount) => {
                discount.discounts.forEach((discountItem) => {
                    // existingProducts.push(discountItem.productId);
                    existingDiscountwithValue[discountItem.productId] = discountItem.discount;
                });
            });
            setExistingDiscountValues(existingDiscountwithValue);
            setExistingProducts(existingProducts);
            setFilteredProducts(filtered);
            setSelectedProducts(filtered.map((product) => product._id));
            setDiscounts({});
        } else {
            setFilteredProducts([]);
            setSelectedProducts([]);
            setDiscounts({});
            setExistingDiscountValues(null);
            setExistingProducts(null);
        }
    }, [selectedCategories, products, alldiscounts]);

    useEffect(() => {
        if (selectedTypes.length > 0) {
            const filteredCategories = getcategory.filter((category) =>
                selectedTypes.some((type) => type.value === category.type)
            );
            setCategoryOptions(
                filteredCategories.map((category) => ({
                    value: category.category,
                    label: category.name,
                }))
            );
        }
    }, [selectedTypes, selectedCategories, getcategory]);

    useEffect(() => {
        // Extract productIds that already have a discount
        const existingProducts = [];
        const existingDiscountwithValue = [];
        alldiscounts?.forEach((discount) => {
            discount.discounts.forEach((discountItem) => {
                existingProducts.push(discountItem.productId);
                existingDiscountwithValue[discountItem.productId] = discountItem.discount;
            });
        });
        setExistingDiscountValues(existingDiscountwithValue);
        setExistingDiscountProducts(existingProducts);
    }, [alldiscounts]);

    useEffect(() => {
        if (!alldiscounts) {
            dispatch(getDiscounts());
        }
    }, [alldiscounts])


    const handleSearch = () => {
        const searchTerm = searchQuery.toLowerCase();
        const filtered = products.filter((product) =>
            product.englishName.toLowerCase().includes(searchTerm) &&
            !existingDiscountProducts.includes(product._id)
        );
        const existingProducts = products.filter((product) =>
            product.englishName.toLowerCase().includes(searchTerm) &&
            existingDiscountProducts.includes(product._id)
        );
        const existingDiscountwithValue = [];
        alldiscounts?.forEach((discount) => {
            discount.discounts.forEach((discountItem) => {
                // existingProducts.push(discountItem.productId);
                existingDiscountwithValue[discountItem.productId] = discountItem.discount;
            });
        });
        setExistingDiscountValues(existingDiscountwithValue);
        setExistingProducts(existingProducts);
        setFilteredProducts(filtered);
        setSelectedProducts(filtered.map((product) => product._id));
        setDiscounts({});
    };


    useEffect(() => {
        const searchTerm = existingTableSearchQuery.toLowerCase();

        // If the search query is empty, reset the filtered products to the original filtered state
        if (searchTerm === '') {
            if (selectedCategories.length > 0) {
                const selectedCategoryNames = selectedCategories.map((cat) => cat.value);
                const filtered = products.filter((product) =>
                    selectedCategoryNames.includes(product.category) &&
                    !existingDiscountProducts.includes(product._id)
                );
                const existingProducts = products.filter((product) =>
                    selectedCategoryNames.includes(product.category) &&
                    existingDiscountProducts.includes(product._id)
                );
                const existingDiscountwithValue = [];
                alldiscounts?.forEach((discount) => {
                    discount.discounts.forEach((discountItem) => {
                        // existingProducts.push(discountItem.productId);
                        existingDiscountwithValue[discountItem.productId] = discountItem.discount;
                    });
                });
                setExistingDiscountValues(existingDiscountwithValue);
                setExistingProducts(existingProducts);
                setFilteredProducts(filtered);
                setSelectedProducts(filtered.map((product) => product._id));
                setDiscounts({});
            } else {
                setFilteredProducts([]);
                setExistingDiscountValues(null);
                setExistingProducts(null);
                setSelectedProducts([]);
                setDiscounts({});
            }
        } else {
            const filtered = existingProducts?.filter((product) =>
                product.englishName.toLowerCase().includes(searchTerm) ||
                product.tamilName.toLowerCase().includes(searchTerm) &&
                !existingDiscountProducts.includes(product._id)
            );
            setExistingProducts(filtered);
        }
    }, [existingTableSearchQuery]);

    useEffect(() => {
        const searchTerm = tableSearchQuery.toLowerCase();

        // If the search query is empty, reset the filtered products to the original filtered state
        if (searchTerm === '') {
            if (selectedCategories.length > 0) {
                const selectedCategoryNames = selectedCategories.map((cat) => cat.value);
                const filtered = products.filter((product) =>
                    selectedCategoryNames.includes(product.category) &&
                    !existingDiscountProducts.includes(product._id)
                );
                const existingProducts = products.filter((product) =>
                    selectedCategoryNames.includes(product.category) &&
                    existingDiscountProducts.includes(product._id)
                );
                const existingDiscountwithValue = [];
                alldiscounts?.forEach((discount) => {
                    discount.discounts.forEach((discountItem) => {
                        // existingProducts.push(discountItem.productId);
                        existingDiscountwithValue[discountItem.productId] = discountItem.discount;
                    });
                });
                setExistingDiscountValues(existingDiscountwithValue);
                setExistingProducts(existingProducts);
                setFilteredProducts(filtered);
                setSelectedProducts(filtered.map((product) => product._id));
                setDiscounts({});
            } else {
                setFilteredProducts([]);
                setExistingDiscountValues(null);
                setExistingProducts(null);
                setSelectedProducts([]);
                setDiscounts({});
            }
        } else {
            const filtered = filteredProducts.filter((product) =>
                product.englishName.toLowerCase().includes(searchTerm) ||
                product.tamilName.toLowerCase().includes(searchTerm)
            );
            setFilteredProducts(filtered);
        }
    }, [tableSearchQuery]);

    const handleProductSelect = (e, productId) => {
        const isSelected = selectedProducts.includes(productId);
        setSelectedProducts((prev) =>
            isSelected ? prev.filter((id) => id !== productId) : [...prev, productId]
        );

        if (isSelected) {
            setDiscounts((prev) => {
                const newDiscounts = { ...prev };
                delete newDiscounts[productId];
                return newDiscounts;
            });
        }
    };

    const handleDiscountChange = (e, productId, value) => {
        setDiscounts((prev) => ({ ...prev, [productId]: value }));
    };

    const applyGlobalDiscount = () => {
        const discountValue = parseFloat(globalDiscount) || 0; // Ensure it's a number
        const newDiscounts = {};
        selectedProducts.forEach(
            (productId) => (newDiscounts[productId] = discountValue)
        );
        setDiscounts((prev) => ({ ...prev, ...newDiscounts }));
    };

    const handleSubmit = () => {
        const discountData = selectedProducts
            .map((productId) => ({
                productId,
                discount: discounts[productId] || 0,
            }))
            .filter((data) => data.discount > 0);

        if (discountData.length === 0) {
            alert("Please provide a valid title and apply discounts to products.");
            return;
        }

        const payload = {
            title: discountTitle, // Include title in the payload
            discounts: discountData,
        };
        dispatch(createDiscount({ payload }));
    };

    useEffect(() => {
        if (discountData) {
            toast.dismiss();
            setTimeout(() => {
                toast.success(discountData?.message, {
                    position: 'bottom-center',
                    autoClose: 700,
                    transition: Slide,
                    onOpen: () => {
                        dispatch(getDiscounts());
                        dispatch(clearNewDiscount());
                        setSelectedCategories([]);
                        setSelectedTypes([]);
                        setSelectedProducts([]);
                        setDiscounts({});
                        setGlobalDiscount(0);
                        setDiscountTitle("");
                    }
                });
            }, 300);
            navigate('/admin/discounts');
            return;

        }
        if (discountError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(discountError, {
                    position: 'bottom-center',
                    autoClose: 700,
                    transition: Slide,
                    onOpen: () => dispatch(clearNewDiscount()),
                });
            }, 300);
        }

    }, [discountData, discountError])

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedProducts(filteredProducts.map((product) => product._id));
        } else {
            setSelectedProducts([]);
            setDiscounts({});
        }
    };

    const typeOptions = [
        { value: "Fresh", label: "Fresh" },
        // { value: "Groceries", label: "Groceries" },
    ];

    const categoryOptionsList =
        getcategory?.map((category) => ({
            value: category.category,
            label: category.name,
        })) || [];

    const handleDeleteClick = (id) => {
        setProductToDelete(id);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        dispatch(deleteSingleDiscounts({ id: productToDelete }));
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (discountDeleteError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(discountDeleteError, {
                    position: 'bottom-center',
                    autoClose: 700,
                    transition: Slide,
                    onOpen: () => dispatch(clearDiscountDelete()),
                });
            }, 300);
        }
        if (discountDeleteSuccess) {
            toast.dismiss();
            dispatch(clearGetdiscounts())
            setTimeout(() => {
                toast.success(discountDeleteSuccess?.message, {
                    position: 'bottom-center',
                    autoClose: 700,
                    transition: Slide,
                    onOpen: () => {
                        dispatch(getDiscounts());
                        dispatch(clearDiscountDelete());
                        setShowModal(false);
                    }
                });
            }, 300);
            return;
        }

    }, [discountDeleteError, discountDeleteSuccess])

    return (
        <div>
            <MetaData
                title="Category List"
                description="Manage your category inventory. Create, View, update, or remove Category from your catalog to keep your store fresh and relevant."
            />

            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div>
                <div className="col-12 col-md-10 smalldevice-space loader-parent">
                    {getloading ? (
                        <div className="container loader-loading-center">
                            <Loader />
                        </div>
                    ) : (
                        <div>
                            <h1 className="mb-4 admin-dashboard-x">New Offer</h1>
                            <Fragment>
                                <>
                                    <div className="back-button" onClick={() => navigate(-1)}>
                                        <ArrowBackIcon fontSize="small" />
                                        <span>Back</span>
                                    </div>
                                    <h3 style={{ marginTop: '20px' }}>Filter</h3>
                                    <div className="discount-filters">
                                        <div className="discount-type">
                                            <Select
                                                isMulti
                                                value={selectedTypes}
                                                onChange={setSelectedTypes}
                                                options={typeOptions}
                                                placeholder="Select Product Type"
                                            />
                                        </div>

                                        <div className="discount-category">
                                            <Select
                                                isMulti
                                                value={selectedCategories}
                                                onChange={setSelectedCategories}
                                                options={categoryOptions}
                                                placeholder="Select Categories"
                                            />
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row' }} className="discount-input-search">
                                            <input
                                                type="text"
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                                placeholder="Search Products"
                                                className="discount-input"
                                            />
                                            <button onClick={handleSearch} className="discount-button btn btn-primary">
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                    <h3 style={{ marginTop: '20px' }}>Existing Products in Other Offer:</h3>
                                    <div className="discount-input-search">
                                        <input
                                            type="text"
                                            value={existingTableSearchQuery}
                                            onChange={(e) => setExistingTableSearchQuery(e.target.value)}
                                            placeholder="Search"
                                            className="discount-table-search"
                                        />
                                    </div>
                                    <div className="discount-table-wrapper">
                                        <table className="discount-table table">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Product Image</th>
                                                    <th>Product Name</th>
                                                    <th>Buying Price</th>
                                                    <th>Selling Price</th>
                                                    <th>Offer (%)</th>
                                                    <th>Final Price</th>
                                                    <th>Delete in Other Offer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {existingProducts && existingProducts.length > 0 ? (
                                                    existingProducts.map((product, index) => (
                                                        <tr key={product._id}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <img
                                                                    src={product?.images[0]?.image}
                                                                    alt={product.englishName}
                                                                    style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                                                />
                                                            </td>
                                                            <td>{product.englishName} / {product.tamilName}</td>
                                                            <td>{product.buyingPrice.toFixed(2)}</td>
                                                            <td>{product.price.toFixed(2)}</td>
                                                            <td>
                                                                {existingDiscountValues?.[product._id]}
                                                            </td>
                                                            <td>{product.finalPrice}</td>
                                                            <td>
                                                                <Button onClick={() => handleDeleteClick(product._id)} className="btn btn-danger py-1 px-2 ml-2">
                                                                    <i className="fa fa-trash"></i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={8}>No matching records found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        <h3 style={{ marginTop: "20px" }}>Offer Title</h3>
                                        <input
                                            type="text"
                                            value={discountTitle}
                                            onChange={(e) => setDiscountTitle(e.target.value)}
                                            placeholder="Enter Discount Title"
                                            className="form-control"
                                        />
                                    </div>
                                    <h3 style={{ marginTop: '20px' }}>Products</h3>
                                    <div className="discount-input-search">
                                        <input
                                            type="text"
                                            value={tableSearchQuery}
                                            onChange={(e) => setTableSearchQuery(e.target.value)}
                                            placeholder="Search"
                                            className="discount-table-search"
                                        />
                                    </div>
                                    <div className="discount-table-wrapper">
                                        <table className="discount-table table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input
                                                            type="checkbox"
                                                            onChange={handleSelectAll}
                                                            checked={selectedProducts.length === filteredProducts.length}
                                                        />
                                                    </th>
                                                    <th>S.No</th>
                                                    <th>Product Image</th>
                                                    <th>Product Name</th>
                                                    <th>Buying Price</th>
                                                    <th>Selling Price</th>
                                                    <th>Offer (%)</th>
                                                    <th>Final Price</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredProducts && filteredProducts.length > 0 ? (
                                                    filteredProducts.map((product, index) => (
                                                        <tr key={product._id}>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedProducts.includes(product._id)}
                                                                    onChange={(e) => handleProductSelect(e, product._id)}
                                                                />
                                                            </td>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <img
                                                                    src={product?.images[0]?.image}
                                                                    alt={product.englishName}
                                                                    style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                                                />
                                                            </td>
                                                            <td>{product.englishName} / {product.tamilName}</td>
                                                            <td>{product.buyingPrice.toFixed(2)}</td>
                                                            <td>{product.price.toFixed(2)}</td>
                                                            <td>
                                                                <NumberInput
                                                                    type="number"
                                                                    value={discounts[product._id] || ""}
                                                                    onChange={(e) =>
                                                                        handleDiscountChange(e, product._id, parseFloat(e.target.value) || 0)
                                                                    }
                                                                    disabled={!selectedProducts.includes(product._id)}
                                                                    className="input-discount form-control no-arrow-input"
                                                                />
                                                            </td>
                                                            <td>
                                                                {discounts[product._id]
                                                                    ? (product.price - (product.price * discounts[product._id] / 100)).toFixed(2)
                                                                    : product.price.toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={8}>No matching records found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        <div className="discount-global-section" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                            <div style={{ display: 'flex' }}>
                                                <NumberInput
                                                    type="number"
                                                    value={globalDiscount}
                                                    onChange={(e) => setGlobalDiscount(parseFloat(e.target.value))}
                                                    placeholder="Global Offer (%)"
                                                    className="form-control no-arrow-input"
                                                />
                                                <button onClick={applyGlobalDiscount} className="btn btn-success" style={{ marginLeft: '5px', minWidth: '130px' }}>Apply to All</button>
                                            </div>

                                        </div>

                                    </div>
                                </>
                            </Fragment>
                            <div className="discount-submit-btn-section">
                                <Button onClick={handleSubmit} className="discount-submit-btn" disabled={discountLoading}>
                                    {discountLoading ? <LoaderButton fullPage={false} size={20} /> : (
                                        <span>Apply Offer</span>
                                    )

                                    }
                                </Button>
                            </div>

                        </div>
                    )}
                </div>
                {showModal && (
                    <div className="modal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Confirm Delete</h5>
                                    <button type="button" className="close" onClick={handleCancelDelete}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure you want to delete this in Other Offer?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button"
                                        className="btn btn-danger"
                                        onClick={handleConfirmDelete}
                                        disabled={deleteSingleloading}
                                    >
                                        {deleteSingleloading ? <LoaderButton fullPage={false} size={20} /> : (
                                            <span>OK</span>
                                        )

                                        }

                                    </button>
                                    <button type="button" className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NewDiscount;