import { Fragment, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { orderDetail as orderDetailAction, updateOrder, porterOrder, RemoveOrderResponse, adminOrders, editDispatchedOrder, updateOrderStatusToDelivered } from "../../actions/orderActions";
import { CancelOrderResponse, createPorterOrderResponse, getPackedOrder, getporterOrder, packedOrder } from "../../actions/porterActions";
import { Slide, toast } from "react-toastify";
import { clearOrderUpdated, clearError, removeOrderClear, resetDeliverUpdate } from "../../slices/orderSlice";
import { v4 as uuidv4 } from 'uuid';
import Loader from "../Layouts/Loader";
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import ReactDOM from 'react-dom';
import JasInvoice from "../Layouts/JasInvoice";
import MetaData from "../Layouts/MetaData";
import LoaderButton from "../Layouts/LoaderButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NumberInput from "../Layouts/NumberInput";


const Dispatch = ({ isActive, setIsActive }) => {
    const location = useLocation();
    const { loading, isOrderUpdated, error, orderDetail, porterloading, porterOrderDetail, orderRemoveResponse, orderRemoveError, removeItemloading, removedItemDetail, removedItemError,updateDeliverError, isDeliverUpdated,updateDeliverLoading,updateDeliver  } = useSelector(state => state.orderState);
    const { products } = useSelector((state) => state.productsState);
    const { porterOrderData, porterOrderResponse, porterCancelResponse, porterCancelError, portererror, getpackedOrderData } = useSelector((state) => state.porterState);
    const { user = {}, orderItems = [], shippingInfo = {}, totalPrice = 0, statusResponse = {} } = orderDetail;
    const [orderStatus, setOrderStatus] = useState("Processing");
    const [dropStatus, setDropStatus] = useState("");
    const [editableWeights, setEditableWeights] = useState(orderDetail && orderItems && orderItems.map(item => item.productWeight)); // Initial state for weights
    const [originalWeights, setOriginalWeights] = useState(orderItems.map(item => item.productWeight)); // Original weights
    const [selectedItems, setSelectedItems] = useState([]);
    const { id } = useParams();
    const [refreshData, setRefreshData] = useState(false)
    const invoiceRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [editMissingItems, setEditMissingItems] = useState(false);
    const [weightvalue, setweightvalue] = useState(false);
    const [totalDispatchedAmount, setTotalDispatchedAmount] = useState(0);
    const [showDeliverModal, setShowDeliverModal] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false);


    // const handlePrint = useReactToPrint({
    //     content: () => invoiceRef.current,
    //     documentTitle: `Invoice_${id || 'unknown'}`, // Set the document title.
    //     onAfterPrint: () => {
    //         // toast.dismiss();
    //         // setTimeout(() => {
    //         //     toast.success("Invoice downloaded successfully.", {
    //         //         position: 'bottom-center',
    //         //         type: 'error',
    //         //         autoClose: 700,
    //         //         transition: Slide,
    //         //         hideProgressBar: true,
    //         //         className: 'small-toast',
    //         //     });
    //         // }, 300);
    //         console.log("Invoice downloaded successfully")
    //     },

    // });

    const calculateTotalDispatchedAmount = (updatedItems) => {
        let total = 0;

        if (editMissingItems) {
            updatedItems && updatedItems.forEach((item, index) => {
                const dispatchedWeight = parseFloat(item.productWeight);
                const pricePerKg = parseFloat(getpackedOrderData && getpackedOrderData.dispatchedTable[index].pricePerKg);
                total += dispatchedWeight * pricePerKg;
            });

            return total;
        }
        else {
            updatedItems && updatedItems.forEach((item, index) => {
                const dispatchedWeight = parseFloat(item.productWeight);
                const pricePerKg = parseFloat(orderItems[index].finalPrice);
                total += dispatchedWeight * pricePerKg;
            });

            return total;
        }


    };

    const getInvoiceHtmlWithStyles = () => {
        const invoiceHtml = invoiceRef.current.outerHTML;

        // Get the contents of the app.css file as a string
        const appCss = `
          <style>
            /* You can manually copy the CSS from your app.css or use inline styles */
            .invoice-container {
  width: 90%;
  margin: auto;
  font-family: 'Times New Roman', Times, serif;
  padding: 20px;
  font-size: 15px;
  font-weight: bold
}

.invoice-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}


.invoice-header .top-info {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.invoice-header .top-info div {
  margin-left: 20px;
}

.invoice-header .middle-info {
  display: flex;
  justify-content: space-between;
}

.invoice-header .middle-info div {
  flex: 1;
  margin: 0 10px;
}

.invoice-header .middle-info h4 {

  margin-bottom: 10px;
}

.invoice-header .bottom-info {
  display: flex;
  justify-content: space-between;
}

.invoice-header .bottom-info div {
  flex: 1;
  margin: 10px 10px;

}

.bottom-data {
  border: 1px solid #000;
  width: fit-content;
  font-family: Arial, sans-serif;
}

.bottom-data p {
  border-bottom: 1px solid black;
  text-align: center;
  font-weight: bolder;
}

.bottom-data p:last-child {
  border-bottom: none;
}

.invoice-header h4,
.invoice-header h5,
.invoice-header p {
  margin: 0;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  page-break-inside: auto;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #000;
  text-align: center;
}

.invoice-table td {
  font-weight: 800px;
}

.invoice-table tbody tr {
  page-break-inside: avoid;
  /* Prevent row splitting */
}

/* Footer should always appear at the end of the table */
.invoice-table tfoot {
  display: table-footer-group;
  /* Ensure footer is part of the table's final rendering */
}


.signature {
  text-align: right;
  margin-top: 50px;
}

.hidden {
  display: none;
}

.form-select:focus {
  border: 1px solid #ced4da;
  box-shadow: none;
}


            /* Include other styles from app.css here */
          </style>
        `;

        return appCss + invoiceHtml; // Combine CSS and HTML
    };

    const handlePrint = useReactToPrint({
        content: () => invoiceRef.current,
        documentTitle: `Invoice_${id || 'unknown'}`,
        onAfterPrint: () => {
            // Optionally, notify Android after print
        },
    });

    const triggerAndroidPrint = () => {
        if (window.Android) {
            // Send the HTML content of the invoice to Android
            //   const invoiceHtml = invoiceRef.current.outerHTML;
            const invoiceHtmlWithStyles = getInvoiceHtmlWithStyles();
            //   if (invoiceHtml.trim() === "") {
            //     console.log("Invoice content is empty!");
            //     return;
            //   }
            window.Android.printWebView(invoiceHtmlWithStyles);
        } else {
            handlePrint(); // Regular browser print if not running in WebView
        }
    };

    const pageRef = useRef();

    const [isPrinting, setIsPrinting] = useState(false); // To track print mode

    const handleDownload = useReactToPrint({
        content: () => pageRef.current,
        documentTitle: `Order_${orderDetail.order_id || "unknown"}`,
        removeAfterPrint: true,
        onBeforePrint: () => {
            setIsPrinting(true); // Enable print mode
            document.body.style.overflow = "hidden";
            document.body.style.height = "auto";

            const style = document.createElement("style");
            style.innerHTML = `
        html, body {
          overflow: hidden !important;
          height: auto !important;
        }

        .order-detail-container {
          height: auto !important;
          max-height: none !important;
          overflow: hidden !important;
          break-inside: avoid !important;
          page-break-inside: avoid !important;
        }

        .exclude-from-print {
          display: none !important;
        }

        // @page {
        //   size: auto;
        //   margin: 10mm;
        // }
      `;
            style.id = "print-style";
            document.head.appendChild(style);
        },
        onAfterPrint: () => {
            setIsPrinting(false); // Disable print mode after printing
            document.body.style.overflow = "auto";

            const printStyle = document.getElementById("print-style");
            if (printStyle) printStyle.remove();
        },
    });

    const triggerAndroidDownload = async () => {
        if (window.Android) {
            setIsPrinting(true); // Enable print mode
            const pageContent = document.documentElement.outerHTML;

            // Send the HTML content to the Android WebView to handle the download
            window.Android.printWebView(pageContent);
            setIsPrinting(false);
            document.body.style.overflow = "auto";
        } else {
            handleDownload(); // Fallback to normal download
        }
    };



    // useEffect(() => {
    //     if (orderDetail && orderDetail.order_id) {
    //         setOrderStatus(orderDetail.orderStatus);
    //         setDropStatus(orderDetail.orderStatus);
    //     }
    //     if (orderItems) {
    //         setEditableWeights(orderItems.map(item => item.productWeight))
    //     }
    // }, [orderDetail]);

    const updatedItems = orderItems && orderItems.map((item, index) => ({
        ...item,
        status: editableWeights && editableWeights[index] > 0 ? 'confirm' : 'cancel',
        productWeight: editableWeights && editableWeights[index]
    }));

    useEffect(() => {
        if (orderDetail?.order_id) {
            setOrderStatus(orderDetail.orderStatus);
            setDropStatus(orderDetail.orderStatus);
        }
        if (orderItems && !editMissingItems) {
            setEditableWeights(orderItems.map(item => item.productWeight))
        }
        if (getpackedOrderData && editMissingItems) {
            setEditableWeights(getpackedOrderData && getpackedOrderData.dispatchedTable.map(item => item.dispatchedWeight))
        }

    }, [orderDetail, editMissingItems]);

    const submitHandler = async (e) => {
        e.preventDefault();
        if (!editMissingItems) {
            const requestId = `Jas_0_${uuidv4()}`;
            const porterData = {
                "request_id": requestId,
                "delivery_instructions": {
                    "instructions_list": [
                        {
                            "type": "text",
                            "description": "handle with care"
                        }
                    ]
                },
                "pickup_details": {
                    "address": {
                        "apartment_address": "29",
                        "street_address1": "Reddy Street,Nerkundram",
                        "landmark": "Jas Fruits And Vegetables",
                        "city": "Chennai",
                        "state": "Tamilnadu",
                        "pincode": "600107",
                        "country": "India",
                        "lat": 13.0671844,
                        "lng": 80.1823796,
                        "contact_details": {
                            "name": "Gopalakrishnan",
                            "phone_number": "+919176883506"
                        }
                    }
                },
                "drop_details": {
                    "address": {
                        "street_address1": shippingInfo.address,
                        "landmark": shippingInfo.landmark,
                        "city": shippingInfo.city,
                        "state": shippingInfo.state,
                        "pincode": shippingInfo.postalCode,
                        "country": shippingInfo.country,
                        "lat": shippingInfo && shippingInfo.latitude && shippingInfo.latitude,
                        "lng": shippingInfo && shippingInfo.longitude && shippingInfo.longitude,
                        "contact_details": {
                            "name": user && user.name,
                            "phone_number": shippingInfo && shippingInfo.phoneNo && shippingInfo.phoneNo
                        }
                    }
                },
            };

            const reqPorterData = {
                user: user,
                request_id: requestId,
                invoiceNumber: getpackedOrderData?.invoiceNumber,
                user_id: user._id,
                order_id: orderDetail.order_id,
                porterData: porterData,
                updatedItems: getpackedOrderData.updatedItems,
                detailedTable: getpackedOrderData && getpackedOrderData.dispatchedTable,
                orderDetail: getpackedOrderData?.orderDetail,
                totalDispatchedAmount: Number(getpackedOrderData && getpackedOrderData.totalDispatchedAmount),
                totalRefundableAmount: Number(getpackedOrderData && getpackedOrderData.totalRefundableAmount),
                orderDate: getpackedOrderData?.orderDate,
                payment_method: getpackedOrderData?.payment_method,
            };
            if (orderDetail && shippingInfo && shippingInfo.latitude && shippingInfo.longitude && user && user.name && shippingInfo.phoneNo) {

                try {
                    console.log("Order ID:", orderDetail.order_id); 
                    await dispatch(porterOrder({ id: orderDetail.order_id, reqPorterData }));
                } catch (error) {
                    toast.dismiss();
                    setTimeout(() => {
                        toast.error(error, {
                            position: 'bottom-center',
                            type: 'error',
                            autoClose: 700,
                            transition: Slide,
                            hideProgressBar: true,
                            className: 'small-toast',
                        });
                    }, 300);
                }
            }
            else {
                toast.dismiss();
                setTimeout(() => {
                    toast.error('Can’t Dispatch because some user drop details are missing!', {
                        position: 'bottom-center',
                        type: 'error',
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: 'small-toast',
                    });
                }, 300);
            }
        }
        else {
            let totalDispatchedAmount = 0;
            let totalRefundableAmount = 0;

            const dispatchedTable = getpackedOrderData?.dispatchedTable.map((item, index) => {
                const orderedWeight = parseFloat(item.orderedWeight);
                const measurement = item.measurement;
                const range = item.range && item.range;
                const dispatchedWeight = parseFloat(updatedItems[index].productWeight);
                const refundableWeight = parseFloat((orderedWeight - dispatchedWeight).toFixed(2));
                const pricePerKg = parseFloat((item?.pricePerKg).toFixed(2));
                const productId = item?.productId;
                const totalAmount = parseFloat((dispatchedWeight * pricePerKg).toFixed(2));
                const refundableAmount = parseFloat((refundableWeight * pricePerKg).toFixed(2));

                totalRefundableAmount += refundableAmount;

                totalDispatchedAmount += totalAmount;

                return {
                    image: item.image,
                    name: item.name,
                    orderedWeight,
                    measurement,
                    range: range ? range : '',
                    pricePerKg,
                    productId,
                    dispatchedWeight,
                    refundableWeight,
                };
            });

            totalDispatchedAmount = parseFloat(totalDispatchedAmount.toFixed(2));
            totalRefundableAmount = parseFloat(totalRefundableAmount.toFixed(2));

            const reqPorterData = {
                user: user,
                user_id: user._id,
                order_id: orderDetail?.order_id,
                orderDetail: orderDetail,
                updatedItems: updatedItems,
                dispatchedTable: dispatchedTable,
                orderDate: orderDetail?.orderDate,
                payment_method: orderDetail?.payment_method,
                totalDispatchedAmount: totalDispatchedAmount,
                totalRefundableAmount: totalRefundableAmount
            };

            try {
                // await dispatch(packedOrder({ reqPackedData }));
                await dispatch(editDispatchedOrder({ id: orderDetail?.order_id, reqPorterData }));

            } catch (error) {
                toast.dismiss();
                setTimeout(() => {
                    toast.error(error, {
                        position: 'bottom-center',
                        type: 'error',
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: 'small-toast',
                    });
                }, 300);
            }
        }


    };

    useEffect(() => {
        dispatch(orderDetailAction(id));
        dispatch(getporterOrder({ order_id: id }))
        dispatch(getPackedOrder({ order_id: id }))
        setRefreshData(true)

    }, [dispatch, id, porterOrderDetail]);

    useEffect(() => {
        if (isOrderUpdated) {
            toast.dismiss();
            setTimeout(() => {
                toast.success('Order Dispatched Successfully!', {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => dispatch(clearOrderUpdated())
                });
                // dispatch(adminOrders());
                // dispatch(adminOrders({ startDate, endDate }));

            }, 300);
            setShowModal(false);

        }
    }, [isOrderUpdated])

    useEffect(() => {
        if (error) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(error, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearError()) }
                });
            }, 300);
            return;
        }
    }, [error])

    useEffect(() => {
        if (porterOrderData && refreshData) {
            dispatch(createPorterOrderResponse({ order_id: porterOrderData && porterOrderData.order_id, porterOrder_id: porterOrderData?.porterOrder?.order_id }))
        }
    }, [porterOrderData])

    useEffect(() => {
        if (refreshData && porterOrderResponse) {
            dispatch(getporterOrder({ order_id: id }))
            setRefreshData(false)
        }
    }, [refreshData, porterOrderResponse])



    const [trackurl, setTrackurl] = useState(false);
    const handleClick = (tracking_url) => {
        setTrackurl(true)
        window.location.href = tracking_url;
    }

    // const handeldispatch = () => {
    //     setShowModal(true);
    // }



    const handeldispatch = async (e) => {
        e.preventDefault();
        if (editMissingItems) {
            const dispatchedAmount = calculateTotalDispatchedAmount(updatedItems && updatedItems);
            setTotalDispatchedAmount(dispatchedAmount); // Update the total dispatched amount

            // Open modal
            setShowModal(true);
        }
        else {
            const dispatchedAmount = calculateTotalDispatchedAmount(updatedItems && updatedItems);
            setTotalDispatchedAmount(dispatchedAmount); // Update the total dispatched amount

            // Open modal
            setShowModal(true);
        }

    };

    const handleCancel = () => {
        setShowModal(false);
    }

    const capitalizeFirstLetter = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const handleEditClick = () => {
        setEditMissingItems(!editMissingItems); // Set edit mode to true when clicked
    };

    const handleItemSelection = (index) => {
        const newSelectedItems = [...selectedItems];
        newSelectedItems[index] = !newSelectedItems[index];

        if (!editMissingItems) {
            if (newSelectedItems[index]) {
                // If the checkbox is checked, set the weight to zero
                const newWeights = [...editableWeights];
                newWeights[index] = 0;
                setEditableWeights(newWeights);
            } else {
                // If the checkbox is unchecked, reset the weight to the original value
                const newWeights = [...editableWeights];
                newWeights[index] = orderItems[index].productWeight;
                setEditableWeights(newWeights);
            }
        }
        if (editMissingItems) {
            if (newSelectedItems[index]) {
                // If the checkbox is checked, set the weight to zero
                const newWeights = [...editableWeights];
                newWeights[index] = getpackedOrderData?.dispatchedTable[index]?.dispatchedWeight;
                setEditableWeights(newWeights);
            } else {
                // If the checkbox is unchecked, reset the weight to the original value
                const newWeights = [...editableWeights];
                newWeights[index] = getpackedOrderData?.dispatchedTable[index]?.dispatchedWeight;
                setEditableWeights(newWeights);
            }
        }

        setSelectedItems(newSelectedItems);
    };

    const changeWeight = (e, index) => {
        const value = e.target.value;
        const measurement = editMissingItems ? getpackedOrderData?.dispatchedTable[index].measurement : orderItems[index].measurement;
        if (editMissingItems) {
            if (value === '' || !isNaN(value)) {
                const numericValue = parseFloat(value);
                if (numericValue < 0) {
                    // If the entered value is negative, reset to the original weight and show an error
                    setweightvalue(true);
                    if (!weightvalue) {
                        toast.dismiss();
                        setTimeout(() => {
                            toast.error('Quantity cannot be negative. Reverting to original Quantity.', {
                                position: 'top-right',
                                type: 'error',
                                autoClose: 700,
                                transition: Slide,
                                hideProgressBar: true,
                                className: 'small-toast-input',
                            });
                            setweightvalue(false);
                        }, 300);
                    }
                    return;
                }

                if (numericValue > getpackedOrderData?.dispatchedTable[index].orderedWeight) {
                    setweightvalue(true);
                    if (!weightvalue) {
                        toast.dismiss();
                        setTimeout(() => {
                            toast.error('Entered Quantity is greater than requested Quantity!', {
                                position: 'top-right',
                                type: 'error',
                                autoClose: 700,
                                transition: Slide,
                                hideProgressBar: true,
                                className: 'small-toast-input',
                            });
                            setweightvalue(false)
                        }, 300);
                    }

                    return;
                }

                const weight = measurement && measurement === 'Kg' ? Math.min(parseFloat(numericValue).toFixed(2), getpackedOrderData?.dispatchedTable[index].orderedWeight.toFixed(2)) : Math.floor(numericValue, getpackedOrderData?.dispatchedTable[index].orderedWeight); // Ensure weight does not exceed initially ordered weight
                const newWeights = [...editableWeights];
                newWeights[index] = value === '' ? 0 : weight; // Allow empty value temporarily for editing
                setEditableWeights(newWeights);
            }
        }
        else {
            if (value === '' || !isNaN(value)) {
                const numericValue = parseFloat(value);
                if (numericValue < 0) {
                    // If the entered value is negative, reset to the original weight and show an error
                    setweightvalue(true);
                    if (!weightvalue) {
                        toast.dismiss();
                        setTimeout(() => {
                            toast.error('Quantity cannot be negative. Reverting to original Quantity.', {
                                position: 'top-right',
                                type: 'error',
                                autoClose: 700,
                                transition: Slide,
                                hideProgressBar: true,
                                className: 'small-toast-input',
                            });
                            setweightvalue(false);
                        }, 300);
                    }
                    return;
                }

                if (numericValue > orderItems[index].productWeight) {
                    setweightvalue(true);
                    if (!weightvalue) {
                        toast.dismiss();
                        setTimeout(() => {
                            toast.error('Entered Quantity is greater than requested Quantity. Reverting to original Quantity.', {
                                position: 'top-right',
                                type: 'error',
                                autoClose: 700,
                                transition: Slide,
                                hideProgressBar: true,
                                className: 'small-toast-input',
                            });
                            setweightvalue(false)
                        }, 300);
                    }

                    return;
                }

                const weight = measurement && measurement === 'Kg' ? Math.min(parseFloat(numericValue).toFixed(2), orderItems[index].productWeight.toFixed(2)) : Math.floor(numericValue, orderItems[index].productWeight); // Ensure weight does not exceed initially ordered weight
                const newWeights = [...editableWeights];
                newWeights[index] = value === '' ? 0 : weight; // Allow empty value temporarily for editing
                setEditableWeights(newWeights);
            }
        }

    };

    const handleBlur = (index) => {
        if (!editMissingItems) {
            if (editableWeights[index] === '' || editableWeights[index] === null) {
                const newWeights = [...editableWeights];
                newWeights[index] = orderItems[index].productWeight;
                setEditableWeights(newWeights);
            }
        }
        if (editMissingItems) {
            if (editableWeights[index] === '' || editableWeights[index] === null) {
                const newWeights = [...editableWeights];
                newWeights[index] = getpackedOrderData?.dispatchedTable[index].dispatchedWeight;
                setEditableWeights(newWeights);
            }
        }

    };


    useEffect(() => {
        if (removedItemDetail) {
            toast.dismiss();
            setTimeout(() => {
                toast.success(removedItemDetail, {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => dispatch(removeOrderClear())
                });
                // dispatch(adminOrders());
                // dispatch(adminOrders({ startDate, endDate }));
                dispatch(orderDetailAction(id));
                handleEditClick();
                dispatch(getporterOrder({ order_id: id }))
                dispatch(getPackedOrder({ order_id: id }))
            }, 300);
            setShowModal(false);

        }
    }, [removedItemDetail])

    useEffect(() => {
        if (removedItemError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(removedItemError, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(removeOrderClear()) }
                });
            }, 300);
            return;
        }
    }, [removedItemError])

    const handleDeliverClick = () => {
        setIsDisabled(true); 
        setShowDeliverModal(true);
    };

    const confirmDelivery = (orderId) => {
        dispatch(updateOrderStatusToDelivered({id: orderId }));
        dispatch(orderDetailAction(orderDetail.order_id));
        setShowDeliverModal(false);
    };

    useEffect(() => {
        if (updateDeliverError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(updateDeliverError, {
                    position: "bottom-center",
                    type: "error",
                    autoClose: 1000,
                    transition: Slide,
                    hideProgressBar: true,
                    className: "small-toast",
                    onOpen: () => { dispatch(resetDeliverUpdate()); } 
                });
            }, 300);
        }
    }, [updateDeliverError, dispatch]);

    useEffect(() => {
        if (isDeliverUpdated) {
            toast.dismiss();
            setTimeout(() => {
                toast.success("Order marked as Delivered!", {
                    position: "bottom-center",
                    type: "success",
                    autoClose: 1000,
                    transition: Slide,
                    hideProgressBar: true,
                    className: "small-toast",
                    onOpen: () => { dispatch(resetDeliverUpdate()); } // Reset after success
                });
            }, 300);
        }
    }, [isDeliverUpdated, dispatch]);


    return (
        <div>
            <MetaData
                title="Dispatch"
                description="Manage the dispatch of orders, track shipping status, and ensure products reach customers on time."
            />


            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <div className={window.Android ? "exclude-from-print" : ""} style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div>

                <div className="col-12 col-md-10 col-sm-10 smalldevice-space container order-detail-container loader-parent print-container" ref={pageRef}>
                    <div className="back-button exclude-from-print" onClick={() => navigate(-1)}>
                        <ArrowBackIcon fontSize="small" />
                        <span>Back</span>
                    </div>

                    {
                        loading ? (
                            <div className="container loader-loading-center">
                                <Loader />
                            </div>
                        ) : (
                            <Fragment>
                                <div className="col-12 col-lg-12 mt-5 order-details">
                                    <div className="order-container" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap', alignItems: 'center' }}>
                                        <h1 className="order-id-title mb-3" style={{ margin: 0 }} >Order ID: {orderDetail.order_id}</h1>
                                        <div className="order-buttons" style={{ display: 'flex', gap: '10px' }}>
                                            {
                                                porterOrderData && porterOrderData.tracking_url && (
                                                    <button onClick={() => handleClick(porterOrderData.tracking_url)} disabled={trackurl} className='tracking-btn exclude-from-print' >
                                                        Track Order
                                                    </button>
                                                )
                                            }
                                            {
                                                (orderStatus === 'Delivered' || orderStatus === 'Dispatched' || orderStatus === 'Cancelled') && (
                                                    <button onClick={() => triggerAndroidDownload()} className=" downloadBtn exclude-from-print" >
                                                        Download
                                                    </button>
                                                )
                                            }
                                        </div>
                                    </div>


                                    <h4 className="shipping-title mb-2">Shipping Info</h4>
                                    <div className="shipping-info">
                                        <div><b>Name:</b> {shippingInfo.name}</div>
                                        <div><b>Phone:</b> +91 {shippingInfo.phoneNo}</div>
                                        <div className='order-address-formatted'>
                                            <b>Address:</b>
                                            {shippingInfo.address && `${shippingInfo.address},`}
                                            {shippingInfo.area && `${shippingInfo.area},`}
                                            {shippingInfo.landmark && `${shippingInfo.landmark},`}
                                            {shippingInfo.city && `${shippingInfo.city}`}
                                            {shippingInfo.postalCode && -`${shippingInfo.postalCode}`}
                                        </div>
                                        <div><b>Total Amount:</b> ₹ {parseFloat(totalPrice).toFixed(2)}</div>
                                        <div><b>Online Payment:</b> ₹ {orderDetail?.paidOnline}</div>
                                        <div><b>Wallet Payment:</b> ₹ {orderDetail?.paidWallet}</div>
                                        {orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.payment_method ? (
                                            <div><b>Payment Mode:</b> {orderDetail && orderDetail?.statusResponse && orderDetail.statusResponse.payment_method}</div>

                                        ) : (
                                            <div><b>Payment Mode:</b> {orderDetail && orderDetail?.payment_method}</div>
                                        )

                                        }
                                    </div>
                                    <hr />

                                    <div className="status-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div><b>Payment Status:</b></div>
                                            <div style={{ color: orderDetail && orderDetail.paymentStatus === 'CHARGED' ? 'green' : 'red', marginLeft: '10px' }}>
                                                <b>{orderDetail && orderDetail.paymentStatus === 'CHARGED' ? 'Success' : orderDetail ? orderDetail.paymentStatus : 'Pending'}</b>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div><b>Order Status:</b></div>
                                            {/* <div style={{ color: orderStatus && orderStatus.includes('Delivered') ? 'green' : 'red', marginLeft: '10px' }}>
                                                <b>{orderStatus}</b>
                                            </div> */}
                                            <div style={{
                                                color: orderStatus && orderStatus.includes('Delivered') ? 'green' : 'red',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                margin: '10px',
                                                padding: '0px'
                                            }}>
                                                <b>
                                                    {orderStatus && orderStatus.includes('Delivered')
                                                        ? orderStatus
                                                        : (orderDetail?.paymentStatus === 'CHARGED' ? orderStatus : 'Cancelled')}
                                                </b>
                                            </div>
                                        </div>

                                    </div>
                                    {porterOrderData && porterOrderData.porterResponse && (
                                        <Fragment>
                                            <hr />
                                            <h4 className="delivery-title mb-2">Delivery Details:</h4>
                                            <div className="delivery-details">
                                                <div className="detail-column">
                                                    <div className="detail-row">
                                                        <h6>Order ID:</h6>
                                                        <p>{porterOrderData.porterResponse.order_id && porterOrderData.porterResponse.order_id}</p>
                                                    </div>
                                                    <div className="detail-row">
                                                        <h6>Estimated Fair details:</h6>
                                                        <p>Currency: {porterOrderData.porterResponse.fare_details && porterOrderData.porterResponse.fare_details.estimated_fare_details && porterOrderData.porterResponse.fare_details.estimated_fare_details.currency && porterOrderData.porterResponse.fare_details.estimated_fare_details.currency || "INR"}</p>
                                                        <p>Minor Amount: {porterOrderData.porterResponse.fare_details && porterOrderData.porterResponse.fare_details.estimated_fare_details && porterOrderData.porterResponse.fare_details.estimated_fare_details.minor_amount && (porterOrderData.porterResponse.fare_details.estimated_fare_details.minor_amount / 100) || "N/A"}  Rs</p>
                                                    </div>


                                                    <div className="detail-row">
                                                        <h6>Order Timings:</h6>

                                                        {porterOrderData.porterResponse && porterOrderData.porterResponse.order_timings && porterOrderData.porterResponse.order_timings.pickup_time ?
                                                            (
                                                                <p>Pickup Time:  {new Date(porterOrderData.porterResponse.order_timings.pickup_time * 1000).toLocaleString()}</p>
                                                            ) : (<p>Pickup Time:  N/A</p>)
                                                        }

                                                        {porterOrderData.porterResponse && porterOrderData.porterResponse.order_timings && porterOrderData.porterResponse.order_timings.order_accepted_time ?
                                                            (
                                                                <p>Order Accepted Time:  {new Date(porterOrderData.porterResponse.order_timings.order_accepted_time * 1000).toLocaleString()}</p>
                                                            ) : (<p>Order Accepted Time:  N/A</p>)
                                                        }

                                                        {porterOrderData.porterResponse && porterOrderData.porterResponse.order_timings && porterOrderData.porterResponse.order_timings.order_started_time ?
                                                            (
                                                                <p>Order Started Time:  {new Date(porterOrderData.porterResponse.order_timings.order_started_time * 1000).toLocaleString()}</p>
                                                            ) : (<p>Order Started Time:  N/A</p>)
                                                        }
                                                        {porterOrderData.porterResponse && porterOrderData.porterResponse.order_timings && porterOrderData.porterResponse.order_timings.order_ended_time ?
                                                            (
                                                                <p>Order Ended Time:  {new Date(porterOrderData.porterResponse.order_timings.order_ended_time * 1000).toLocaleString()}</p>
                                                            ) : (<p>Order Ended Time:  N/A</p>)
                                                        }
                                                    </div>


                                                </div>
                                                <div className="detail-column">
                                                    <div className="detail-row">
                                                        <h6>Delivery Status:</h6>
                                                        <p>{porterOrderData.porterResponse.status}</p>
                                                    </div>

                                                    <div className="detail-row">
                                                        <h6>Actual Fare Details:</h6>
                                                        <p>Currency: {porterOrderData.porterResponse.fare_details && porterOrderData.porterResponse.fare_details.actual_fare_details && porterOrderData.porterResponse.fare_details.actual_fare_details.currency && porterOrderData.porterResponse.fare_details.actual_fare_details.currency || "INR"}</p>
                                                        <p>Minor Amount: {porterOrderData.porterResponse.fare_details && porterOrderData.porterResponse.fare_details.actual_fare_details && porterOrderData.porterResponse.fare_details.actual_fare_details.minor_amount && (porterOrderData.porterResponse.fare_details.actual_fare_details.minor_amount / 100) || "N/A"}  Rs</p>
                                                    </div>

                                                    {
                                                        porterOrderData.porterResponse.partner_info && (
                                                            <div className="detail-row">
                                                                <h5>Delivery Partner:</h5>
                                                                <p>Name: {porterOrderData.porterResponse.partner_info.name}</p>
                                                                <p>Mobile: {porterOrderData.porterResponse.partner_info.mobile.country_code} {porterOrderData.porterResponse.partner_info.mobile.mobile_number}</p>
                                                                {porterOrderData.porterResponse.partner_info.partner_secondary_mobile && (
                                                                    <>
                                                                        <p>Secondary Mobile: {porterOrderData.porterResponse.partner_info.partner_secondary_mobile.country_code} {porterOrderData.porterResponse.partner_info.partner_secondary_mobile.mobile_number}</p>
                                                                    </>
                                                                )
                                                                }
                                                                <p>Vehicle Number: {porterOrderData.porterResponse.partner_info.vehicle_number}</p>
                                                                <p>Vehicle Type: {porterOrderData.porterResponse.partner_info.vehicle_type}</p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}


                                    <hr />
                                    {/* <h4 className="order-items my-4">Order Items:</h4> */}
                                    {
                                        (orderStatus === 'Dispatched' || orderStatus === 'Delivered') ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <h4 className=" order-items my-4">Dispatched Items:</h4>
                                                {
                                                    !editMissingItems ? (
                                                        <button onClick={handleEditClick} className="btn btn-success packEdit exclude-from-print">
                                                            {/* <i className="fa fa-pencil"></i> */}
                                                            Missing Items
                                                        </button>
                                                    ) : (
                                                        <button onClick={handleEditClick} className="btn btn-danger packEdit exclude-from-print">
                                                            {/* <i className="fa fa-times"></i> */}
                                                            Cancel
                                                        </button>
                                                    )
                                                }
                                                <button onClick={handleDeliverClick}
                                                 className="btn btn-primary packEdit exclude-from-print"
                                                //  disabled={isDisabled || orderStatus === "Delivered"}
                                                 disabled={isDisabled || orderStatus === "Delivered" ? true : porterOrderData?.porterResponse?.status !== 'cancelled'}
                                                 >
                                                    Delivery
                                                </button>

                                            </div>
                                        ) : (
                                            <h4 className=" order-items my-4">Order Items:</h4>
                                        )
                                    }


                                    <div className="invoice-table-container">
                                        <div className="updatetable-responsive">
                                            <table className="updatetable updatetable-bordered">
                                                <thead>
                                                    <tr>
                                                        {getpackedOrderData && getpackedOrderData?.dispatchedTable && !editMissingItems ? (
                                                            <>
                                                                <th>S.No</th>
                                                                <th>Image</th>
                                                                <th>Name</th>
                                                                <th>Price</th>
                                                                <th>Ordered Quantity</th>
                                                                <th>Dispatched Quantity</th>
                                                                <th>Total Amount</th>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <th>Select Item</th>
                                                                <th>Image</th>
                                                                <th>Name</th>
                                                                <th>Price</th>
                                                                <th>Ordered Quantity</th>
                                                                <th>Dispatched Quantity</th>
                                                                <th>Total Amount</th>
                                                            </>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {getpackedOrderData && getpackedOrderData?.dispatchedTable && !editMissingItems ? (
                                                        getpackedOrderData?.dispatchedTable.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <img src={item.image} alt={item.name} className="updateTableproduct-image" />
                                                                </td>
                                                                <td>{item && item.range ? `${capitalizeFirstLetter(item.name)} (${item.range})` : `${capitalizeFirstLetter(item.name)}`} </td>
                                                                <td>₹ {parseFloat(item.pricePerKg).toFixed(2)}</td>
                                                                <td>{item.orderedWeight}  {item.measurement}</td>
                                                                <td>{item.dispatchedWeight}  {item.measurement}</td>
                                                                <td>₹ {parseFloat(item.pricePerKg * item.dispatchedWeight).toFixed(2)}</td>
                                                            </tr>
                                                        ))

                                                    ) : (
                                                        getpackedOrderData?.dispatchedTable.map((item, index) => {
                                                            const product = products.find((product) => product.englishName === item.name);
                                                            if (!product) return null;

                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="updatecheck-input"
                                                                            checked={selectedItems[index]}
                                                                            onChange={() => handleItemSelection(index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <img src={item.image} alt={item.name} className="updateTableproduct-image" />
                                                                    </td>
                                                                    <td>{item && item.range ? `${capitalizeFirstLetter(item.name)} (${item.range})` : `${capitalizeFirstLetter(item.name)}`}</td>
                                                                    <td>₹ {item.pricePerKg}</td>
                                                                    <td>{item.orderedWeight} {item.measurement}</td>
                                                                    {editableWeights && (
                                                                        <>
                                                                            <td style={{ maxWidth: '70px' }}>
                                                                                <NumberInput
                                                                                    type="number"
                                                                                    className="no-arrow-input form-control updateTableInput"
                                                                                    value={editableWeights[index] === 0 ? "" : editableWeights[index]}
                                                                                    step="0.01"
                                                                                    onChange={(e) => changeWeight(e, index)}
                                                                                    placeholder={editableWeights[index] === 0 ? 0 : ''}
                                                                                    onBlur={() => handleBlur(index)}
                                                                                    disabled={!selectedItems[index]}
                                                                                    required
                                                                                />

                                                                            </td>
                                                                            <td>₹ {(editableWeights[index] * item.pricePerKg).toFixed(2)}</td>
                                                                        </>
                                                                    )}
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                    <tr>
                                                        <td colSpan="6" style={{ textAlign: 'right' }}><strong>Total Dispatched Amount</strong></td>
                                                        <td className="amount">
                                                            <strong>
                                                                ₹ {getpackedOrderData && getpackedOrderData.totalDispatchedAmount
                                                                    ? parseFloat(getpackedOrderData.totalDispatchedAmount).toFixed(2)
                                                                    : "0.00"}
                                                            </strong>
                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <hr />
                                    <div>
                                        <button
                                            className="btn btn-primary exclude-from-print"
                                            // onClick={handeldispatch}
                                            onClick={(e) => handeldispatch(e)}
                                            disabled={(dropStatus === "Dispatched" && !editMissingItems) || (dropStatus === "Delivered" && !editMissingItems) || dropStatus === "Cancelled"}
                                            style={{ cursor: ((dropStatus === "Dispatched" && !editMissingItems) || (dropStatus === "Delivered" && !editMissingItems) || dropStatus === "Cancelled") ? 'not-allowed' : 'pointer' }}
                                        >
                                            {editMissingItems ? "Update-Dispatch"
                                                : dropStatus === "Dispatched" ? "Already Dispatched"
                                                    : dropStatus === "Delivered" ? "Order Delivered"
                                                        : dropStatus === "Cancelled" ? "Order Cancelled"
                                                            : "Dispatch"}
                                        </button>

                                        {
                                            dropStatus === 'Packed' && (
                                                <button
                                                    className='btn btn-primary exclude-from-print'
                                                    style={{ textDecoration: 'none', color: 'blue', marginLeft: '30px' }}
                                                >
                                                    <Link to={`/admin/order/${id}`} style={{ textDecoration: 'none', color: '#fff' }}>
                                                        Repack <ArrowForwardIcon fontSize="small" />
                                                    </Link>
                                                </button>
                                            )
                                        }

                                    </div>

                                    {showModal && (
                                        <div className="modal" tabIndex="-1" role="dialog" style={modalStyle}>
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        {editMissingItems ? (
                                                            <div>Confirm Action</div>
                                                        ) : (
                                                            <div>Are you sure you want to dispatch this items?</div>
                                                        )}

                                                        <button type="button" className="close" onClick={handleCancel}>
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">

                                                        {getpackedOrderData && getpackedOrderData?.dispatchedTable && !editMissingItems ? (
                                                            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                                <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
                                                                    {getpackedOrderData.dispatchedTable
                                                                        .filter(item => item.pricePerKg * item.dispatchedWeight > 0) // Filter items with total amount > 0
                                                                        .map((item, index) => (
                                                                            <li key={index} style={{ paddingBottom: '10px' }}>
                                                                                <strong>{item.name}</strong> - {item.dispatchedWeight} {item.measurement}
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            </div>
                                                        ) : (
                                                            <div className="modal-body">
                                                                {totalDispatchedAmount > 0 ? (
                                                                    <p>Do you want to confirm the update?</p>
                                                                ) : (
                                                                    <p>There is no item to update. This order cannot be canceled because it has already been dispatched.</p>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="modal-footer">
                                                        {
                                                            getpackedOrderData && getpackedOrderData?.dispatchedTable && !editMissingItems ? (
                                                                <button type="button" className="btn btn-primary" onClick={submitHandler} disabled={porterloading}>
                                                                    {porterloading || removeItemloading ? <LoaderButton fullPage={false} size={20} /> : (
                                                                        <span>  Continue</span>
                                                                    )

                                                                    }

                                                                </button>
                                                            ) : (
                                                                <button type="button" className="btn btn-primary" style={{ cursor: (porterloading || totalDispatchedAmount <= 0) ? 'not-allowed' : 'pointer' }} onClick={submitHandler} disabled={porterloading || totalDispatchedAmount <= 0}>
                                                                    {porterloading || removeItemloading ? <LoaderButton fullPage={false} size={20} /> : (
                                                                        <span>Continue</span>
                                                                    )

                                                                    }

                                                                </button>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {(getpackedOrderData && porterOrderData) && (
                                        <div style={{ marginTop: '20px' }}>
                                            <button onClick={triggerAndroidPrint} className='btn btn-primary exclude-from-print'>Download Invoice</button>
                                            {ReactDOM.createPortal(
                                                <div style={{ position: 'absolute', top: '-9999px', left: '-9999px', zIndex: '-9999999999' }}>
                                                    <JasInvoice ref={invoiceRef} invoice={getpackedOrderData} porterData={porterOrderData} />
                                                </div>,
                                                document.body
                                            )}
                                        </div>

                                    )

                                    }
                                </div>
                            </Fragment>
                        )
                    }

                    {/* Hide buttons when printing */}
                    <style>
                        {`
                    @media print {
                       
                    
                        .exclude-from-print {
                            display: none !important;
                        }
                    
                        html, body {
                            overflow: auto;
                            height: auto !important;
                        }
                    
                        .order-detail-container {
                            height: auto !important;
                            max-height: none !important;
                            overflow: visible !important;
                            overflow: hidden !important;
                        }
                    
                       
                        // @page {
                        //     size: auto;
                        //     margin: 10mm; 
                        // }
                    
                    
                `}
                    </style>

                </div>
            </div>
            {showDeliverModal && (
                <div className="modal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Delivery</h5>
                                <button type="button" className="close" onClick={() => setShowDeliverModal(false)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to mark this order as delivered?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success" onClick={() => confirmDelivery(orderDetail.order_id)}>
                                  
                                    {updateDeliverLoading ? <LoaderButton fullPage={false} size={20} /> : (
                                            <span>Confirm Delivery</span>
                                        )

                                        }
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={() => setShowDeliverModal(false)}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );



};

const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)'
};

export default Dispatch;