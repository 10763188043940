import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Container, NavDropdown, Navbar, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../actions/userActions';
import { adminOrderClear, userOrdersClear } from '../../slices/orderSlice';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';


const Sidebar = ({ isActive, setIsActive }) => {
    const { user, isAuthenticated, loggedoutmessage, isloggedout } = useSelector(state => state.authState);
    const { loading, userOrders, error } = useSelector(state => state.orderState)
    useEffect(() => {
    }, [isloggedout, isAuthenticated, user]);


    const useWindowWidth = () => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);

        useEffect(() => {
            const handleResize = () => setWindowWidth(window.innerWidth);

            window.addEventListener('resize', handleResize);

            // Cleanup event listener on component unmount
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowWidth;
    };
    const windowWidth = useWindowWidth();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(false);
    const toggleSidebar = () => {
        setIsActive(!isActive);
    }

    const closeSidebar = () => {
        setIsActive(false);
    }

    const logoutHandler = (e) => {
        e.preventDefault();
        if (userOrders) {
            dispatch(userOrdersClear());
        }

        dispatch(logout);
        sessionStorage.clear();
        setRefresh(true); // Set refresh to true to trigger useEffect
    }


    useEffect(() => {
        if (isActive) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [isActive]);

    useEffect(() => {
        const handleScroll = () => {
            const headerElement = document.querySelector('.custom-navbar');
            if (window.scrollY > 20) {
                headerElement && headerElement.classList.add('custom-navbar-shadow');
            } else {
                headerElement && headerElement.classList.remove('custom-navbar-shadow');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className={`sidebar-wrapper ${isActive ? 'active' : ''}`} >
                <nav id="sidebar">
                    {windowWidth > 767 && (
                        <Link to="/">
                            <img className="logo-admin" src="/images/logo.png" alt="logo" />
                        </Link>
                    )}
                    <ul className="list-unstyled components">
                        {user && user.role === 'admin' && (
                            <li>
                                <Link to='/admin/dashboard' onClick={closeSidebar}><i className='fas fa-tachometer-alt'></i> Dashboard</Link>
                            </li>
                        )}
                        {/* <li>
                            
                            <NavDropdown title={<i className='fa fa-product-hunt'> Product</i>}>
                            {user && user.role === 'admin' && (
                                <NavDropdown.Item onClick={() => { navigate('/admin/products'); closeSidebar(); }}>
                                    <i className='fa fa-shopping-basket product-dropdown'> All</i>
                                </NavDropdown.Item>
                            )}
                             {user && user.role === 'admin' && (
                                <NavDropdown.Item onClick={() => { navigate('/admin/products/create'); closeSidebar(); }}>
                                    <i className='fa fa-plus product-dropdown'> Create </i>
                                </NavDropdown.Item>
                            )}
                                <NavDropdown.Item onClick={() => { navigate('/admin/products/updateprice'); closeSidebar(); }}>
                                    <i className='fa fa-upload product-dropdown'> UpdatePrice </i>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </li> */}

                        <li>
                            {user && user.role === 'admin' ? (
                                <NavDropdown
                                    title={
                                        <i className='fa fa-product-hunt'> Product</i>
                                    }
                                >
                                    <NavDropdown.Item onClick={() => { navigate('/admin/products'); closeSidebar(); }}>
                                        <i className='fa fa-shopping-basket product-dropdown'> All</i>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => { navigate('/admin/products/create'); closeSidebar(); }}>
                                        <i className='fa fa-plus product-dropdown'> Create </i>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => { navigate('/admin/products/updateprice'); closeSidebar(); }}>
                                        <i className='fa fa-upload product-dropdown'> UpdatePrice </i>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                // <Nav.Link onClick={() => { navigate('/admin/products/updateprice'); closeSidebar(); }}>
                                //     <i className='fa fa-upload product-dropdown'> Update Price </i>
                                // </Nav.Link>

                                <NavDropdown
                                    title={
                                        <i className='fa fa-product-hunt'> Product</i>
                                    }
                                >
                                    <NavDropdown.Item onClick={() => { navigate('/admin/products'); closeSidebar(); }}>
                                        <i className='fa fa-shopping-basket product-dropdown'> All</i>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => { navigate('/admin/products/updateprice'); closeSidebar(); }}>
                                        <i className='fa fa-upload product-dropdown'> UpdatePrice </i>
                                    </NavDropdown.Item>
                                </NavDropdown>


                            )}
                        </li>

                        {user && user.role === 'admin' && (
                            <li>
                                <Link to="/admin/category" onClick={closeSidebar}><i className="fa fa-tags"></i>Category</Link>
                            </li>
                        )}
                        {user && user.role === 'admin' && (
                            <li>
                                <Link to="/admin/measurement" onClick={closeSidebar}><i className="fa fa-balance-scale"></i>Measurement</Link>
                            </li>
                        )}
                        <li>
                            {user && user.role === 'admin' ? (
                                <NavDropdown title={<i className='fa fa-shopping-basket'> Orders</i>}>

                                    <NavDropdown.Item onClick={() => { navigate('/admin/orders'); closeSidebar(); }}>
                                        <i className='fa fa-list'> Order List</i>
                                    </NavDropdown.Item>

                                    <NavDropdown.Item onClick={() => { navigate('/admin/order-summary'); closeSidebar(); }}>
                                        <i className='fa fa-file-text'> Order Summary </i>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                // <Nav.Link onClick={() => { navigate('/admin/order-summary'); closeSidebar(); }}>
                                //     <i className='fa fa-file-text'> Order Summary </i>
                                // </Nav.Link>

                                <NavDropdown title={<i className='fa fa-shopping-basket'> Orders</i>}>

                                    <NavDropdown.Item onClick={() => { navigate('/admin/orders'); closeSidebar(); }}>
                                        <i className='fa fa-list'> Order List</i>
                                    </NavDropdown.Item>

                                    <NavDropdown.Item onClick={() => { navigate('/admin/order-summary'); closeSidebar(); }}>
                                        <i className='fa fa-file-text'> Order Summary </i>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            )}
                        </li>
                        <li>
                            {user && user.role === 'admin' ? (
                                <NavDropdown title={<i className="fa fa-users"> Users</i>}>

                                    <NavDropdown.Item onClick={() => { navigate('/admin/users'); closeSidebar(); }}>
                                        <i className='fa fa-list'> User List</i>
                                    </NavDropdown.Item>

                                    <NavDropdown.Item onClick={() => { navigate('/admin/user-summary'); closeSidebar(); }}>
                                        <i className='fa fa-file-text'> User Summary </i>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => { navigate('/admin/users/wallet'); closeSidebar(); }}>
                                        <AccountBalanceWalletIcon style={{ marginLeft: '-3px' }} />User Wallet
                                    </NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                <Nav.Link onClick={() => { navigate('/admin/user-summary'); closeSidebar(); }}>
                                    <i className='fa fa-file-text'> User Summary </i>
                                </Nav.Link>
                            )}
                        </li>
                        {user && user.role === 'admin' && (
                        <li>
                            <Link to="/admin/discounts" onClick={closeSidebar}><i className="fa fa-percent"></i> Offers</Link>
                        </li>
                         )}

                        {/* {user && user.role === 'admin' && (
                            <li>
                                <Link to="/admin/payments" onClick={closeSidebar}><i className="fa fa-credit-card"></i> Payments</Link>
                            </li>
                        )} */}

                        {user && (user.role === 'admin' || user.role === 'subadmin') && (
                            <li>
                                <Link to="/admin/dispatch" onClick={closeSidebar}><i className="fa fa-truck"></i> Dispatch</Link>
                            </li>
                        )}
                        <li>
                            {user && user.role === 'admin' && (
                                // <li>
                                //     <Link to="/admin/orderReport" onClick={closeSidebar}><i className='fa fa-file-text'></i> Order Report</Link>
                                // </li>
                                <NavDropdown
                                    title={
                                        <i className='fa fa-file-text'> Report</i>
                                    }
                                >
                                    <NavDropdown.Item onClick={() => { navigate('/admin/orderReport'); closeSidebar(); }}>
                                        <i className='fa fa-file-text'> Order Report</i>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => { navigate('/admin/dateReport'); closeSidebar(); }}>
                                        <i className='fa fa-file-text'> Margin Report </i>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => { navigate('/admin/payments'); closeSidebar(); }}>
                                        <i className='fa fa-file-text'> Payment Report </i>
                                    </NavDropdown.Item>

                                </NavDropdown>
                            )}
                        </li>

                        {user && user.role === 'admin' && (
                            <li>
                                <Link to="/admin/analysis" onClick={closeSidebar}>
                                    <i className="fa fa-pie-chart"></i> Analysis
                                </Link>
                            </li>
                        )}

                        {user && user.role === 'admin' && (

                            <li>
                                <Link to="/admin/announcement" onClick={closeSidebar}>
                                    <i className="fa fa-bullhorn"></i>
                                    Announcement
                                </Link>
                            </li>
                        )}

                        {user && user.role === 'admin' && (
                            <li>
                                <Link to="/admin/refund" onClick={closeSidebar}><i className="fa fa-reply"></i> Refund</Link>
                            </li>
                        )}
                        <li>
                            <Link onClick={(e) => logoutHandler(e)}><i className="fa fa-sign-out"></i> Logout</Link>
                        </li>
                    </ul>
                </nav>
            </div>
            {windowWidth < 768 && (
                <Navbar collapseOnSelect expand="sm" className="bg-body-tertiary custom-navbar" style={{ justifyContent: 'unset !important' }}>
                    {!isActive ? (
                        <button id="sidebarCollapse" onClick={toggleSidebar} style={{ zIndex: '99999', border: 'none', position: 'relative', fontSize: '20px' }}>
                            <i className="fa fa-bars"></i>
                        </button>
                    ) : (
                        <button id="sidebar-close" onClick={toggleSidebar} style={{ zIndex: '99999', border: 'none', fontSize: '20px' }}>
                            <i className="fa fa-close"></i>
                        </button>
                    )}
                    <Navbar.Brand>
                        <Link to="/">
                            <img width="300px" height="90px" className="logo admin-logo" src="/images/logo.png" alt="logo" />
                        </Link>
                    </Navbar.Brand>
                </Navbar>
            )}

            {isActive && (
                <>
                    <div className="overlay" onClick={closeSidebar}></div>
                    <div className="blur-effect"></div>
                </>
            )}
        </>
    );
}

export default Sidebar;