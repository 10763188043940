import React, { useEffect, useState } from "react";
import NumberInput from "./NumberInput"; // Assuming you have a NumberInput component

const CartWeightInput = ({ item, handleWeightChange, weight, editable }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1); // Tracks the focused option index

    // Function to handle dropdown option selection
    const handleSelectOption = (value) => {
        handleWeightChange(item.product, String(value), item.category, item.measurement, item);
        setDropdownVisible(false);
    };

    // Generate options based on the measurement type
    // const options = item.measurement === 'Kg'
    //     ? [...Array(6).keys()].map((i) => (i + 1) * 0.5) // Options for Kg: [0.5, 1, 1.5]
    //     : [...Array(3).keys()].map((i) => i + 1); // Options for others: [1, 2, 3]

    const minQty = Number(item.minimumQuantity) || 0.25; // Ensure valid number
    const maxQty = Number(item.maximumQuantity) || 5;   // Ensure valid max quantity

    // Ensure maxQty is greater than minQty to avoid errors
    if (maxQty < minQty) {
        console.error("Invalid quantity range:", { minQty, maxQty });
    }

    // Generate options based on measurement type
    const options =
    item.measurement === "Kg"
            ? minQty <= 0.25
                ? Array.from({ length: 6 }, (_, i) => (0.5 + i * 0.5).toFixed(2)) // Start at 0.5, increase by 0.5, length 6
                : Array.from({ length: 5 }, (_, i) => (minQty + i * 0.5).toFixed(2)) // Start at minQty, increase by 0.5, length 5
            : Array.from({ length: 3 }, (_, i) => (minQty + i).toString());

    return (
        <>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '100%' }}>
                <NumberInput
                    type="number"
                    value={!editable ? weight[item.product] || item.productWeight : weight[item.product] || ""}
                    onChange={(e) =>
                        handleWeightChange(item.product, e.target.value, item.category, item.measurement, item, item.maximumQuantity, item.minimumQuantity)
                    }
                    onFocus={() => setDropdownVisible(true)}
                    onClick={() => setDropdownVisible(true)}
                    onBlur={() => setTimeout(() => setDropdownVisible(false), 100)} // Delay for selection
                    className="no-arrow-input cart-input"
                    placeholder=""
                    disabled={!editable}
                />
                <span style={{ whiteSpace: 'nowrap', marginLeft: '2px' }}>
                    {item.measurement === 'Grams' ? 'Piece' : item.measurement}
                </span>


            </div>
            {dropdownVisible && (

                <div style={{ position: 'absolute', zIndex: '99', marginTop: '30px' }}>
                    <ul
                        style={{
                            position: "absolute",
                            maxWidth: '100px',
                            minWidth: '100px',
                            left: "50%",
                            transform: "translateX(-50%)",
                            backgroundColor: "#fff",
                            zIndex: 10,
                            listStyle: "none",
                            margin: 0,
                            padding: 0,
                            borderLeft: "solid transparent",
                            borderRight: "solid transparent",
                            borderBottom: "solid #fff",
                            boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",
                            borderTop: "1px solid transparent", // Changed to borderTop for upward arrow
                            height: 'auto',
                            overflowY: "auto",

                        }}
                    >

                        {options.map((option, index) => (
                            <li
                                key={option}
                                style={{
                                    padding: "10px 14px",
                                    cursor: "pointer",
                                    margin: '3px',
                                    borderRadius: '5px',
                                    fontSize: '13px',
                                    backgroundColor: focusedIndex === index ? "#e9ecef" : "#fff",
                                    color: focusedIndex === index ? "#495057" : "#000",
                                    transition: "background-color 0.2s, color 0.2s",
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                                onMouseEnter={() => setFocusedIndex(index)}
                                onClick={() => handleSelectOption(option)}
                            >
                                {option}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export default CartWeightInput;