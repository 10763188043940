import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { deleteEnquiryFail, deleteEnquiryRequest, deleteEnquirySuccess, getEnquiryFail, getEnquiryRequest, getEnquirySuccess, postEnquiryFail, postEnquiryRequest, postEnquirySuccess } from '../slices/enquirySlice';



export const postEnquiryDetails = createAsyncThunk('post/postenquiry', async (form, { dispatch }) => {
    try {
              dispatch(postEnquiryRequest());
              const config = {
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            }
            
              const {data} = await axios.post(`/api/v1/enquiry`,form,config);
              dispatch(postEnquirySuccess(data));
        } catch (error) {
              dispatch(postEnquiryFail(error.response.data.message));
            
        }
          
        
  })

  export const getEnquiryDetails = createAsyncThunk('get/getenquiry', async ({ startDate, endDate }, { dispatch }) => {
    try {
              dispatch(getEnquiryRequest());
              // const {data} = await axios.get(`/api/v1/getenquiry`);
              const { data } = await axios.get('/api/v1/getenquiry', {
                params: {
                  startDate,
                  endDate,
                },
              }, { withCredentials: true });
              dispatch(getEnquirySuccess(data));
        } catch (error) {
              dispatch(getEnquiryFail(error.response.data.message));
            
        }
          
        
  })

  export const deleteEnquiryDetails = createAsyncThunk('delete/deleteenquiry', async ({ id }, { dispatch }) => {

    try {
      dispatch(deleteEnquiryRequest())
      await axios.delete(`/api/v1/enquiry/${id}`);
      dispatch(deleteEnquirySuccess())
    } catch (error) {
      //handle error
      dispatch(deleteEnquiryFail(error.response.data.message))
    }
  })
