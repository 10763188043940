import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  orderFail,
  orderSuccess,
  orderRequest,
  adminOrdersRequest,
  adminOrdersSuccess,
  adminOrdersFail,
  createOrderRequest,
  createOrderSuccess,
  createOrderFail,
  deleteOrderRequest,
  deleteOrderSuccess,
  deleteOrderFail,
  orderDetailRequest,
  orderDetailSuccess,
  orderDetailFail,
  updateOrderRequest,
  updateOrderSuccess,
  updateOrderFail,
  porterOrderRequest,
  porterOrderSuccess,
  porterOrderFail,
  userOrdersRequest,
  userOrdersSuccess,
  userOrdersFail,
  orderSummaryRequest,
  orderSummarySuccess,
  userSummaryRequest,
  userSummaryFail,
  userSummarySuccess,
  adminOrderRemoveRequest,
  adminOrderRemoveSuccess,
  adminOrderRemoveFail,
  updateadminOrdersRequest,
  updateadminOrdersSuccess,
  updateadminOrdersFail,
  paymentOrdersRequest,
  paymentOrdersSuccess,
  paymentOrdersFail,
  ordersListRequest,
  ordersListSuccess,
  ordersListFail,
  dispatchListRequest,
  dispatchListSuccess,
  dispatchListFail,
  orderReportRequest,
  orderReportSuccess,
  orderReportFail,
  removeOrderRequest,
  removeOrderSuccess,
  removeOrderFail,
  updateDeliverRequest,
  updateDeliverSuccess,
  updateDeliverFail,
} from '../slices/orderSlice';


export const initOrder = createAsyncThunk('init/order/create', async (order, { dispatch }) => {
  try {
    dispatch(orderRequest());
    const { data } = await axios.post('/api/v1/payment/orders', order);
    dispatch(orderSuccess(data));
  } catch (error) {
    dispatch(orderFail(error.response.data.message));
  }
});

export const createOrder = createAsyncThunk('order/create', async (order, { dispatch }) => {
  try {
    dispatch(createOrderRequest());
    const { data } = await axios.post('/api/v1/order/new', order);
    dispatch(createOrderSuccess(data));
  } catch (error) {
    dispatch(createOrderFail(error.response.data.message));
  }
});

export const userOrders = createAsyncThunk('order/userOrders', async ({ startDate, endDate }, { dispatch }) => {
  try {
    dispatch(userOrdersRequest());
    // const { data } = await axios.get('/api/v1/myorders');
    const { data } = await axios.get('/api/v1/myorders', {
      params: {
        startDate,
        endDate,
      },
    });
    dispatch(userOrdersSuccess(data));
  } catch (error) {
    dispatch(userOrdersFail(error.response.data.message));
  }
});



export const orderDetail = createAsyncThunk('order/orderDetail', async (id, { dispatch }) => {
  try {
    dispatch(orderDetailRequest());
    const { data } = await axios.get(`/api/v1/order/${id}`);
    dispatch(orderDetailSuccess(data));
  } catch (error) {
    dispatch(orderDetailFail(error.response.data.message));
  }
});

export const adminOrders = createAsyncThunk('order/adminOrders', async ({ startDate, endDate }, { dispatch }) => {
  try {
    dispatch(adminOrdersRequest());
    const { data } = await axios.get('/api/v1/admin/orders', {
      params: {
        startDate,
        endDate,
      },
    }, { withCredentials: true });
    // const { data } = await axios.get('/api/v1/admin/orders', { withCredentials: true })
    dispatch(adminOrdersSuccess(data));
  } catch (error) {
    dispatch(adminOrdersFail(error.response.data.message));
  }
});

export const updateadminOrders = createAsyncThunk('order/adminOrders', async (_, { dispatch }) => {
  try {
    dispatch(updateadminOrdersRequest());
    const { data } = await axios.get('/api/v1/admin/orders', { withCredentials: true })
    dispatch(updateadminOrdersSuccess(data));
  } catch (error) {
    dispatch(updateadminOrdersFail(error.response.data.message));
  }
});

export const deleteOrder = createAsyncThunk('order/delete', async (id, { dispatch }) => {
  try {
    dispatch(deleteOrderRequest());
    await axios.delete(`/api/v1/admin/order/${id}`, { withCredentials: true });
    dispatch(deleteOrderSuccess());
  } catch (error) {
    dispatch(deleteOrderFail(error.response.data.message));
  }
});

export const porterOrder = createAsyncThunk('order/porter', async ({ reqPorterData }, { dispatch }) => {
  try {
    dispatch(porterOrderRequest());
    const { data } = await axios.post(`/api/v1/admin/porter/create/orders`, reqPorterData, { withCredentials: true });
    dispatch(porterOrderSuccess(data));
  } catch (error) {
    dispatch(porterOrderFail(error.response.data.message));
  }
});


export const updateOrder = createAsyncThunk('order/update', async ({ id, orderData }, { dispatch }) => {
  try {
    dispatch(updateOrderRequest());
    const { data } = await axios.put(`/api/v1/admin/order/${id}`, orderData, { withCredentials: true });
    dispatch(updateOrderSuccess(data));
  } catch (error) {
    dispatch(updateOrderFail(error.response.data.message));
  }
});

export const fetchOrderSummary = createAsyncThunk('ordersummary/get', async (date, { dispatch }) => {
  try {
    dispatch(orderSummaryRequest());

    const { data } = await axios.get(`/api/v1/admin/orders-summary?date=${date}`, { withCredentials: true });
    dispatch(orderSummarySuccess(data.orderSummary));
  } catch (error) {
    dispatch(orderSummarySuccess());
  }
});


export const fetchUserSummary = createAsyncThunk('usersummary/get', async (date, { dispatch }) => {
  try {
    dispatch(userSummaryRequest());

    const { data } = await axios.get(`/api/v1/admin/user-summary?date=${date}`, { withCredentials: true });
    dispatch(userSummarySuccess(data.userSummary));
  } catch (error) {
    dispatch(userSummaryFail());
  }
});

export const RemoveOrderResponse = createAsyncThunk('/order/cancelResponse', async ({ order_id, removalReason }, { dispatch }) => {
  try {
    dispatch(adminOrderRemoveRequest());
    const { data } = await axios.post('/api/v1/admin/removeOrder', { order_id, removalReason }, { withCredentials: true });
    dispatch(adminOrderRemoveSuccess(data));
  } catch (error) {
    dispatch(adminOrderRemoveFail(error.response.data.message));
  }
});


export const PaymentOrders = createAsyncThunk('order/paymentOrders', async ({ startDate, endDate }, { dispatch }) => {
  try {
    dispatch(paymentOrdersRequest());
    // const { data } = await axios.get('/api/v1/admin/orders/payments', { withCredentials: true })
    const { data } = await axios.get('/api/v1/admin/orders/payments', {
      params: {
        startDate,
        endDate,
      },
    }, { withCredentials: true });
    dispatch(paymentOrdersSuccess(data));
  } catch (error) {
    dispatch(paymentOrdersFail(error.response.data.message));
  }
});


export const OrdersList = createAsyncThunk('order/OrdersList', async ({ startDate, endDate }, { dispatch }) => {
  try {
    dispatch(ordersListRequest());
    // const { data } = await axios.get('/api/v1/admin/orders/payments', { withCredentials: true })
    const { data } = await axios.get('/api/v1/admin/orders/orderlist', {
      params: {
        startDate,
        endDate,
      },
    }, { withCredentials: true });
    dispatch(ordersListSuccess(data));
  } catch (error) {
    dispatch(ordersListFail(error.response.data.message));
  }
});


export const DispatchLists = createAsyncThunk('order/DispatchList', async ({ startDate, endDate }, { dispatch }) => {
  try {
    dispatch(dispatchListRequest());
    // const { data } = await axios.get('/api/v1/admin/orders/payments', { withCredentials: true })
    const { data } = await axios.get('/api/v1/admin/orders/dispatch', {
      params: {
        startDate,
        endDate,
      },
    }, { withCredentials: true });
    dispatch(dispatchListSuccess(data));
  } catch (error) {
    dispatch(dispatchListFail(error.response.data.message));
  }
});


export const OrdersReport = createAsyncThunk('order/OrdersReport', async ({ startDate, endDate }, { dispatch }) => {
  try {
    dispatch(orderReportRequest());
    // const { data } = await axios.get('/api/v1/admin/orders/payments', { withCredentials: true })
    const { data } = await axios.get('/api/v1/admin/orders/report', {
      params: {
        startDate,
        endDate,
      },
    }, { withCredentials: true });
    dispatch(orderReportSuccess(data));
  } catch (error) {
    dispatch(orderReportFail(error.response.data.message));
  }
});

export const editDispatchedOrder = createAsyncThunk('order/porter', async ({ reqPorterData }, { dispatch }) => {
  try {
    dispatch(removeOrderRequest());
    const { data } = await axios.post('/api/v1/admin/dispatch/update/orders', reqPorterData, { withCredentials: true });
    dispatch(removeOrderSuccess(data));
  } catch (error) {
    dispatch(removeOrderFail(error.response.data.message));
  }
});

export const updateOrderStatusToDelivered = createAsyncThunk(
  "order/updateStatusToDelivered",
  async ({id}, { dispatch }) => {
      try {
          dispatch(updateDeliverRequest());
          const response = await axios.post(
              `/api/v1/admin/order/${id}/deliver`,
              {},
              { withCredentials: true }
          );
          dispatch(updateDeliverSuccess(response.data));
          return response.data;
      } catch (error) {
          dispatch(updateDeliverFail(error.response.data.message));
      }
  }
);

