import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { clearProductUpdateCart, clearRemoveCart, clearUpdateCart, decreaseCartItemQty, increaseCartItemQty, removeItemFromCart, updateCartItemPrice, updateCartItemWeight } from '../../slices/cartSlice';
import MetaData from '../Layouts/MetaData';
import { Slide, toast } from 'react-toastify';
import store from '../../store';
import { loadUser } from '../../actions/userActions';
import { getProduct } from '../../actions/productAction';
import axios from 'axios';
import LoaderButton from '../Layouts/LoaderButton';
import NumberInput from '../Layouts/NumberInput';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CartWeightInput from '../Layouts/CartWeightInput'
import { getCart, removeFromCart, updateCart, updateCartProduct } from '../../actions/cartActions';

const Cart = ({ openSide, setOpenSide, onLoginClick }) => {
    const [item, setItems] = useState([]);
    const { items, updateLoading, updateError, updateCart: updatecartresponse, removeLoading, removeError, removeItem, ProducUpdateCart, ProductUpdateError, ProductUpdateLoading } = useSelector(state => state.cartState);
    const [weight, setWeight] = useState({});
    const [weightError, setWeightError] = useState({});
    const { isAuthenticated, user } = useSelector(state => state.authState);
    const { products } = useSelector((state) => state.productsState);
    const { getdata, geterror } = useSelector(state => state.addressState);
    const [shippingAmount, setShippingAmount] = useState(null);
    const [weighttoast, setWeightToast] = useState(false);
    const location = useLocation();
    sessionStorage.setItem('redirectPath', location.pathname);
    const [errorText, setErrorText] = useState(null);
    const [nextPage, setnextPage] = useState(true);
    const [closeToast, setCloseToast] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [getshipping, setGetshipping] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [editMode, setEditMode] = useState({});
    const [isCheckDisabled, setIsCheckDisabled] = useState({});
    const [isEditing, setIsEditing] = useState({});
    const [isUpdating, setIsUpdating] = useState({});
    const [distance, setDistance] = useState(null); // State to store calculated distance

    const [showModal, setShowModal] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);
    const [popupMessage, setPopupMessage] = useState(null); // State for the message
    const [showPopup, setShowPopup] = useState(false); // State to toggle visibility
    const [adjustedShippingCharge, setAdjustedShippingCharge] = useState(null); // To store updated shipping charge

    // Function to calculate accurate distance using Haversine formula
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const toRadians = (degree) => (degree * Math.PI) / 180;
        const R = 6371; // Earth’s radius in km
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return parseFloat((R * c).toFixed(4)); // Return distance rounded to 3 decimal places
    };


    // const shippingCharge = shippingAmount / 100;
    // // const shippingCharge = 1.00;
    // const subtotal = items.reduce((acc, item) => acc + item?.finalPrice * item.productWeight, 0).toFixed(2);
    // const total = (parseFloat(subtotal) + shippingCharge).toFixed(2);

    // const originalSubtotal = items.reduce((acc, item) => acc + item?.price * item.productWeight, 0).toFixed(2);
    // const originalTotal = (parseFloat(originalSubtotal) + shippingCharge).toFixed(2);
    // const isReducedCost = parseFloat(originalTotal - total).toFixed(2);

    // Calculate subtotal
    const subtotal = items.reduce((acc, item) => acc + item?.finalPrice * item.productWeight, 0).toFixed(2);

    // Calculate original subtotal (before discounts)
    const originalSubtotal = items.reduce((acc, item) => acc + item?.price * item.productWeight, 0).toFixed(2);

    // Base shipping charge
    const shippingCharge = shippingAmount / 100;

    useEffect(() => {
        // Only apply shipping discounts if distance is within 5 km
        let newAdjustedShippingCharge = shippingCharge;

        if (distance !== null) {
            if (distance <= 5) {
                // Apply discount logic based on subtotal
                if (subtotal >= 500 && subtotal < 1000) {
                    newAdjustedShippingCharge = shippingCharge / 2; // 50% off
                } else if (subtotal >= 1000) {
                    newAdjustedShippingCharge = 0; // Free shipping
                }
            } else {
                newAdjustedShippingCharge = shippingCharge; // No discount if distance > 5 km
            }
        }

        // Update the shipping charge state
        setAdjustedShippingCharge(newAdjustedShippingCharge);
    }, [distance, subtotal, shippingCharge]);


    // Calculate total dynamically
    const total = (parseFloat(subtotal) + adjustedShippingCharge).toFixed(2);
    const originalTotal = (parseFloat(originalSubtotal) + shippingCharge).toFixed(2);
    const isReducedCost = (parseFloat(originalTotal) - parseFloat(total)).toFixed(2);

    // Show popup message when conditions are met
    useEffect(() => {
        let message = null;
        if (distance <= 5) {
            if (subtotal >= 400 && subtotal < 500) {
                message = "You are eligible for **50% off** on shipping for orders above ₹500! ";
            } else if (subtotal >= 700 && subtotal < 1000) {
                message = "You are eligible for **FREE shipping** on orders above ₹1000!";
            }
            if (message) {
                setPopupMessage(message);
                setShowPopup(true);
            }
        }



    }, [subtotal]);


    // useEffect(() => {
    //     if(user?._id && !items){
    //         dispatch(getCart({userId:user._id}));
    //     }
    //   }, [dispatch, user]);

    const toggleEditMode = (productId) => {
        setIsEditing((prevState) => ({
            ...prevState,
            [productId]: !prevState[productId],
        }));
        setIsUpdating((prevState) => ({
            ...prevState,
            [productId]: !prevState[productId],
        }));
    };

    const handlecancelClick = (productId) => {
        setWeightError(prevErrors => ({ ...prevErrors, [productId]: '' }));
        toggleEditMode(productId);
    };


    const handleCheckClick = (productId) => {
        if (weightError[productId] || !weight[productId]) return;

        const updatedItem = items.find((item) => item.product === productId);

        // Dispatch the API action to update the database
        // dispatch({
        //     userId: user._id,
        //     productId,
        //     quantity: updatedItem.quantity,
        //     productWeight: weight[productId],
        // });
        dispatch(updateCartProduct({
            userId: user?._id,
            productId,
            quantity: updatedItem.quantity,
            productWeight: weight[productId],
        }));

        toggleEditMode(productId);
    };

    useEffect(() => {
        if (user?._id) {
            dispatch(updateCart({ userId: user._id }));
        }
    }, [dispatch, user]);

    useEffect(() => {
        // Check weights on initial render to set error messages
        const initialErrors = {};
        items.forEach(item => {
            if (!item.productWeight || item.productWeight <= 0) {
                initialErrors[item.product] = "Required";
            }
        });
        setWeightError(initialErrors);
    }, [items]);

    const defaultAddress = getdata && getdata.addresses && getdata.addresses.find(address => address?.defaultAddress) || null;

    useEffect(() => {
        if (!user) {
            store.dispatch(loadUser());
            store.dispatch(getProduct());
        }
    }, []);

    // useEffect(() => {
    //     if (updatecartresponse) {
    //         toast.dismiss();
    //         setTimeout(() => {
    //             toast.success(updatecartresponse, {
    //                 position: 'bottom-center',
    //                 type: 'success',
    //                 autoClose: 700,
    //                 transition: Slide,
    //                 hideProgressBar: true,
    //                 className: 'small-toast',
    //                 onOpen: () => { dispatch(clearUpdateCart()) }
    //             });
    //         }, 300);
    //     }
    //     if (updateError) {
    //         toast.dismiss();
    //         setTimeout(() => {
    //             toast.error(updateError, {
    //                 position: 'top-right',
    //                 type: 'error',
    //                 autoClose: 700,
    //                 transition: Slide,
    //                 hideProgressBar: true,
    //                 className: 'small-toast-input',
    //                 onOpen: () => { dispatch(clearUpdateCart()) }
    //             });
    //             // setweightvalue(false);
    //         }, 300);


    //     }

    // }, [updatecartresponse, updateError])

    useEffect(() => {
        if (removeItem) {
            toast.dismiss();
            setTimeout(() => {
                toast.success(removeItem, {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { setShowModal(false); dispatch(clearRemoveCart()) }
                });
            }, 300);
        }
        if (removeError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(removeError, {
                    position: 'top-right',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast-input',
                    onOpen: () => { dispatch(clearRemoveCart()) }
                });
                // setweightvalue(false);
            }, 300);


        }

    }, [removeItem, removeError])

    useEffect(() => {
        if (ProducUpdateCart) {
            setIsUpdating({});
            toast.dismiss();
            setTimeout(() => {
                toast.success(ProducUpdateCart, {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearProductUpdateCart()) }
                });
            }, 300);
        }
        if (ProductUpdateError) {
            setIsUpdating({})
            toast.dismiss();
            setTimeout(() => {
                toast.error(ProductUpdateError, {
                    position: 'top-right',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast-input',
                    onOpen: () => { dispatch(clearProductUpdateCart()) }
                });
                // setweightvalue(false);
            }, 300);


        }

    }, [ProducUpdateCart, ProductUpdateError])

    // useEffect(() => {
    //     // Function to update cart prices and sync with localStorage
    //     const updateCartPrices = async () => {
    //         const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

    //         const updatedItems = await Promise.all(
    //             cartItems.map(async cartItem => {
    //                 const currentItem = products.find(item => item._id === cartItem.product);
    //                 if (currentItem && currentItem.price !== cartItem.price || currentItem.offer !== cartItem.offer || currentItem.finalPrice !== cartItem.finalPrice) {
    //                     await dispatch(updateCartItemPrice({
    //                         productId: cartItem.product, newPrice: currentItem.price, newOffer: currentItem.offer,
    //                         newFinalPrice: currentItem.finalPrice
    //                     }));
    //                     // Return the updated item
    //                     return {
    //                         ...cartItem, price: currentItem.price, offer: currentItem.offer,
    //                         finalPrice: currentItem.finalPrice
    //                     };
    //                 }
    //                 return cartItem;
    //             })
    //         );

    //         // Update local storage with the new cart items
    //         localStorage.setItem('cartItems', JSON.stringify(updatedItems));
    //         setUpdated(true);
    //     };

    //     updateCartPrices();
    // }, [products, dispatch]);



    // useEffect(() => {
    //     if (updated && user?._id) {
    //         // setItems(JSON.parse(localStorage.getItem("cartItems")) || [])
    //         dispatch(getCart({userId:user?._id}));
    //     }
    // }, [updated])



    const [pickupDetails, setPickupDetails] = useState({
        lat: 13.0671844,
        lng: 80.1823796
    });

    const [dropDetails, setDropDetails] = useState(null);

    const [customerDetails, setCustomerDetails] = useState(null);

    useEffect(() => {
        if (defaultAddress && isAuthenticated) {
            setCustomerDetails({
                name: defaultAddress && defaultAddress.name && defaultAddress.name,
                countryCode: '+91',
                phoneNumber: defaultAddress && defaultAddress.phoneNo
            })
            setDropDetails({
                lat: defaultAddress && defaultAddress.latitude && defaultAddress.latitude,
                lng: defaultAddress && defaultAddress.longitude && defaultAddress.longitude
            })
        }

    }, [defaultAddress, isAuthenticated])

    // Calculate distance when dropDetails are available
    useEffect(() => {
        if (dropDetails && dropDetails.lat && dropDetails.lng) {
            const calculatedDistance = calculateDistance(
                pickupDetails.lat,
                pickupDetails.lng,
                dropDetails.lat,
                dropDetails.lng
            );
            setDistance(calculatedDistance.toFixed(2)); // Store rounded distance
            // console.log("Calculated Distance (Radius in KM):", calculatedDistance.toFixed(2));
        }
    }, [dropDetails]);



    useEffect(() => {
        const fetchdata = async () => {
            setGetshipping(true);
            const requestData = {
                pickup_details: pickupDetails,
                drop_details: dropDetails,
                customer: {
                    name: customerDetails.name,
                    mobile: {
                        country_code: customerDetails.countryCode,
                        number: customerDetails.phoneNumber
                    }
                }
            };
            try {
                const response = await axios.post('/api/v1/get-quote', requestData);
                const twoWheelerVehicle = response?.data?.vehicles.find(vehicle =>
                    vehicle.type && vehicle.type.includes("2 Wheeler")
                );

                if (twoWheelerVehicle && twoWheelerVehicle.fare) {
                    // Set the shipping amount for "2 Wheeler"
                    setShippingAmount(twoWheelerVehicle.fare.minor_amount);
                    setGetshipping(false);
                    setnextPage(true);
                    setErrorText(null);
                }
                else {
                    toast.dismiss();
                    setTimeout(() => {
                        toast.error('No 2 Wheeler found in the vehicle list', {
                            position: 'bottom-center',
                            type: 'error',
                            autoClose: 700,
                            transition: Slide,
                            hideProgressBar: true,
                            className: 'small-toast',
                        });
                    }, 300);
                    setGetshipping(false);
                    setErrorText('No 2 Wheeler found in the vehicle list');
                    setnextPage(false);
                }
            } catch (error) {
                toast.dismiss();
                setTimeout(() => {
                    toast.error(error?.response?.data?.message, {
                        position: 'bottom-center',
                        type: 'error',
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: 'small-toast',
                    });
                }, 300);
                setGetshipping(false);
                setnextPage(false);
                setErrorText(error?.response?.data?.message);
                // Handle error as needed
            }
        }
        if (defaultAddress && dropDetails && customerDetails) {
            fetchdata()
        }

    }, [dropDetails])

    const handleDeleteClick = (product) => {
        setProductToDelete(product);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        // dispatch(removeItemFromCart(productToDelete));
        dispatch(removeFromCart({ userId: user?._id, productId: productToDelete }));
        // setShowModal(false);
        // toast.dismiss();
        // setTimeout(() => {
        //     toast.success('Item removed from Cart', {
        //         position: 'bottom-center',
        //         type: 'success',
        //         autoClose: 700,
        //         transition: Slide,
        //         hideProgressBar: true,
        //         className: 'small-toast',
        //         // onOpen: () => { setItems(JSON.parse(localStorage.getItem("cartItems")) || []); }
        //     });
        // }, 300);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    const capitalizeFirstLetter = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };


    const handelAddAddress = () => {
        if (user && isAuthenticated) {
            navigate('/shipping');
        }
        else {
            onLoginClick();
        }
    }

    const checkOutHandler = (e) => {
        e.preventDefault();
        const invalidItems = items.filter(item => !item.productWeight || item.productWeight <= 0);
        const invalidquantity = items.filter(item => item.measurement === 'Kg' && item.productWeight < 0.25);
        if (invalidItems.length > 0 && !closeToast) {
            setErrorText("Please set a valid quantity for all products in the cart.");
            setCloseToast(true);
            toast.dismiss();
            setTimeout(() => {
                toast.error("Please set a valid quantity for all products in the cart.", {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
                setCloseToast(false);
            }, 300);
            return; // Stop checkout
        }
        else if (invalidquantity.length > 0 && !closeToast) {
            setErrorText("Weight should Not be Less than 0.25kg.");
            setCloseToast(true);
            toast.dismiss();
            setTimeout(() => {
                toast.error("Weight should Not be Less than 0.25kg.", {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
                setCloseToast(false);
            }, 300);
            return; // Stop checkout
        }
        else if (!nextPage && !closeToast) {
            setCloseToast(true);
            toast.dismiss();
            setTimeout(() => {
                toast.error(errorText, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
                setCloseToast(false);
            }, 300);
            return;
        }
        else if (nextPage) {
            if (isAuthenticated && user && defaultAddress) {
                navigate('/order/confirm', { state: { shippingCharge: adjustedShippingCharge, porterShippingCharge: shippingCharge, defaultAddress, subtotal, total, items, originalSubtotal, originalTotal } });
            }
            else if (isAuthenticated && user && !defaultAddress) {
                navigate('/address');
            }
            else {
                onLoginClick();
            }
        }
    };

    // const handleWeightChange = (productId, value, productCategory, productMeasurement, item) => {
    //     // Allow clearing the input
    //     if (value === '' || value === null || value === 0) {
    //         setWeight(prevWeights => ({ ...prevWeights, [productId]: '' }));
    //         if (weight[productId] === null || weight[productId] === 0 || weight[productId] === '') {
    //             setWeightError(prevErrors => ({ ...prevErrors, [productId]: "Required" }));
    //         }

    //         // Update localStorage with the empty value for real-time sync
    //         const updatedItems = items.map(item =>
    //             item.product === productId ? { ...item, productWeight: '' } : item
    //         );
    //         setItems(updatedItems);
    //         localStorage.setItem("cartItems", JSON.stringify(updatedItems));

    //         return;
    //     }

    //     // Validate value format based on category and measurement
    //     let validValue;
    //     if (productMeasurement === 'Piece' || productMeasurement === 'Box' || productMeasurement === 'Grams') {
    //         validValue = value.match(/^\d*$/) ? value : weight[productId]; // Only whole numbers allowed
    //         setWeightError(prevErrors => ({ ...prevErrors, [productId]: '' }));
    //     } else {
    //         validValue = value.match(/^\d*\.?\d{0,2}$/) ? value : weight[productId];
    //         setWeightError(prevErrors => ({ ...prevErrors, [productId]: '' }));
    //     }

    //     const weightValue = parseFloat(validValue);
    //     if (weightValue < 0) return;

    //     if (productMeasurement === 'Piece' || productMeasurement === 'Grams') {
    //         if (weightValue > 10) {
    //             setWeight(prevWeights => ({ ...prevWeights, [productId]: '' }));
    //             if (item.productWeight === null || item.productWeight === '' || item.productWeight === 0) {
    //                 setWeightError(prevErrors => ({ ...prevErrors, [productId]: "Required" }));
    //             }
    //             if (!weighttoast) {
    //                 setWeightToast(true);
    //                 toast.dismiss();
    //                 setTimeout(() => {
    //                     toast.error("Piece count cannot exceed 10", {
    //                         position: 'bottom-center',
    //                         type: 'error',
    //                         autoClose: 700,
    //                         transition: Slide,
    //                         hideProgressBar: true,
    //                         className: 'small-toast',
    //                     });
    //                     setCloseToast(false);
    //                     setWeightToast(false);
    //                 }, 300);
    //             }

    //             return;
    //         }
    //     } else if (productMeasurement === 'Box') {
    //         if (weightValue > 10) {
    //             setWeight(prevWeights => ({ ...prevWeights, [productId]: '' }));
    //             if (item.productWeight === null || item.productWeight === '' || item.productWeight === 0) {
    //                 setWeightError(prevErrors => ({ ...prevErrors, [productId]: "Required" }));
    //             }
    //             if (!weighttoast) {
    //                 setWeightToast(true);
    //                 toast.dismiss();
    //                 setTimeout(() => {
    //                     toast.error("Box count cannot exceed 10", {
    //                         position: 'bottom-center',
    //                         type: 'error',
    //                         autoClose: 700,
    //                         transition: Slide,
    //                         hideProgressBar: true,
    //                         className: 'small-toast',
    //                     });
    //                     setCloseToast(false);
    //                     setWeightToast(false);
    //                 }, 300);
    //             }
    //             return;
    //         }
    //     }
    //     if (item.productWeight < 0.25) {
    //         setWeightError(prevErrors => ({ ...prevErrors, [productId]: "Minimum 0.25" }));
    //     }
    //     else if (weightValue > 5) {
    //         setWeight(prevWeights => ({ ...prevWeights, [productId]: '' }));
    //         if (item.productWeight === null || item.productWeight === '' || item.productWeight === 0) {
    //             setWeightError(prevErrors => ({ ...prevErrors, [productId]: "Required" }));
    //         }
    //         if (!weighttoast) {
    //             setWeightToast(true);
    //             toast.dismiss();
    //             setTimeout(() => {
    //                 toast.error("Weight cannot exceed 5Kg", {
    //                     position: 'bottom-center',
    //                     type: 'error',
    //                     autoClose: 700,
    //                     transition: Slide,
    //                     hideProgressBar: true,
    //                     className: 'small-toast',
    //                 });
    //                 setCloseToast(false);
    //                 setWeightToast(false);
    //             }, 300);
    //         }
    //         return;
    //     }

    //     // Update weight in state and local storage
    //     const updatedItems = items.map(item =>
    //         item.product === productId ? { ...item, productWeight: weightValue } : item
    //     );
    //     setItems(updatedItems);
    //     localStorage.setItem("cartItems", JSON.stringify(updatedItems));
    //     setItems(JSON.parse(localStorage.getItem("cartItems")) || []);
    // };

    // const handleWeightChange = (productId, value, productCategory, productMeasurement, item, maximumQuantity) => {

    //     if (value === '' || value === null || value === 0) {
    //         setWeight(prevWeights => ({ ...prevWeights, [productId]: '' }));
    //         if (weight[productId] === null || weight[productId] === 0 || weight[productId] === '') {
    //             setWeightError(prevErrors => ({ ...prevErrors, [productId]: "Required" }));
    //         }

    //         // Update localStorage with the empty value for real-time sync
    //         const updatedItems = items.map(item =>
    //             item.product === productId ? { ...item, productWeight: '' } : item
    //         );
    //         // setItems(updatedItems);
    //         localStorage.setItem("cartItems", JSON.stringify(updatedItems));

    //         return;
    //     }

    //     let validValue;
    //     if (productMeasurement === "Kg") {
    //         // Allow leading zero, optional decimal, and up to two decimal places
    //         validValue = value.match(/^(0|[1-9]\d*)?(\.\d{0,2})?$/) ? value : weight[productId];
    //         setWeightError(prevErrors => ({ ...prevErrors, [productId]: '' }));
    //     } else {
    //         // Only whole numbers allowed
    //         validValue = value.match(/^\d*$/) ? value : weight[productId];
    //         setWeightError(prevErrors => ({ ...prevErrors, [productId]: '' }));
    //     }

    //     if (value === "") {
    //         setWeight((prevWeights) => ({ ...prevWeights, [productId]: "" }));
    //         return;
    //     }

    //     const weightValue = parseFloat(validValue);

    //     // Prevent invalid values
    //     if (isNaN(weightValue) || weightValue < 0) {
    //         return;
    //     }

    //     if (productMeasurement) {
    //         if (weightValue > maximumQuantity) {
    //             setWeightToast(true);
    //             toast.dismiss();
    //             if (!weighttoast) {
    //                 setTimeout(() => {
    //                     toast.error(`Cannot exceed ${maximumQuantity} ${productMeasurement}`, {
    //                         position: 'top-right',
    //                         type: 'error',
    //                         autoClose: 700,
    //                         transition: Slide,
    //                         hideProgressBar: true,
    //                         className: 'small-toast-input',
    //                     });
    //                     setWeightToast(false);
    //                 }, 300);
    //             }

    //             setWeight((prevWeights) => ({ ...prevWeights, [productId]: "" }));
    //             return;
    //         }
    //     }


    //     // Update weight in state and local storage
    //     const updatedItems = items.map(item =>
    //         item.product === productId ? { ...item, productWeight: weightValue } : item
    //     );
    //     // setItems(updatedItems);
    //     localStorage.setItem("cartItems", JSON.stringify(updatedItems));
    //     // setItems(JSON.parse(localStorage.getItem("cartItems")) || []);
    // };


    // const handleWeightChange = (productId, value, productCategory, productMeasurement, item, maximumQuantity) => {
    //     // Initialize errors and valid values
    //     let validValue;
    //     let weightValue = parseFloat(value);

    //     // Validate input based on measurement
    //     if (productMeasurement === "Kg") {
    //         // Allow leading zero, optional decimal, and up to two decimal places
    //         validValue = value.match(/^(0|[1-9]\d*)?(\.\d{0,2})?$/) ? value : weight[productId];

    //         // Check if the value is below 0.25 Kg for invalid weight
    //         if (validValue && weightValue < 0.25) {
    //             setWeightError(prevErrors => ({ ...prevErrors, [productId]: 'Weight must be greater than or equal to 0.25 Kg' }));
    //             setWeight(prevWeights => ({ ...prevWeights, [productId]: validValue }));
    //             return;
    //         }
    //     } else {
    //         // Only whole numbers allowed
    //         validValue = value.match(/^\d*$/) ? value : weight[productId];
    //     }

    //     // Set error messages for invalid input
    //     if (!validValue) {
    //         setWeight(prevWeights => ({ ...prevWeights, [productId]: '' }));
    //         setWeightError(prevErrors => ({ ...prevErrors, [productId]: 'Invalid weight' }));
    //         return;
    //     }

    //     weightValue = parseFloat(validValue);

    //     // Validate weight against maximum quantity
    //     if (weightValue > maximumQuantity) {
    //         setWeightToast(true);
    //         toast.dismiss();
    //         if (!weighttoast) {
    //             toast.error(`Cannot exceed ${maximumQuantity} ${productMeasurement}`, {
    //                 position: 'top-right',
    //                 autoClose: 700,
    //                 hideProgressBar: true,
    //                 className: 'small-toast-input',
    //             });
    //         }
    //         setWeight(prevWeights => ({ ...prevWeights, [productId]: '' }));
    //         setWeightError(prevErrors => ({ ...prevErrors, [productId]: 'Exceeds maximum quantity' }));
    //         return;
    //     }

    //     // Remove error and update weight
    //     setWeightError(prevErrors => ({ ...prevErrors, [productId]: '' }));
    //     setWeight(prevWeights => ({ ...prevWeights, [productId]: validValue }));

    //     // Update localStorage for real-time sync
    //     const updatedItems = items.map(item =>
    //         item.product === productId ? { ...item, productWeight: weightValue } : item
    //     );
    //     localStorage.setItem('cartItems', JSON.stringify(updatedItems));
    // };


    const handleWeightChange = (productId, value, productCategory, productMeasurement, item, maximumQuantity, minimumQuantity) => {
        // Initialize errors and valid values
        let validValue;
        let weightValue = parseFloat(value);

        // Validate input based on measurement
        if (productMeasurement === "Kg") {
            // Allow leading zero, optional decimal, and up to two decimal places
            validValue = value.match(/^(0|[1-9]\d*)?(\.\d{0,2})?$/) ? value : weight[productId];

            // Check if the value is below 0.25 Kg for invalid weight
            if (validValue && weightValue < minimumQuantity) {
                setWeightError(prevErrors => ({ ...prevErrors, [productId]: `Weight must be greater than or equal to ${minimumQuantity}${productMeasurement}` }));
                setWeight(prevWeights => ({ ...prevWeights, [productId]: validValue }));
                return;
            }
        } else {
            // Only whole numbers allowed
            validValue = value.match(/^\d*$/) ? value : weight[productId];
            // Check if the value is below 0.25 Kg for invalid weight
            if (validValue && weightValue < minimumQuantity) {
                setWeightError(prevErrors => ({ ...prevErrors, [productId]: `Weight must be greater than or equal to ${minimumQuantity}${productMeasurement}` }));
                setWeight(prevWeights => ({ ...prevWeights, [productId]: validValue }));
                return;
            }

        }

        // Set error messages for invalid input
        if (!validValue) {
            setWeight(prevWeights => ({ ...prevWeights, [productId]: '' }));
            setWeightError(prevErrors => ({ ...prevErrors, [productId]: 'Invalid weight' }));
            return;
        }

        weightValue = parseFloat(validValue);

        // Validate weight against maximum quantity
        if (weightValue > maximumQuantity) {
            setWeightToast(true);
            toast.dismiss();
            if (!weighttoast) {
                toast.error(`Cannot exceed ${maximumQuantity} ${productMeasurement}`, {
                    position: 'top-right',
                    autoClose: 700,
                    hideProgressBar: true,
                    className: 'small-toast-input',
                });
            }
            setWeight(prevWeights => ({ ...prevWeights, [productId]: '' }));
            setWeightError(prevErrors => ({ ...prevErrors, [productId]: 'Exceeds maximum quantity' }));
            return;
        }


        // Remove error and update weight
        setWeightError(prevErrors => ({ ...prevErrors, [productId]: '' }));
        setWeight(prevWeights => ({ ...prevWeights, [productId]: validValue }));

        // Update localStorage for real-time sync
        const updatedItems = items.map(item =>
            item.product === productId ? { ...item, productWeight: weightValue } : item
        );
        localStorage.setItem('cartItems', JSON.stringify(updatedItems));
    };

    return (
        <div style={{ display: 'flex', position: 'relative', flexDirection: 'column' }}>
            <MetaData
                title="Your Shopping Cart"
                description="Review the items in your shopping cart. Adjust quantities, remove products, or proceed to checkout for a seamless shopping experience."
            />
            <div style={{ display: 'flex', position: 'relative', flexDirection: 'column', minWidth: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', position: 'fixed', width: '100%', zIndex: '990', height: '100px', marginBottom: '20px' }}>
                    {defaultAddress ? (

                        <div className="shadow-sm default-address">

                            <h5>Deliver To</h5>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ marginLeft: '20px' }}>
                                    <div>{defaultAddress.gender === 'Male' ? 'Mr. ' : defaultAddress.gender === 'Female' ? 'Ms. ' : ''}{defaultAddress.name}</div>
                                    <div>+91 {defaultAddress.phoneNo}</div>
                                    <div>{defaultAddress.formattedAddress}</div>
                                </div>

                                <Link to='/address'>Manage Address</Link>
                            </div>
                        </div>

                    ) : (
                        <div className="shadow-sm default-address">

                            <h5>Shipping Address</h5>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: '5px' }}>

                                <button onClick={handelAddAddress} >+ Add Address</button>
                            </div>
                        </div>
                    )}
                </div>



                <Fragment>
                    <div className="products_heading">Cart</div>
                    <div className="mt-5">
                        <span className="back-button" onClick={() => navigate(-1)}>
                            <ArrowBackIcon fontSize="small" />
                            <span>Back</span>
                        </span>

                    </div>

                    <div className="container cart-detail-container mt-5 " >

                        {items && items.length === 0 ? (
                            <div className="Cartproduct-not-found">
                                <img src="../images/empty-cart.jpg" alt="No Products Found" />
                                <p>Your Cart is Empty</p>
                                <Link to="/"> <button className="btn ms-2" style={{ backgroundColor: '#02441E', color: 'white' }}> Add Items</button> </Link>

                            </div>
                        ) : (<div className="" >
                            <h2 className="cart_text">Your Cart: <b>{items.filter(item => item.stocks === "Stock").length}</b></h2>
                            <div className="invoice-table-container">
                                <table className="updatetable updatetable-bordered">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                            <th>Total</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {items.filter(item => item.stocks === "Stock").map((item, index) => (

                                            <tr key={item.product}>
                                                <td>{index + 1}</td>
                                                {/* <td>
                                                    <img src={item.image} alt={item.name} height="45" width="65" className='updateTableproduct-image' />
                                                </td> */}
                                                <td className="products-image" >
                                                    {item.offer > 0 && item.finalPrice > 0 && (
                                                        <div style={{
                                                            position: 'relative',
                                                            width: '20px',
                                                            height: '40px',
                                                            backgroundColor: 'green',
                                                            color: 'white',
                                                            fontSize: '12px',
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            lineHeight: '15px',
                                                            borderRadius: '0px',
                                                            clipPath: 'polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0% 85%)', // Curved arrow shape
                                                            overflow: 'hidden',
                                                        }}>
                                                            {/* Coin Icons */}
                                                            <div style={{
                                                                position: 'absolute',
                                                                top: '-10px',
                                                                left: '50%',
                                                                transform: 'translateX(-50%)',
                                                                display: 'flex',
                                                                gap: '2px',
                                                            }}>
                                                                <div style={{
                                                                    width: '15px',
                                                                    height: '15px',
                                                                    backgroundColor: 'gold',
                                                                    borderRadius: '50%',
                                                                }}></div>
                                                                <div style={{
                                                                    width: '15px',
                                                                    height: '15px',
                                                                    backgroundColor: 'gold',
                                                                    borderRadius: '50%',
                                                                }}></div>
                                                                <div style={{
                                                                    width: '15px',
                                                                    height: '15px',
                                                                    backgroundColor: 'gold',
                                                                    borderRadius: '50%',
                                                                }}></div>
                                                            </div>

                                                            {/* Best Offer Text */}
                                                            <div style={{
                                                                position: 'absolute',
                                                                top: '10px',
                                                                width: '100%',
                                                            }}>
                                                                {/* <div style={{ fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center', textTransform: 'uppercase' }}>OFFER</div> */}
                                                                {/* <span style={{ fontSize: '8px', display: 'block', textTransform: 'uppercase' }}>OFFER</span> */}
                                                                <div style={{
                                                                    position: 'relative',
                                                                    bottom: '5px',
                                                                    marginTop: '0px',
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    width: '100%',
                                                                    fontSize: '8px',
                                                                    animation: 'scrollDown 2s linear infinite',
                                                                }}>
                                                                    <div style={{ margin: '0px', padding: '0px' }}>
                                                                        {item.offer} %
                                                                    </div>
                                                                    <div style={{ margin: '0px', padding: '0px' }}>
                                                                        OFF
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            {/* Percentage Off */}

                                                        </div>


                                                    )}

                                                    {/* {item.images[0] && item?.images[0]?.image && ( */}
                                                    <img src={item.image} alt={item.name} height="45" width="65" className='updateTableproduct-image' />
                                                    {/* )} */}
                                                </td>
                                                <td>{item && item.range ? `${capitalizeFirstLetter(item.name)} (${item.range})` : `${capitalizeFirstLetter(item.name)}`} </td>
                                                {/* <td>RS.{(item.price).toFixed(2)}</td> */}
                                                <td>
                                                    {
                                                        item?.offer > 0 && item.finalPrice > 0 ? (
                                                            <>
                                                                <div style={{ textDecoration: 'line-through', color: 'red' }}>
                                                                    ₹ {item.price} (per {item?.measurement})
                                                                </div>
                                                                <div>
                                                                    ₹ {item.finalPrice} (per {item?.measurement})
                                                                </div>

                                                            </>
                                                        ) : (
                                                            <>
                                                                ₹ {item.finalPrice} (per {item?.measurement})
                                                            </>
                                                        )
                                                    }
                                                </td>

                                                <td>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '100%' }} >

                                                        <CartWeightInput item={item} handleWeightChange={handleWeightChange} weight={weight} editable={isEditing[item.product]} disabled={!isEditing[item.product]} />
                                                        {/* <span style={{ whiteSpace: 'nowrap', marginLeft: '2px' }}>
                                                            {item.measurement && item.measurement == 'Grams' ? 'Piece' : item.measurement}
                                                        </span> */}
                                                    </div>
                                                    {/* <datalist id={weight-options-${item.product}}>
                                                        {item && item.measurement === 'Kg'
                                                            ? [...Array(3).keys()].map(i => (
                                                                <option key={i} value={(i + 1) * 0.5}></option>
                                                            ))
                                                            : [...Array(3).keys()].map(i => (
                                                                <option key={i} value={i + 1}>{i + 1}</option>
                                                            ))}
                                                    </datalist> */}
                                                    {weightError[item.product] && (
                                                        <div className="error-message">{weightError[item.product]}</div>
                                                    )}
                                                </td>

                                                <td>
                                                    {/* Rs.{(item.finalPrice * item.productWeight).toFixed(2)} */}
                                                    {!isEditing[item.product] ? (
                                                        <div>
                                                            ₹ {(item.finalPrice * item.productWeight).toFixed(2)}
                                                        </div>

                                                    ) : (
                                                        <div>
                                                            ₹ {(item.finalPrice * (weight[item.product] ? parseFloat(weight[item.product]) : 0)).toFixed(2)}
                                                        </div>

                                                    )}
                                                </td>
                                                <td>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div>
                                                            {isEditing[item.product] && parseFloat(weight[item.product]) >= 0.25 ? (
                                                                <i
                                                                    id="check_cart_item"
                                                                    className="fa fa-check btn btn-success"
                                                                    onClick={() => handleCheckClick(item.product)}
                                                                    disabled={!!weightError[item.product]}
                                                                ></i>

                                                            ) : isEditing[item.product] && !weight[item.product] ? (
                                                                <i
                                                                    id="cancel_cart_item"
                                                                    className="fa fa-times btn btn-danger"
                                                                    onClick={() => handlecancelClick(item.product)}
                                                                // disabled={!!weightError[item.product]}
                                                                ></i>
                                                            ) : ProductUpdateLoading ? (
                                                                <i id=""><LoaderButton fullPage={false} size={10} /></i>
                                                            ) : (
                                                                <i
                                                                    id="edit_cart_item"
                                                                    className="fa fa-pencil btn btn-primary"
                                                                    onClick={() => toggleEditMode(item.product)}
                                                                ></i>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <i
                                                                id="delete_cart_item"
                                                                className="fa fa-trash btn btn-danger"
                                                                onClick={() => handleDeleteClick(item.product)}
                                                            ></i>
                                                        </div>

                                                    </div>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                            <div className="row justify-content-end">
                                <div className="col-12 col-md-6 col-lg-4 my-4 d-flex justify-content-md-end align-items-center">
                                    <span style={{ marginRight: '10px' }}><b>Missed Something?</b> </span>
                                    <Link to="/"> <button className="btn ms-2" style={{ backgroundColor: '#02441E', color: 'white' }}>+ Add more items</button> </Link>
                                </div>
                            </div>

                            <div className="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {/* <div className="col-12 col-lg-8 my-4">
                                    <div id="order_summary">
                                        <h4 className="cart_text">🚚 Special Shipping Offer! 🎉</h4>
                                        <hr />
                                        <p className="shipping_text"> Enjoy **50% off** on shipping for orders above ₹500!</p>
                                        <p className="shipping_text"> Enjoy **FREE shipping** on orders above ₹1000!</p>
                                    </div>
                                </div> */}

                                <div className="col-12 col-lg-4 my-4">
                                    <div id="order_summary">
                                        {/* <h4 className="cart_text">Cart Totals</h4>
                                        <hr />
                                        <p>Subtotal: <span className="order-summary-values">₹ {subtotal}</span></p>

                                        <p style={{ marginBottom: '30px' }}>
                                            Shipping:
                                            {distance <= 5 && defaultAddress ? (
                                                <div style={{ display: 'flex', flexDirection: 'column', float: 'right' }}>
                                                    {subtotal < 500 && (
                                                        <span className="order-summary-values">
                                                            ₹{shippingCharge && shippingCharge.toFixed(2)}
                                                        </span>
                                                    )}
                                                    {subtotal >= 500 && subtotal < 1000 && (
                                                        <>
                                                            <span className="order-summary-values" style={{ textDecoration: 'line-through', color: 'red', fontSize: '14px' }}>
                                                                ₹{shippingCharge && shippingCharge.toFixed(2)}
                                                            </span>
                                                            <span className="order-summary-values">
                                                                ₹{(shippingCharge / 2).toFixed(2)}
                                                            </span>
                                                        </>
                                                    )}
                                                    {subtotal >= 1000 && (
                                                        <>
                                                            <span className="order-summary-values" style={{ textDecoration: 'line-through', color: 'red', fontSize: '14px' }}>
                                                                ₹{shippingCharge && shippingCharge.toFixed(2)}
                                                            </span>
                                                            <span className="order-summary-values">₹0.00</span>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <span className="order-summary-values">₹{shippingCharge && shippingCharge.toFixed(2)}</span>
                                            )}
                                        </p>

                                        <hr />
                                        <p className="confirmorder_name">Total:
                                            <div style={{ float: 'right', position: "relative" }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', float: 'right' }} className="order-summary-values">
                                                    {isReducedCost > 0 && (
                                                        <div style={{ float: 'right', textDecoration: 'line-through', color: 'red' }}>
                                                            <span className="order-summary-values" style={{ float: 'right', textDecoration: 'line-through', color: 'red' }}>₹ {originalTotal}</span>
                                                        </div>
                                                    )

                                                    }

                                                    <div>
                                                        <span className="order-summary-values">₹ {total}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                        <p style={{ fontSize: '0.8rem', color: '#6c757d', marginTop: '0px' }}>
                                            (Inclusive of all taxes)
                                        </p>
                                        {isReducedCost > 0 && (
                                            <p style={{ fontSize: '0.8rem', color: 'green', marginTop: '0px' }}>
                                                (You will save ₹ {isReducedCost} in this Order.)
                                            </p>
                                        )}
                                        {showPopup && distance <= 5 && defaultAddress && (
                                            <div className="top-popup">
                                                <p>{popupMessage}</p>
                                                <button className="close-btn" onClick={() => setShowPopup(false)}>&times;</button>
                                            </div>
                                        )}

                                        <hr />
                                       
                                        <button id="checkout_btn" className="btn btn-block" disabled={getshipping} onClick={checkOutHandler}>
                                            {getshipping ? <LoaderButton fullPage={false} size={20} /> : (
                                                <span>Check Out</span>
                                            )
                                            }

                                        </button> */}

                                        <h4 className="cart_text">Cart Totals</h4>
                                        <hr />
                                        <p>Subtotal: <span className="order-summary-values">₹ {subtotal}</span></p>
                                        <p>Shipping:
                                            {distance <= 5 && defaultAddress ? (
                                                <span className="order-summary-values">
                                                    {subtotal < 500 && `₹${shippingCharge.toFixed(2)}`}
                                                    {subtotal >= 500 && subtotal < 1000 && (
                                                        <>
                                                            <span style={{ textDecoration: 'line-through', color: 'red' }}>₹{shippingCharge.toFixed(2)}</span> ₹{(shippingCharge / 2).toFixed(2)}
                                                        </>
                                                    )}
                                                    {subtotal >= 1000 && (
                                                        <>
                                                            <span style={{ textDecoration: 'line-through', color: 'red' }}>₹{shippingCharge.toFixed(2)}</span> ₹0.00
                                                        </>
                                                    )}
                                                </span>
                                            ) : (
                                                <span className="order-summary-values">₹{shippingCharge.toFixed(2)}</span>
                                            )}
                                        </p>
                                        <hr />
                                        <p className="confirmorder_name">Total: <span className="order-summary-values">₹ {total}</span></p>
                                        <p style={{ fontSize: '0.8rem', color: '#6c757d' }}>(Inclusive of all taxes)</p>
                                        {isReducedCost > 0 && (
                                            <p style={{ fontSize: '0.8rem', color: 'green' }}>(You will save ₹ {isReducedCost} in this Order.)</p>
                                        )}
                                        {showPopup && distance <= 5 && defaultAddress && (
                                            <div className="top-popup">
                                                <p>{popupMessage}</p>
                                                <button className="close-btn" onClick={() => setShowPopup(false)}>&times;</button>
                                            </div>
                                        )}

                                        {/* Checkout Button for Large Screens (Hides below 990px) */}
                                        <button id="checkout_btn" className="btn btn-block" disabled={getshipping} onClick={checkOutHandler}>
                                            {getshipping ? <LoaderButton fullPage={false} size={20} /> : "Confirm & Pay"}
                                        </button>
                                    </div>

                                    {/* Checkout Button for Small Screens (Shows below 990px) */}
                                    <div id="checkout_button_container">
                                        <button id="checkout_btn" disabled={getshipping} onClick={checkOutHandler}>                                            {getshipping ? (
                                                <LoaderButton fullPage={false} size={20} />
                                            ) : (
                                                <>
                                                    Confirm & Pay <strong>₹{total}</strong>
                                                </>
                                            )}

                                        </button>
                                        <style>
                                            {`
                .top-popup {
                    position: fixed;
                    top: -50px; /* Start off-screen */
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: #02441E;
                    color: white;
                    padding: 15px 25px;
                    width: fit-content;
                    max-width: 600px;
                    text-align: center;
                    font-weight: bold;
                    border-radius: 8px;
                    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
                    z-index: 1000;
                    animation: slideDown 0.8s ease-out forwards, fadeOut 0.5s ease-in 4s forwards;
                }

                /* Close Button */
            .close-btn {
                background: none;
                border: none;
                color: white !important;
                // font-size: 16px;
                cursor: pointer;
                position: absolute;
                top: 4px;
                right: 8px;
            }

            .close-btn:hover {
                color: white !important;
            }

                
                /* Slide-down effect */
                @keyframes slideDown {
                    from {
                        top: -50px;
                        opacity: 0;
                    }
                    to {
                        top: 80px; /* Moves into view */
                        opacity: 1;
                    }
                }
                /* Responsive Styles for Small Screens */
@media (max-width: 768px) {
    .top-popup {
        max-width: 100%; /* Make it fit smaller screens */
        font-size: 12px; /* Reduce text size */
        padding: 10px 15px; /* Adjust padding */
        top: 60px; /* Adjust position */
    }

    .close-btn {
        font-size: 12px; /* Reduce button size */
        top: 2px; 
        right: 2px;
    }
}

@media (max-width: 480px) {
    .top-popup {
        max-width: 100%;
        font-size: 9px;
        padding: 10px 8px;
        top: 50px;
    }

    .close-btn {
        font-size: 10px;
        top: 2px;
        right: 1px;
    }
}
                
                `}
                                        </style>
                                    </div>
                                </div>
                            </div>
                        </div>)}

                    </div>
                    {showModal && (
                        <div className="modal" tabIndex="-1" role="dialog" style={modalStyle}>
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Confirm Delete</h5>
                                        <button type="button" className="close" onClick={handleCancelDelete}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure you want to delete this item?</p>
                                    </div>
                                    <div className="modal-footer">

                                        <button type="button" className="btn btn-danger" disabled={removeLoading} onClick={handleConfirmDelete}>
                                            {removeLoading ? <LoaderButton fullPage={false} size={20} /> : (
                                                <span>OK</span>
                                            )

                                            }

                                        </button>
                                        <button type="button" className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Fragment>

                <div>




                </div>
            </div>

        </div>
    );
};

const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)'
};

export default Cart;
