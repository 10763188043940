import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { updateProfile, clearAuthError } from '../../actions/userActions';
import { Slide, toast } from 'react-toastify'
import { clearUpdateProfile } from '../../slices/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import MetaData from '../Layouts/MetaData';
import LoaderButton from '../Layouts/LoaderButton';
import NumberInput from '../Layouts/NumberInput';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const UpdateProfile = () => {

    const { profileupdateloading, error, user, isUpdated } = useSelector(state => state.authState);
    const [name, setName] = useState("");
    const [gender, setGender] = useState("");
    const [Update, setUpdate] = useState(false);
    const [avatar, setAvatar] = useState("");
    const [avatarName, setAvatarName] = useState('');
    const [avatarPreview, setAvatarPreview] = useState("/images/default_avatar.png");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();


    const onChangeAvatar = (e) => {
        const file = e.target.files[0];
        const fileSizeLimit = 1 * 1024 * 1024; // 1 MB

        if (file && file.size > fileSizeLimit) {

            toast.dismiss();
            setTimeout(() => {
                toast.error('The size of selected images exceeds the 1MB limit.', {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
            e.target.value = ''; // Clear the file input
            setAvatarName('');
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setAvatarPreview(reader.result);
                setAvatar(file);
                setAvatarName(file.name);

            }
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const removeAvatar = () => {
        setAvatar(null); // Reset avatar
        setAvatarPreview("/images/default_avatar.png"); // Reset avatar preview to default
        setAvatarName(''); // Clear the file name
    };


    const submitHandler = (e) => {
        e.preventDefault();
        setUpdate(true);
        const formData = new FormData();
        formData.append('name', name);
        formData.append('gender', gender);
        formData.append('avatar', avatar);
        dispatch(updateProfile(formData))

    }

    useEffect(() => {
        if (user) {
            setName(user.name);
            setGender(user.gender)
            if (user.avatar && user.avatar !== 'default_avatar.png') {
                setAvatarPreview(user.avatar)
            }
        }

        if (isUpdated && Update) {
            toast.dismiss();
            setTimeout(() => {
                toast.success('Profile updated successfully', {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { navigate('/myProfile'); dispatch(clearUpdateProfile()) },
                    onClose: () => { setUpdate(false) }
                });
            }, 300);
        }

        if (error) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(error, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearUpdateProfile()) }
                });
            }, 300);
            return
        }
    }, [user, isUpdated, error, dispatch])



    return (
        <div>
            <MetaData
                title="Update Profile"
                description="Update your personal details, manage your account settings, and ensure your information is always up-to-date for a smooth shopping experience."
            />


            <div className="products_heading">Update Profile</div>
            <div className="">
                            <span className="back-button" onClick={() => navigate(-1)}>
                                <ArrowBackIcon fontSize="small" />
                                <span>Back</span>
                            </span>

                        </div>

            <div className="row wrapper">

                <div className="col-10 col-lg-5">
                    <form onSubmit={submitHandler} className="shadow-lg mt-0" encType='multipart/form-data'>
                        <h5 className="mt-2 mb-5">Update Profile</h5>
                        <div className="form-group">
                            <label htmlFor="email_field">Name</label>
                            <input
                                type="name"
                                id="name_field"
                                className="form-control"
                                name='name'
                                value={name}
                                maxLength={40}
                                // onChange={(e) => setName(e.target.value)}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^[a-zA-Z\s]*$/.test(value)) {
                                        setName(value);
                                    }
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="gender_field">Gender </label>
                            <select
                                type='gender'
                                id="gender_field"
                                name="gender"
                                className="form-control"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                            >
                                <option value="" disabled>Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>

                        <div className='form-group'>
                            <label htmlFor='avatar_upload'>Avatar (*Size should be within 1mb)</label>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <figure className='avatar mr-3 item-rtl'>
                                        {avatarPreview && avatarPreview !== '/images/default_avatar.png' && (
                                            <button
                                                type="button"
                                                style={{
                                                    position: 'absolute',
                                                    backgroundColor: 'transparent',
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: 'red'
                                                }}
                                                onClick={removeAvatar}
                                            >
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        )}
                                        <img
                                            src={avatarPreview}
                                            className='rounded-circle'
                                            alt='Avatar Preview'
                                        />
                                    </figure>
                                </div>
                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        name='avatar'
                                        className='custom-file-input'
                                        id='customFile'
                                        onChange={onChangeAvatar}
                                        accept='.jpg, .jpeg, .png' // Accepts only jpg, jpeg, and png files
                                        multiple={false}           // Ensures only one file can be selected
                                    />
                                    <label className='custom-file-label' htmlFor='customFile'>
                                        {/* Choose Avatar */}
                                        {avatarName ? avatarName : 'Choose Avatar'}
                                    </label>

                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn update-btn btn-block mt-4 mb-3" disabled={profileupdateloading}>
                            {profileupdateloading ? <LoaderButton fullPage={false} size={20} /> : (
                                <span> Update </span>
                            )
                            }
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UpdateProfile