import React, { useEffect, useState } from 'react';
import { Stepper, Step, StepLabel, StepContent, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const JasInvoice = React.forwardRef(({ invoice, porterData }, ref) => {

    const pickup_lat = porterData?.porterData?.pickup_details?.address?.lat;
    const pickup_lon = porterData?.porterData?.pickup_details?.address?.lng;
    const drop_lat = Number(porterData?.porterData?.drop_details?.address?.lat);
    const drop_lon = Number(porterData?.porterData?.drop_details?.address?.lng);

    // Extract important data from the response
    const { order_id, createdAt, updatedItems, user, dispatchedTable } = invoice;
    const { order_timings, partner_info } = porterData?.porterResponse || {};
    const googleMapsApiKey = process.env.REACT_APP_GOOGLEMAP_API_KEY;
    const [staticMapUrl, setStaticMapUrl] = useState('');
    useEffect(() => {
        const fetchRoute = async () => {
            const directionsUrl = `/directions?pickup_lat=${pickup_lat}&pickup_lon=${pickup_lon}&drop_lat=${drop_lat}&drop_lon=${drop_lon}`;

            try {
                const response = await fetch(directionsUrl);
                const data = await response.json();
                if (data.status === "OK") {
                    const polyline = data.routes[0]?.overview_polyline?.points;
                    if (polyline) {
                        // const staticMap = https://maps.googleapis.com/maps/api/staticmap?size=600x400&path=enc:${encodeURIComponent(polyline)}&key=${googleMapsApiKey};
                        const staticMap = `https://maps.googleapis.com/maps/api/staticmap?size=600x400&markers=color:green|label:S|${pickup_lat},${pickup_lon}&markers=color:red|label:E|${drop_lat},${drop_lon}&path=enc:${encodeURIComponent(polyline)}&key=${googleMapsApiKey}`;
                        setStaticMapUrl(staticMap);
                    }
                } else {
                    console.error("Error fetching directions:", data.error_message);
                }
            } catch (error) {
                console.error('Error fetching directions:', error);
            }
        };

        fetchRoute();
    }, [pickup_lat, pickup_lon, drop_lat, drop_lon, googleMapsApiKey]);


    const formattedDate = new Date(invoice.createdAt).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    }).replace(/ /g, '/');

    const steps = [
        {
            label: "Pickup Location",
            description: (
                <>
                    <Typography variant="body2">
                        {order_timings?.order_started_time
                            ? new Date(order_timings.order_started_time * 1000).toLocaleString()
                            : "N/A"}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {"29, Reddy Street, Nerkundram, Chennai, Tamil Nadu, India"}
                    </Typography>
                </>
            ),
            color: "green",
            // icon: <CheckCircleIcon style={{ color: "green" }} />, // Green circle icon
        },
        {
            label: "Drop Location",
            description: (
                <>
                    <Typography variant="body2">
                        {order_timings?.order_ended_time
                            ? new Date(order_timings.order_ended_time * 1000).toLocaleString()
                            : "N/A"}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {/* {invoice?.drop_location || */}
                        {/* "BlueMount Apartments, Poona-mallee High Road, Chennai, Tamil Nadu"} */}
                        {invoice.orderDetail.shippingInfo.address},{invoice.orderDetail.shippingInfo.area},{invoice.orderDetail.shippingInfo.city}-{invoice.orderDetail.shippingInfo.postalCode}
                    </Typography>
                </>
            ),
            color: porterData?.porterResponse?.status === "completed" ? "green" : "red",
            // icon: <CheckCircleIcon style={{ color: "red" }} />, // Red circle icon
        },
    ];


    return (
        <div className="invoice-container" ref={ref}>
            <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}><u>INVOICE</u></h4>
            <div className="invoice-header">
                <div className="top-info">
                    <div>
                        <p>Invoice No: {invoice.invoiceNumber}</p>
                        <p>Date: {formattedDate}</p>
                    </div>
                </div>
                <div className="middle-info">
                    <div>
                        <h4 style={{ fontWeight: 'bolder' }}><u>Supplier</u></h4>
                        <p style={{ fontWeight: 'bolder' }}>M/s.JAS FRUITS AND VEGETABLES</p>
                        <p>29 Reddy Street, Nerkundram</p>
                        <p>Chennai-600107</p>
                    </div>
                    <div>
                        <div>
                            <h4 style={{ fontWeight: 'bolder' }}><u>Buyer</u></h4>
                            <p style={{ fontWeight: 'bolder', textTransform: 'uppercase' }}>{invoice.orderDetail.shippingInfo.gender === 'Male' ? 'Mr. ' : invoice.orderDetail.shippingInfo.gender === 'Female' ? 'Ms. ' : ''}{invoice.orderDetail.shippingInfo.name}</p>
                            <p style={{ textTransform: 'uppercase' }}>{invoice.orderDetail.shippingInfo.address},{invoice.orderDetail.shippingInfo.area}</p>
                            <p style={{ textTransform: 'uppercase' }}>{invoice.orderDetail.shippingInfo.city}-{invoice.orderDetail.shippingInfo.postalCode}</p>
                        </div>
                        <div>
                            <h5 style={{ fontWeight: 'bolder', marginTop: '20px' }}><u>Notify</u></h5>
                            <p style={{ fontWeight: 'bolder', textTransform: 'uppercase' }}>{invoice.orderDetail.shippingInfo.gender === 'Male' ? 'Mr. ' : invoice.orderDetail.shippingInfo.gender === 'Female' ? 'Ms. ' : ''}{invoice.orderDetail.shippingInfo.name}</p>
                            <p style={{ textTransform: 'uppercase' }}>{invoice.orderDetail.shippingInfo.address},{invoice.orderDetail.shippingInfo.area}</p>
                            <p style={{ textTransform: 'uppercase' }}>{invoice.orderDetail.shippingInfo.city}-{invoice.orderDetail.shippingInfo.postalCode}</p>
                        </div>
                        <div>
                            <h5 style={{ fontWeight: 'bolder', marginTop: '20px' }}><u>Payment</u></h5>
                            <p>Online Payment:₹ {invoice?.orderDetail?.paidOnline}</p>
                            <p>Wallet Payment:₹ {invoice?.orderDetail?.paidWallet}</p>
                        </div>
                    </div>

                </div>

                <div className="bottom-info">
                    <div>

                        <p>Commodity</p>
                        <p>OrderID</p>
                        <p>Place of Origin of Goods </p>
                        <p>Place of Final Destination </p>
                        <p>Payment in Advance</p>
                        <p> CONTACT NUMBER </p>
                    </div>
                    <div className="bottom-data">

                        <p>VEGETABLES SUPPLY</p>
                        <p>{invoice.order_id}</p>
                        <p>Chennai - Koyembedu</p>
                        <p>{invoice.orderDetail.shippingInfo.city}-{invoice.orderDetail.shippingInfo.area}</p>
                        <p>{invoice?.orderDetail?.statusResponse?.txn_id}</p>
                        <p>9176883506</p>
                    </div>
                </div>
            </div>

            <div className="invoice-body">
                <table className="invoice-table">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Description of Goods</th>
                            <th>Order KG</th>
                            <th>Excess/Minus</th>
                            <th>Packed Quantity</th>
                            <th>Rate per KG</th>
                            <th>Total value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoice && invoice.detailedTable ? (invoice.detailedTable.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.orderedWeight}</td>
                                <td>{parseFloat(item.dispatchedWeight - item.orderedWeight).toFixed(2)}</td>
                                <td>{item.dispatchedWeight}</td>
                                <td>₹ {item.pricePerKg}</td>
                                <td>₹ {parseFloat(item.dispatchedWeight * item.pricePerKg).toFixed(2)}</td>
                            </tr>
                        ))) : (
                            invoice.dispatchedTable.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.orderedWeight}</td>
                                    <td>{parseFloat(item.dispatchedWeight - item.orderedWeight).toFixed(2)}</td>
                                    <td>{item.dispatchedWeight}</td>
                                    <td>₹ {item.pricePerKg}</td>
                                    <td>₹ {parseFloat(item.dispatchedWeight * item.pricePerKg).toFixed(2)}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="6" style={{ textAlign: 'right' }}>Total</td>
                            <td>₹ {parseFloat(invoice.totalDispatchedAmount).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td colSpan="6" style={{ textAlign: 'right' }}>Shipping Charge</td>
                            {invoice.totalDispatchedAmount > 0 ?
                                <td>₹ {parseFloat(invoice.orderDetail.shippingPrice).toFixed(2)}</td>
                                :
                                <td>0.00</td>
                            }
                        </tr>
                        <tr>
                            <td colSpan="6" style={{ textAlign: 'right' }}>Grand Total</td>
                            <td>
                                {invoice.totalDispatchedAmount > 0
                                    ? `₹ ${(parseFloat(invoice.totalDispatchedAmount || 0) + parseFloat(invoice.orderDetail?.shippingPrice || 0)).toFixed(2)}`
                                    : `₹ ${parseFloat(invoice.totalDispatchedAmount || 0).toFixed(2)}`}
                            </td>


                        </tr>
                    </tfoot>
                </table>
            </div>


            <div className="invoice-footer">
                <div className="info">
                    <p>The price actually charged by us and there is no additional consideration flowing directly or indirectly from such sales over and what has been declared</p>

                </div>

                {/* <div className="signature">
                    <p>Authorised Signatory</p>
                </div> */}
            </div>
            <hr />
            <div style={{ fontFamily: "'Arial', sans-serif", padding: "20px", display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <h4 style={{ fontWeight: 'bolder' }}><u>Delivery Info</u></h4>
                </div>
                {/* {
                    partner_info ? ( */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                        <h2 style={{ color: "blue", fontSize: "20px", fontWeight: 'bolder', padding: '10px', borderRadius: '20px', backgroundColor: '#f1f6f9' }}>
                            {partner_info?.vehicle_type || "N/A"} | {partner_info?.vehicle_number || "N/A"}
                        </h2>
                    </div>

                    {/* Partner Info */}
                    <div style={{ marginBottom: "20px", textAlign: "center", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', fontSize: '20px' }}>
                            <i className="fa fa-user large" style={{ marginRight: '5px' }}></i>
                            {/* <h4 style={{ margin: 0, color: "#4A4A4A", fontWeight: "bold" }}> */}
                            {partner_info?.name || "N/A"}
                            {/* </h4> */}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', fontSize: '20px' }}>
                            {/* <h4 style={{ margin: 0, color: "#4A4A4A", fontWeight: "bold" }}> */}
                            <i className="fa fa-phone" style={{ marginRight: '5px' }}></i> {partner_info?.mobile?.mobile_number || "N/A"}
                            {/* </h4> */}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', fontSize: '20px' }}>
                            {/* <h4 style={{ margin: 0, color: "#4A4A4A", fontWeight: "bold" }}> */}
                            <i className="fa fa-phone" style={{ marginRight: '5px' }}></i> {partner_info?.partner_secondary_mobile?.mobile_number || "N/A"}
                            {/* </h4> */}
                        </div>

                    </div>
                </div>
                {/* ) : (
                        <></>
                    )
                } */}

                {/* Header with Porter Logo */}

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pageBreakInside:'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {/* Pickup Location */}
                        <div style={{ padding: "0px", fontFamily: "'Arial', sans-serif" }}>
                            {/* <Typography variant="h5" style={{ marginBottom: "20px", color: "#2B2E6D" }}>
                                Delivery Route
                            </Typography> */}
                            <Stepper orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={index} active>
                                        <StepLabel
                                            style={{
                                                fontWeight: "bold",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                            StepIconComponent={() => (
                                                <div
                                                    style={{
                                                        width: "24px", // Circle size
                                                        height: "24px",
                                                        borderRadius: "50%",
                                                        backgroundColor: step.color, // Circle color based on step
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        marginRight: "8px",
                                                    }}
                                                >
                                                    {/* Inner dot */}
                                                    <div
                                                        style={{
                                                            width: "10px", // Dot size
                                                            height: "10px",
                                                            borderRadius: "50%",
                                                            backgroundColor: "white", // Dot color
                                                        }}
                                                    ></div>
                                                </div>
                                            )}
                                        >
                                            {step.label}
                                        </StepLabel>
                                        <StepContent>
                                            <div style={{ marginBottom: "10px" }}>{step.description}</div>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                    </div>


                    <div style={{ display: 'flex' }}>
                        {/* Map */}
                        <div
                            className="map-container"
                            style={{
                                textAlign: "center",
                                borderRadius: "10px",
                                overflow: "hidden",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <img
                                src={staticMapUrl}
                                alt="Static Map showing route from pickup to drop"
                                style={{ width: "100%", maxWidth: "300px", maxHeight: '200px', borderRadius: "10px" }}
                            />
                        </div>
                    </div>

                </div>
            </div>

            {/* New Section for Porter Data */}
            {/* <div className="porter-data">
                <h4 style={{ marginTop: '20px' }}>Porter Data</h4>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: '20px' }}>
                    
                    <div style={{ flex: 1, lineHeight: '5px' }}>
                        <h5 className="mb-3">Order Timings:</h5>
                        {order_timings ? (
                            <ul>
                                <li>
                                    {order_timings.order_accepted_time ?
                                        (<p>Order Accepted Time:  {new Date(order_timings.order_accepted_time * 1000).toLocaleString()}</p>) : (<p>Order Accepted Time:  N/A</p>)
                                    }
                                </li>
                                <li>
                                    {order_timings.order_started_time ?
                                        (<p>Order Started Time: {new Date(order_timings.order_started_time * 1000).toLocaleString()}</p>) : (<p>Order Started Time:  N/A</p>)
                                    }
                                </li>
                                <li>
                                    {order_timings.order_ended_time ?
                                        (<p>Order Ended Time: {new Date(order_timings.order_ended_time * 1000).toLocaleString()}</p>) : (<p>Order Ended Time:  N/A</p>)
                                    }
                                </li>
                                <li>
                                    {order_timings.pickup_time ?
                                        (<p>Pickup Time:  {new Date(order_timings.pickup_time * 1000).toLocaleString()}</p>) : (<p>Pickup Time:  N/A</p>)
                                    }
                                </li>
                            </ul>
                        ) : (
                            <p>Order timings not available</p>
                        )}
                    </div>

                  
                    <div style={{ flex: 1, lineHeight: '5px' }}>
                        <h5 className="mb-3">Partner Info:</h5>
                        {partner_info ? (
                            <ul>
                                <li>Name: {partner_info.name || "Not available"}</li>
                                <li>Mobile: {partner_info.mobile.country_code} {partner_info.mobile.mobile_number || "Not available"}</li>
                                {partner_info.partner_secondary_mobile && (
                                    <>
                                        <li>Secondary Mobile: {partner_info.partner_secondary_mobile.country_code} {partner_info.partner_secondary_mobile.mobile_number || "Not available"}</li>
                                    </>
                                )
                                }
                                <li>Vehicle Number: {partner_info.vehicle_number || "Not available"}</li>
                                <li>Vehicle Type: {partner_info.vehicle_type || "Not available"}</li>
                            </ul>
                        ) : (
                            <p>Partner info not available</p>
                        )}
                    </div>
                    <div className="map-container" style={{ marginTop: '20px', textAlign: 'center' }}>
                        <h5>Route Map</h5>
                        <img
                            src={staticMapUrl}
                            alt="Static Map showing route from pickup to drop"
                            style={{ width: '100%', maxWidth: '600px', borderRadius: '10px' }}
                        />
                    </div>
                </div>
            </div> */}
        </div>
    );
});

export default JasInvoice;