// import React, { useEffect, Fragment, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Link, useLocation, useNavigate} from "react-router-dom";
// import { deleteOrder, adminOrders as adminOrdersAction } from "../../actions/orderActions"
// import { allPackedOrder, getporterOrder, updatedPackedOrder } from "../../actions/porterActions"
// import Loader from '../Layouts/Loader';
// import { MDBDataTable } from 'mdbreact';
// import { Slide, toast } from 'react-toastify';
// import Sidebar from "../admin/Sidebar";
// import { clearError } from '../../slices/productsSlice';
// import { clearOrderDeleted, orderDetailClear } from "../../slices/orderSlice";
// import MetaData from '../Layouts/MetaData';
// import { porterClearData, porterClearResponse } from '../../slices/porterSlice';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';


// const RefundList = ({ isActive, setIsActive }) => {
//     const location = useLocation();
//     const { loading, allpackedOrderData: orders, allpackedOrderError, updatepackedOrderData: orderslist } = useSelector(state => state.porterState);
//     const navigate = useNavigate();
//     const dispatch = useDispatch();


//     // Initialize the date with the current date
//     const currentDate = new Date();
//     const formattedCurrentDate = currentDate.toISOString().split('T')[0];

//     const [date, setDate] = useState(formattedCurrentDate);
//     const [iserror, setIserror] = useState(false);
//     const [refresh, setRefresh] = useState(false);
//     useEffect(() => {
//         dispatch(orderDetailClear());
//         dispatch(porterClearData());
//         dispatch(porterClearResponse());
//     }, [])


//     const setOrders = () => {
//         const data = {
//             columns: [
//                 {
//                     label: 'S.No',
//                     field: 's_no',
//                     sort: 'disabled'
//                 },
//                 {
//                     label: 'Order ID',
//                     field: 'id',
//                     sort: 'disabled'
//                 },
//                 {
//                     label: 'Name',
//                     field: 'name',
//                     sort: 'disabled'
//                 },
//                 {
//                     label: 'Phone.No',
//                     field: 'phone_no',
//                     sort: 'disabled'
//                 },
//                 // {
//                 //     label: 'Email',
//                 //     field: 'email',
//                 //     sort: 'disabled'
//                 // },

//                 {
//                     label: 'RefundStatus',
//                     field: 'refundstatus',
//                     sort: 'disabled'
//                 },

//                 {
//                     label: 'Actions',
//                     field: 'actions',
//                     sort: 'disabled'
//                 }
//             ],
//             rows: []
//         };

//         // Filter and validate orders by selected date
//         const filteredRefundOrders = orderslist && orderslist.filter(order => {
//             if (!order.orderDate) return false; // Skip orders with no orderDate

//             // Attempt to parse the orderDate
//             const orderDate = new Date(order.orderDate);
//             if (isNaN(orderDate.getTime())) return false; // Skip invalid dates

//             // Compare the date part only (ignoring time)
//             return orderDate.toISOString().split('T')[0] === date && order && order.orderDetail && order.orderDetail.statusResponse.status === 'CHARGED';
//         });

//         // Sort orders by creation date (newest first)
//         const sortedOrders = filteredRefundOrders && filteredRefundOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

//         sortedOrders && sortedOrders.filter(order => order.totalRefundableAmount > 0).forEach((order, index) => {
//             data.rows.push({
//                 s_no: index + 1,
//                 id: order.order_id,
//                 name: order.orderDetail && order.orderDetail.shippingInfo && order.orderDetail.shippingInfo.name,
//                 phone_no: order.orderDetail && order.orderDetail.shippingInfo && order.orderDetail.shippingInfo.phoneNo,
//                 // email: order.user.email,
//                 refundstatus: (
//                     // <div className={order.refundStatus && order.refundStatus.includes('SUCCESS') ? 'greenColor' : 'redColor'} >{order.refundStatus}</div>         
//                     <div style={{ color: order && order.statusResponse && order.statusResponse.refunds && order.statusResponse.refunds[0].status === 'SUCCESS' ? 'green' : 'red', marginLeft: '10px' }}>
//                         <b>{order && order.statusResponse && order.statusResponse.refunds ? order.statusResponse.refunds[0].status : 'Processing'}</b>
//                     </div>          
//                 ),
//                 actions: (
//                     <Fragment>
//                         <Link to={`/admin/refund/${order.order_id}`} className="btn btn-success py-1 px-2 ml-2">
//                             {/* <i className="fa fa-pencil"></i> */}
//                             Initiate Refund
//                         </Link>
//                     </Fragment>
//                 )
//             });
//         });

//         return data;
//     };


//     useEffect(() => {
//         if (allpackedOrderError) {
//             toast.dismiss();
//             setTimeout(() => {
//                 toast.error(allpackedOrderError, {
//                     position: 'bottom-center',
//                     type: 'error',
//                     autoClose: 700,
//                     transition: Slide,
//                     hideProgressBar: true,
//                     className: 'small-toast',
//                     onOpen: () => { dispatch(clearError()) }
//                 });
//             }, 300);
//             setIserror(true)
//             return;
//         }


//     }, [dispatch, allpackedOrderError, refresh]);

//     useEffect(() => {
//         if (!orderslist && !iserror) {
//             dispatch(updatedPackedOrder({}));
//         }
//     }, [orderslist, iserror])

//     return (
//         <div>
//             <MetaData
//                 title="Refund List"
//                 description="Review and process customer refund requests. Ensure prompt handling of refunds to maintain customer trust and satisfaction."
//             />


//             <div className="row loader-parent" >
//                 <div className="col-12 col-md-2">
//                     <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
//                         <Sidebar isActive={isActive} setIsActive={setIsActive} />
//                     </div>
//                 </div>
//                 <div className="col-12 col-md-10 smalldevice-space container loader-parent">
//                     <h1 className="mb-4">Refund List</h1>
//                     <div className="">
//                             <span className="back-button" onClick={() => navigate(-1)}>
//                                 <ArrowBackIcon fontSize="small" />
//                                 <span>Back</span>
//                             </span>

//                         </div>


//                     <input
//                         type="date"
//                         value={date}
//                         onChange={(e) => setDate(e.target.value)}
//                         className="form-control mb-3 date-input"
//                     />

//                     <Fragment>
//                         {loading ? (
//                             <div className="container loader-loading-center">
//                                 <Loader />
//                             </div>

//                         ) :
//                             (
//                                 <div className='mdb-table' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                     <MDBDataTable
//                                         data={setOrders()}
//                                         bordered
//                                         hover
//                                         className="px-3 product-table"
//                                         noBottomColumns
//                                     />
//                                 </div>
//                             )

//                         }
//                     </Fragment>
//                 </div>
//             </div>
//         </div>

//     );
// };

// export default RefundList;


import React, { useEffect, Fragment, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { updatedPackedOrder } from "../../actions/porterActions";
import Loader from '../Layouts/Loader';
import { MDBDataTable } from 'mdbreact';
import { Slide, toast } from 'react-toastify';
import Sidebar from "../admin/Sidebar";
import { clearError } from '../../slices/productsSlice';
import MetaData from '../Layouts/MetaData';
import { porterClearData, porterClearResponse } from '../../slices/porterSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { orderDetailClear } from '../../slices/orderSlice';
import JasInvoice from '../Layouts/JasInvoice';
import ReactDOM from 'react-dom';
import { useReactToPrint } from 'react-to-print';

const RefundList = ({ isActive, setIsActive }) => {
    const location = useLocation();
    const { loading, allpackedOrderData: orders, allpackedOrderError, updatepackedOrderData: orderslist } = useSelector(state => state.porterState);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // Initialize the date with the current date
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];
    const [inputStartDate, setInputStartDate] = useState('');
    const [inputEndDate, setInputEndDate] = useState('');
    const [startDate, setStartDate] = useState(formattedCurrentDate);
    const [endDate, setEndDate] = useState(formattedCurrentDate);
    const [iserror, setIserror] = useState(false);
    const [refresh, setRefresh] = useState(false);
    // const [selectedRefundOrder, setSelectedRefundOrder] = useState(null);

   

    useEffect(() => {
        dispatch(orderDetailClear());
        dispatch(porterClearData());
        dispatch(porterClearResponse());
    }, []);

    const invoiceRef = useRef(null);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [isPrinting, setIsPrinting] = useState(false);
    const [porterData, setPorterData] = useState(null);

    // const handlePrint = useReactToPrint({
    //     content: () => invoiceRef.current,
    //     documentTitle: `Invoice_${selectedInvoice?.order_id || "unknown"}`,
    //     onBeforePrint: () => setIsPrinting(true),
    //     onAfterPrint: () => {
    //         setIsPrinting(false);
    //         setSelectedInvoice(null); // Reset state after printing
    //     },
    // });

    const getInvoiceHtmlWithStyles = () => {
        const invoiceHtml = invoiceRef.current.outerHTML;

        // Get the contents of the app.css file as a string
        const appCss = `
          <style>
            /* You can manually copy the CSS from your app.css or use inline styles */
            .invoice-container {
  width: 90%;
  margin: auto;
  font-family: 'Times New Roman', Times, serif;
  padding: 20px;
  font-size: 15px;
  font-weight: bold
}

.invoice-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}


.invoice-header .top-info {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.invoice-header .top-info div {
  margin-left: 20px;
}

.invoice-header .middle-info {
  display: flex;
  justify-content: space-between;
}

.invoice-header .middle-info div {
  flex: 1;
  margin: 0 10px;
}

.invoice-header .middle-info h4 {

  margin-bottom: 10px;
}

.invoice-header .bottom-info {
  display: flex;
  justify-content: space-between;
}

.invoice-header .bottom-info div {
  flex: 1;
  margin: 10px 10px;

}

.bottom-data {
  border: 1px solid #000;
  width: fit-content;
  font-family: Arial, sans-serif;
}

.bottom-data p {
  border-bottom: 1px solid black;
  text-align: center;
  font-weight: bolder;
}

.bottom-data p:last-child {
  border-bottom: none;
}

.invoice-header h4,
.invoice-header h5,
.invoice-header p {
  margin: 0;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  page-break-inside: auto;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #000;
  text-align: center;
}

.invoice-table td {
  font-weight: 800px;
}

.invoice-table tbody tr {
  page-break-inside: avoid;
  /* Prevent row splitting */
}

/* Footer should always appear at the end of the table */
.invoice-table tfoot {
  display: table-footer-group;
  /* Ensure footer is part of the table's final rendering */
}


.signature {
  text-align: right;
  margin-top: 50px;
}

.hidden {
  display: none;
}

.form-select:focus {
  border: 1px solid #ced4da;
  box-shadow: none;
}


            /* Include other styles from app.css here */
          </style>
        `;

        return appCss + invoiceHtml; // Combine CSS and HTML
    };

    const handlePrint = useReactToPrint({
        content: () => invoiceRef.current,
        documentTitle: `Invoice_${selectedInvoice?.order_id || "unknown"}`,
        onBeforePrint: () => setIsPrinting(true),
            onAfterPrint: () => {
                setIsPrinting(false);
                setSelectedInvoice(null); // Reset state after printing
                setPorterData(null);
            }
    });

    const triggerAndroidPrint = () => {
        if (window.Android) {
            // Send the HTML content of the invoice to Android
            //   const invoiceHtml = invoiceRef.current.outerHTML;
            const invoiceHtmlWithStyles = getInvoiceHtmlWithStyles();
            //   if (invoiceHtml.trim() === "") {
            //     console.log("Invoice content is empty!");
            //     return;
            //   }
            window.Android.printWebView(invoiceHtmlWithStyles);
        } else {
            handlePrint(); // Regular browser print if not running in WebView
        }
    };

    const handleDownloadInvoice = (order) => {
        if (isPrinting) return; // Prevent multiple prints
        setSelectedInvoice(order); // Set the current order for printing
        setPorterData(order?.porterData)
        
    };

    useEffect(() => {
        if (selectedInvoice && !isPrinting) {
            triggerAndroidPrint();
        }
    }, [selectedInvoice, isPrinting]);

    const handleStartDateChange = (e) => {
        const selectedDate = e.target.value;
        const year = new Date(selectedDate).getFullYear();
    
        if (year >= 1000 && year <= 9999) {
            setStartDate(selectedDate);
            setInputStartDate(selectedDate);
        } else {
            // alert("Please enter a valid 4-digit year.");
            return
        }
    };
    
    const handleEndDateChange = (e) => {
        const selectedDate = e.target.value;
        const year = new Date(selectedDate).getFullYear();
    
        if (year >= 1000 && year <= 9999) {
            setEndDate(selectedDate);
            setInputEndDate(selectedDate);
        } else {
            // alert("Please enter a valid 4-digit year.");
            return
        }
    };

    const setOrders = () => {
        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 's_no',
                    sort: 'disabled'
                },
                {
                    label: 'Order ID',
                    field: 'id',
                    sort: 'disabled'
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'disabled'
                },
                {
                    label: 'Phone.No',
                    field: 'phone_no',
                    sort: 'disabled'
                },
                {
                    label: 'OrderedAmount',
                    field: 'orderedamount',
                    sort: 'disabled'
                },
                {
                    label: 'RefundAmount',
                    field: 'refundamount',
                    sort: 'disabled'
                },
                {
                    label: 'RefundStatus',
                    field: 'refundstatus',
                    sort: 'disabled'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    sort: 'disabled'
                },
                {
                    label: 'Invoice',
                    field: 'invoice',
                    sort: 'disabled'
                }
            ],
            rows: []
        };

        // Filter orders by start and end date
        const filteredRefundOrders = orderslist && orderslist.filter(order => {
            if (!order.orderDate) return false; // Skip orders with no orderDate

            // Attempt to parse the orderDate
            const orderDate = new Date(order.orderDate);
            if (isNaN(orderDate.getTime())) return false; // Skip invalid dates

            const orderDateString = orderDate.toISOString().split('T')[0];
            // return orderDateString >= startDate && orderDateString <= endDate && order && order.orderDetail && order?.orderDetail?.statusResponse?.status === 'CHARGED';
            return orderDateString >= startDate && orderDateString <= endDate;
        });

        // Sort orders by creation date (newest first)
        const sortedOrders = filteredRefundOrders && filteredRefundOrders.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        sortedOrders && sortedOrders.filter(order => order.totalRefundableAmount > 0).forEach((order, index) => {
            data.rows.push({
                s_no: index + 1,
                id: order.order_id,
                name: order.orderDetail && order.orderDetail.shippingInfo && order.orderDetail.shippingInfo.name,
                phone_no: order.orderDetail && order.orderDetail.shippingInfo && order.orderDetail.shippingInfo.phoneNo,
                orderedamount:`₹ ${order.orderDetail && order.orderDetail.totalPrice && order.orderDetail.totalPrice}`,
                refundamount: (() => {
                    const totalDispatchedAmount = Number(order && order.totalDispatchedAmount);
                    const totalRefundableAmount = Number(order.totalRefundableAmount);
                    const shippingCharge = Number(order.orderDetail && order.orderDetail.shippingPrice);

                    let RefundableAmount;

                    // Check if totalDispatchedAmount is greater than 0
                    if (totalDispatchedAmount > 0) {
                        RefundableAmount = totalRefundableAmount; // Use totalRefundableAmount if dispatched amount is greater than 0
                    } else {
                        RefundableAmount = totalRefundableAmount + shippingCharge; // Add shipping charge to totalRefundableAmount if no dispatched amount
                    }

                    return `₹ ${RefundableAmount}`;
                })(),
                refundstatus: (
                    <div style={{ color: order && order.statusResponse && order.statusResponse.refunds && order.statusResponse.refunds[0].status === 'SUCCESS' ? 'green' : order && order?.refundStatus === 'SUCCESS' ? 'green' : 'red', marginLeft: '10px' }}>
                        {/* <b>{order && order.statusResponse && order.statusResponse.refunds ? order.statusResponse.refunds[0].status : 'Processing'}</b> */}
                        <b>{order && order?.statusResponse?.refunds ? (
                            order && order?.statusResponse && order?.statusResponse?.refunds ? order?.statusResponse?.refunds[0].status : 'Processing'
                        ) : (
                            order && order?.refundStatus ? order?.refundStatus : 'Processing'
                        )

                        }
                        </b>
                    </div>
                ),
                actions: (
                    <Fragment>
                        <Link to={`/admin/refund/${order.order_id}`} className="btn btn-success py-1 px-2 ml-2">
                            Initiate Refund
                        </Link>
                    </Fragment>
                ),
                invoice: (
                    <button
                        onClick={() => handleDownloadInvoice(order)}
                        className="btn btn-primary"
                        disabled={isPrinting}
                    >
                        Download
                    </button>
                )
            });
        });

        return data;
    };

    useEffect(() => {
        if (allpackedOrderError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(allpackedOrderError, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearError()) }
                });
            }, 300);
            setIserror(true)
            return;
        }
    }, [dispatch, allpackedOrderError, refresh]);

    useEffect(() => {
        if (!orderslist && !iserror) {
            dispatch(updatedPackedOrder({ startDate, endDate }));
        }

    }, [orderslist, startDate, endDate, iserror]);

    useEffect(() => {
        if (startDate || endDate) {
            dispatch(updatedPackedOrder({ startDate, endDate }));
        }

    }, [startDate, endDate])

    return (
        <div>
            <MetaData
                title="Refund List"
                description="Review and process customer refund requests. Ensure prompt handling of refunds to maintain customer trust and satisfaction."
            />

            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div>
                <div className="col-12 col-md-10 smalldevice-space container loader-parent">
                    <h1 className="mb-4">Refund List</h1>
                    <div className="">
                        <span className="back-button" onClick={() => navigate(-1)}>
                            <ArrowBackIcon fontSize="small" />
                            <span>Back</span>
                        </span>
                    </div>

                    {/* <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="form-control mb-3 date-input"
                    />
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="form-control mb-3 date-input"
                    /> */}

                    <div className="paymentlist-date-filter">
                        <div className='start-date'>
                            <label htmlFor="startDate">Start Date:</label>
                            <input
                                type="date"
                                value={inputStartDate === '' ? startDate : inputStartDate}
                                // onChange={(e) => { setStartDate(e.target.value); setInputStartDate(e.target.value) }}
                                onChange={handleStartDateChange}
                                className="paymentlist-form-control"
                            />
                        </div>
                        <div className="end-date">
                            <label htmlFor="endDate">End Date:</label>
                            <input
                                type="date"
                                value={inputEndDate === '' ? endDate : inputEndDate}
                                // onChange={(e) => { setEndDate(e.target.value); setInputEndDate(e.target.value) }}
                                onChange={handleEndDateChange}
                                className="paymentlist-form-control"
                            />
                        </div>
                    </div>


                    <Fragment>
                        {loading ? (
                            <div className="container loader-loading-center">
                                <Loader />
                            </div>
                        ) : (
                            <div className='mdb-table' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <MDBDataTable
                                    data={setOrders()}
                                    bordered
                                    hover
                                    className="px-3 product-table"
                                    noBottomColumns
                                />
                            </div>
                        )}
                    </Fragment>
                    {selectedInvoice && (
                        <div style={{ position: "absolute", top: "-9999px", left: "-9999px" }}>
                            <JasInvoice ref={invoiceRef} invoice={selectedInvoice} porterData={porterData} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RefundList;
