import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { deleteOrder, adminOrders as adminOrdersAction, updateadminOrders, DispatchLists } from "../../actions/orderActions"
import Loader from '../Layouts/Loader';
import { MDBDataTable } from 'mdbreact';
import { Slide, toast } from 'react-toastify';
import Sidebar from "../admin/Sidebar";
import { clearError } from '../../slices/productsSlice';
import { clearOrderDeleted, adminOrderClear, orderDetailClear, dispatchListClear } from "../../slices/orderSlice";
import MetaData from '../Layouts/MetaData';
import { porterClearData, porterClearResponse } from '../../slices/porterSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const DispatchList = ({ isActive, setIsActive }) => {
    const location = useLocation();
    const { dispatchList: orders, loading, error, isOrderDeleted, updateadminOrders: orderlist = [],  dispatchListloading,dispatchListError} = useSelector(state => state.orderState);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const previousDate = new Date();
    previousDate.setDate(previousDate.getDate());
    const formattedPreviousDate = previousDate.toISOString().split('T')[0];

    const [date, setDate] = useState(formattedPreviousDate);
    const [refresh, setRefresh] = useState(false);
    const [iserror, setIserror] = useState(false);

    
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];
    const [inputStartDate, setInputStartDate] = useState('');
    const [inputEndDate, setInputEndDate] = useState('');
    const [startDate, setStartDate] = useState(formattedCurrentDate);
    const [endDate, setEndDate] = useState(formattedCurrentDate);

    useEffect(() => {
        dispatch(orderDetailClear());
        dispatch(porterClearData());
        dispatch(porterClearResponse());
    }, [])

    useEffect(() => {
        if (startDate || endDate) {
            // dispatch(adminOrdersAction()); 
            dispatch(DispatchLists({startDate,endDate}));
        }
    }, [startDate,endDate]);

    const handleStartDateChange = (e) => {
        const selectedDate = e.target.value;
        const year = new Date(selectedDate).getFullYear();
    
        if (year >= 1000 && year <= 9999) {
            setStartDate(selectedDate);
            setInputStartDate(selectedDate);
        } else {
            // alert("Please enter a valid 4-digit year.");
            return
        }
    };
    
    const handleEndDateChange = (e) => {
        const selectedDate = e.target.value;
        const year = new Date(selectedDate).getFullYear();
    
        if (year >= 1000 && year <= 9999) {
            setEndDate(selectedDate);
            setInputEndDate(selectedDate);
        } else {
            // alert("Please enter a valid 4-digit year.");
            return
        }
    };


    const setOrders = () => {
        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 's_no',
                    sort: 'disabled'
                },
                {
                    label: 'Order ID',
                    field: 'id',
                    sort: 'disabled'
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'disabled'
                },
                {
                    label: 'Phone.No',
                    field: 'phone_no',
                    sort: 'disabled'
                },
                // {
                //     label: 'Email',
                //     field: 'email',
                //     sort: 'disabled'
                // },

                {
                    label: 'Amount',
                    field: 'amount',
                    sort: 'disabled'
                },
                {
                    label: 'OrderStatus',
                    field: 'orderstatus',
                    sort: 'disabled'
                },
                {
                    label: 'PaymentStatus',
                    field: 'paymentstatus',
                    sort: 'disabled'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    sort: 'disabled'
                }
            ],
            rows: []
        };

        // Filter and validate orders by selected date
        const filteredOrders = orders && orders.filter(order => {
            if (!order.orderDate) return false; // Skip orders with no orderDate

            // // Attempt to parse the orderDate
            // const orderDate = new Date(order.orderDate);
            // if (isNaN(orderDate.getTime())) return false; // Skip invalid dates

            // Compare the date part only (ignoring time)
            // return orderDate.toISOString().split('T')[0] === date && order.paymentStatus === 'CHARGED';
            return order.paymentStatus === 'CHARGED';
        });

        // Sort orders by creation date (newest first)
        const sortedOrders = filteredOrders && filteredOrders.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));


        sortedOrders && sortedOrders.filter(order => order.paymentStatus === 'CHARGED' && order.orderStatus !== 'Processing').forEach((order, index) => {
            data.rows.push({
                s_no: index + 1,
                id: order.order_id,
                name: order.shippingInfo.name,
                phone_no: order.shippingInfo.phoneNo,
                // email: order.user.email,
                amount: `₹ ${order.totalPrice}`,
                // orderstatus: (
                //     <div className={order.orderStatus && order.orderStatus.includes('Delivered') ? 'greenColor' : 'redColor'} >{order.orderStatus}</div>
                // ),
                orderstatus: order.orderStatus && order.orderStatus.includes('Delivered') ?
                (<p style={{ color: 'green', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px', padding: '0px' }}> {order.orderStatus} </p>) :
                (<p style={{ color: 'red', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px', padding: '0px' }}> {order && order.paymentStatus && order.paymentStatus === 'CHARGED' ? order.orderStatus : 'Cancelled'} </p>),
                paymentstatus: (
                    <div style={{ color: order && order.paymentStatus === 'CHARGED' ? 'green' : 'red' }}>
                        {order && order.paymentStatus === 'CHARGED' ? 'Success' : order ? order.paymentStatus : 'Pending'}
                    </div>
                ),
                actions: (
                    <Fragment>
                        <Link to={`/admin/dispatch/${order.order_id}`} className="btn btn-success py-1 px-2 ml-2">
                            {/* <i className="fa fa-pencil"></i> */}
                            Going to Dispatch
                        </Link>

                    </Fragment>
                )
            });
        });

        return data;
    };

    useEffect(() => {
        if (error) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(error, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearError()) }
                });
            }, 300);
            setIserror(true)
            return;
        }
        if(dispatchListError){
            toast.dismiss();
            setTimeout(() => {
                toast.error(dispatchListError, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(dispatchListClear()) }
                });
            }, 300);
            setIserror(true)
            return;
        }

    }, [dispatch, error, refresh,dispatchListError]);

    // useEffect(() => {
    //     if (!orders && !iserror) {
    //         dispatch(adminOrdersAction());
    //     }
    // }, [orders, iserror])
    return (
        <div>
            <MetaData
                title="Dispatch List"
                description="View and manage a list of orders ready for dispatch, ensuring timely and accurate delivery to customers."
            />
            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div>
                <div className="col-12 col-md-10 smalldevice-space loader-parent">
                    <h1 className="mb-4 admin-dashboard-x">Dispatch List</h1>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <ArrowBackIcon fontSize="small" />
                        <span>Back</span>
                    </div>
                    {/* <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="form-control mb-3 date-input"
                    /> */}

                    <div className="paymentlist-date-filter">
                        <div className='start-date'>
                            <label htmlFor="startDate">From:</label>
                            <input
                                type="date"
                                value={inputStartDate === '' ? startDate : inputStartDate}
                                // onChange={(e) => { setStartDate(e.target.value); setInputStartDate(e.target.value) }}
                                onChange={handleStartDateChange}
                                className="paymentlist-form-control"
                            />
                        </div>
                        <div className="end-date">
                            <label htmlFor="endDate">To:</label>
                            <input
                                type="date"
                                value={inputEndDate === '' ? endDate : inputEndDate}
                                // onChange={(e) => { setEndDate(e.target.value); setInputEndDate(e.target.value) }}
                                onChange={handleEndDateChange}
                                className="paymentlist-form-control"
                            />
                        </div>
                    </div>

                    <Fragment>
                        {dispatchListloading ? (<div className="container loader-loading-center">
                            <Loader />
                        </div>)
                            :
                            orders && (
                                <div className='mdb-table' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <MDBDataTable
                                        data={setOrders()}
                                        bordered
                                        hover
                                        className="px-3 product-table"
                                        noBottomColumns
                                    />
                                </div>
                            )

                        }
                    </Fragment>
                </div>
            </div>
        </div>

    );
};

export default DispatchList;

