import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getWalletTransactions } from "../../actions/transactionActions";
import { clearWalletTransactions } from "../../slices/transactionSlice";
import { Slide, toast } from "react-toastify";
import CryptoJS from 'crypto-js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const WalletPage = () => {
    const [walletBalance, setWalletBalance] = useState(0);
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [transactions, setTransactions] = useState([]);
    // const currentDate = new Date();
    // const formattedCurrentDate = currentDate.toISOString().split('T')[0];
    // const [inputStartDate, setInputStartDate] = useState('');
    // const [inputEndDate, setInputEndDate] = useState('');
    // const [startDate, setStartDate] = useState(formattedCurrentDate);
    // const [endDate, setEndDate] = useState(formattedCurrentDate);
    const currentDate = new Date(); // Get the current date
    const formattedCurrentDate = currentDate.toISOString().split('T')[0]; // Format current date

    // Calculate one month ago
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 7); // Subtract one month
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split('T')[0]; // Format one month ago date

    // State variables
    const [inputStartDate, setInputStartDate] = useState('');
    const [inputEndDate, setInputEndDate] = useState('');
    const [startDate, setStartDate] = useState(formattedOneWeekAgo); // Initialize to one month ago
    const [endDate, setEndDate] = useState(formattedCurrentDate); // Initialize to today
    const [searchOrderId, setSearchOrderId] = useState('');
    const { isAuthenticated, user } = useSelector(state => state.authState);
    const { walletTransactions: transactions, walletLoading, walletError } = useSelector(state => state.transactionState);
    const [filters, setFilters] = useState({
        transactionStatus: "all",
        transactionType: "all",
        orderType: "all",
    });
    useEffect(() => {
        if (walletError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(walletError, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearWalletTransactions()) }
                });
                // setweightvalue(false);
            }, 300);


        }

    }, [walletError])

    const decryptData = (walletBalance, encryptionKey) => {
        const bytes = CryptoJS.AES.decrypt(walletBalance, encryptionKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    // Simulated API data (replace with real API calls)
    useEffect(() => {
        const walletbalance = decryptData(user?.walletBalance, encryptionKey);
        // setWalletBalance(walletbalance);
        setWalletBalance(parseFloat(walletbalance));

    }, [user]);

    // Filtered Transactions
    // const filteredTransactions = transactions.filter((transaction) => {
    //     const { transactionStatus, transactionType, orderType } = filters;
    //     return (
    //         (transactionStatus === "all" || transaction.transactionStatus === transactionStatus) &&
    //         (transactionType === "all" || transaction.type.toLowerCase() === transactionType) &&
    //         (orderType === "all" || transaction.orderType === orderType)
    //     );
    // }).sort((a, b) => new Date(b.date) - new Date(a.date));

    const filteredTransactions = transactions?.filter((transaction) => {
        const { transactionStatus, transactionType, orderType } = filters;
        const matchesFilters =
            (transactionStatus === "all" || transaction.transactionStatus === transactionStatus) &&
            (transactionType === "all" || transaction.type.toLowerCase() === transactionType) &&
            (orderType === "all" || transaction.orderType === orderType);
        const matchesOrderId = searchOrderId === '' || transaction.order_id.includes(searchOrderId); // Filter by order ID
        return matchesFilters && matchesOrderId;
    }).sort((a, b) => new Date(b.date) - new Date(a.date));



    useEffect(() => {
        if (!transactions && !walletError) {
            dispatch(getWalletTransactions({ userId: user?._id, fromDate: startDate, toDate: endDate }));
        }

    }, [transactions, startDate, endDate, walletError]);

    useEffect(() => {
        if (startDate || endDate) {
            dispatch(getWalletTransactions({ userId: user?._id, fromDate: startDate, toDate: endDate }));
        }

    }, [startDate, endDate]);


    // Helper to get the appropriate amount symbol and color class
    const getAmountClass = (transaction) => {
        if (transaction.transactionStatus === 'Pending') {
            return 'pending';
        } else if (transaction.transactionStatus === 'Success') {
            return transaction.type === 'Debit' ? 'debit-success' : 'credit-success';
        } else if (transaction.transactionStatus === 'Failed') {
            return 'failed';
        }
        return '';
    };

    // Helper to return the amount symbol
    const getAmountSymbol = (transaction) => {
        if (transaction.transactionStatus === 'Pending') {
            return ''; // No symbol for pending
        }
        return transaction.type === 'Debit' ? '-' : '+'; // Debit with - and Credit with +
    };

    // Helper to get the appropriate status color class
    const getStatusClass = (transaction) => {
        if (transaction.transactionStatus === 'Pending') {
            return 'status-pending'; // Orange for pending
        } else if (transaction.transactionStatus === 'Success') {
            return 'status-success'; // Green for success
        } else if (transaction.transactionStatus === 'Failed') {
            return 'status-failed'; // Red for failed
        }
        return '';
    };

    const handleStartDateChange = (e) => {
        const selectedDate = e.target.value;
        const year = new Date(selectedDate).getFullYear();
    
        if (year >= 1000 && year <= 9999) {
            setStartDate(selectedDate);
            setInputStartDate(selectedDate);
        } else {
            // alert("Please enter a valid 4-digit year.");
            return
        }
    };
    
    const handleEndDateChange = (e) => {
        const selectedDate = e.target.value;
        const year = new Date(selectedDate).getFullYear();
    
        if (year >= 1000 && year <= 9999) {
            setEndDate(selectedDate);
            setInputEndDate(selectedDate);
        } else {
            // alert("Please enter a valid 4-digit year.");
            return
        }
    };


    return (
        <>
            <div className="">
                <span className="back-button" onClick={() => navigate(-1)}>
                    <ArrowBackIcon fontSize="small" />
                    <span>Back</span>
                </span>

            </div>
            <div className="wallet-container">
                {/* Wallet Balance */}
                <div className="wallet-header" style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                        <h2>Wallet Balance : </h2>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '3px' }}>
                        <div>
                            <p style={{ color: 'green' }}>₹ {walletBalance ? walletBalance.toFixed(2): 0}</p>
                        </div>
                    </div>

                </div>
                {/* <div className="paymentlist-date-filter">
                    <div className='start-date'>
                        <label htmlFor="startDate">From:</label>
                        <input
                            type="date"
                            value={inputStartDate === '' ? startDate : inputStartDate}
                            onChange={(e) => { setStartDate(e.target.value); setInputStartDate(e.target.value) }}
                            className="paymentlist-form-control"
                        />
                    </div>
                    <div className="end-date">
                        <label htmlFor="endDate">To:</label>
                        <input
                            type="date"
                            value={inputEndDate === '' ? endDate : inputEndDate}
                            onChange={(e) => { setEndDate(e.target.value); setInputEndDate(e.target.value) }}
                            className="paymentlist-form-control"
                        />
                    </div>
                    <div className="search-order">
                        <input
                            type="text"
                            id="orderId"
                            placeholder="Search Order ID"
                            value={searchOrderId}
                            onChange={(e) => setSearchOrderId(e.target.value)}
                            className="paymentlist-form-control"
                        />
                    </div>
                </div> */}

                <div className="paymentlist-container">
                    <div className="paymentlist-date-filter">
                        <div className="start-date">
                        <label htmlFor="startDate">From:</label>
                        <input
                            type="date"
                            value={inputStartDate === '' ? startDate : inputStartDate}
                            // onChange={(e) => { setStartDate(e.target.value); setInputStartDate(e.target.value) }}
                            onChange={handleStartDateChange}
                            className="paymentlist-form-control"
                        />
                        </div>
                        <div className="end-date">
                        <label htmlFor="endDate">To:</label>
                        <input
                            type="date"
                            value={inputEndDate === '' ? endDate : inputEndDate}
                            // onChange={(e) => { setEndDate(e.target.value); setInputEndDate(e.target.value) }}
                            onChange={handleEndDateChange}
                            className="paymentlist-form-control"
                            max={formattedCurrentDate}
                        />
                        </div>
                    </div>
                    <div className="filter-status">
                    <input
                            type="text"
                            id="orderId"
                            placeholder="Search Order ID"
                            value={searchOrderId}
                            onChange={(e) => setSearchOrderId(e.target.value)}
                            className="paymentlist-form-control"
                        />
                    </div>
                </div>

                {/* Filters */}
                <div className="filter-section">
                    <h3>Filters</h3>
                    <div className="filter-group">
                        {/* Filter by Transaction Status */}
                        <label>
                            Transaction Status:
                            <select
                                value={filters?.transactionStatus}
                                onChange={(e) =>
                                    setFilters({ ...filters, transactionStatus: e.target.value })
                                }
                            >
                                <option value="all">All</option>
                                <option value="Pending">Pending</option>
                                <option value="Success">Success</option>
                                <option value="Failed">Failed</option>
                            </select>
                        </label>

                        {/* Filter by Transaction Type */}
                        <label>
                            Transaction Type:
                            <select
                                value={filters?.transactionType}
                                onChange={(e) =>
                                    setFilters({ ...filters, transactionType: e.target.value })
                                }
                            >
                                <option value="all">All</option>
                                <option value="credit">Credit</option>
                                <option value="debit">Debit</option>
                            </select>
                        </label>

                        {/* Filter by Order Type */}
                        <label>
                            Order Type:
                            <select
                                value={filters?.orderType}
                                onChange={(e) =>
                                    setFilters({ ...filters, orderType: e.target.value })
                                }
                            >
                                <option value="all">All</option>
                                <option value="order">Order</option>
                                <option value="refund">Refund</option>
                                <option value="topup">Top-Up</option>
                            </select>
                        </label>
                    </div>
                </div>

                {/* Transaction History */}
                <div className="transactions-section">
                    <h3>Transaction History</h3>
                    {filteredTransactions?.length > 0 ? (
                        <ul>
                            {filteredTransactions.map((transaction) => (
                                <li key={transaction.id}>
                                    <div className="transaction-info">
                                        <span>{transaction.type}</span>
                                        {/* Amount with conditional styling */}
                                        <span className={`amount ${getAmountClass(transaction)}`}>
                                            {getAmountSymbol(transaction)}
                                            ₹{Math.abs(transaction.amount).toFixed(2)}
                                        </span>
                                    </div>
                                    <div className="transaction-meta">
                                        {/* Status with dynamic color */}
                                        {/* <span>OrderID: {transaction.order_id}</span> */}
                                        <span>OrderID:
                                            <Link to={`/order/${transaction.order_id}`} style={{ textDecoration: 'underLine', color: 'blue', marginLeft: '3px' }}>
                                                {transaction.order_id}
                                            </Link>
                                        </span>
                                        <span>{transaction.orderType}</span>
                                        <span className={`transaction-status ${getStatusClass(transaction)}`}>
                                            {transaction.transactionStatus}
                                        </span>
                                        {/* <span>{transaction.method}</span> */}

                                        {/* <span>{new Date(transaction.date).toLocaleDateString()}</span> */}
                                        <span>
                                            {new Date(transaction.date).toLocaleString('en-GB', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            })}
                                        </span>

                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="no-transactions">No transactions found</p>
                    )}
                </div>


            </div>
        </>

    );
};

export default WalletPage;
