import { Fragment, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Modal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { orderDetail as orderDetailAction, updateOrder, porterOrder, RemoveOrderResponse, adminOrders } from "../../actions/orderActions";
import { CancelOrderResponse, createPorterOrderResponse, getPackedOrder, getporterOrder, initRefund, packedOrder, updatedPackedOrder,initPendingRefund } from "../../actions/porterActions";
import { Slide, toast } from "react-toastify";
import { clearOrderUpdated, clearError, adminOrderRemoveClearError, orderDetailClear } from "../../slices/orderSlice";
import { clearRefundError } from "../../slices/porterSlice";
import Loader from "../Layouts/Loader";
import MetaData from "../Layouts/MetaData";
import LoaderButton from "../Layouts/LoaderButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const RefundOrder = ({ isActive, setIsActive }) => {
    const location = useLocation();
    // sessionStorage.setItem('redirectPath', location.pathname);
    const { isOrderUpdated, error, orderDetail, porterOrderDetail, orderRemoveResponse, orderRemoveError } = useSelector(state => state.orderState);
    const { products } = useSelector((state) => state.productsState);
    const { porterOrderData, porterOrderResponse, porterCancelResponse, porterCancelError, portererror, getpackedOrderData, refundData, refundError } = useSelector((state) => state.porterState);
    const { user = {}, orderItems = [], shippingInfo = {}, totalPrice = 0, statusResponse = {}, paidOnline = 0, paidWallet = 0 } = orderDetail;
    const [orderStatus, setOrderStatus] = useState("Processing");
    const [dropStatus, setDropStatus] = useState("");
    const [editableWeights, setEditableWeights] = useState(orderDetail && orderItems && orderItems.map(item => item.productWeight)); // Initial state for weights
    const { id } = useParams();
    const [refreshData, setRefreshData] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [refundloading, setRefundLoading] = useState(false)
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setShowModal] = useState(false);
    const [refundAmount, setRefundAmount] = useState(0);
    const [pendingAmount, setpendingAmount] = useState(0);
    const { totalDispatchedAmount = "0", totalRefundableAmount = "0",refundAmountInitiated=0 } = getpackedOrderData || {};
    const shippingCharge = Number(orderDetail?.shippingPrice || 0);
    const [refundMethod, setRefundMethod] = useState("wallet");
    const [isPendingOpen, setShowPending] = useState(false);


    // Calculate Refundable Amount
    useEffect(() => {
        // Parse the values from strings to numbers
        const dispatchedAmount = Number(totalDispatchedAmount);
        const refundableAmount = Number(totalRefundableAmount);

        const RefundableAmount =
            dispatchedAmount > 0
                ? refundableAmount
                : refundableAmount + shippingCharge;

        setRefundAmount(RefundableAmount);
    }, [totalDispatchedAmount, totalRefundableAmount, shippingCharge]);

    useEffect(()=>{
        const dispatchedAmount = Number(totalDispatchedAmount);
        const refundableAmount = Number(totalRefundableAmount);
        if(refundAmountInitiated > 0 && refundableAmount > 0 && getpackedOrderData && orderDetail){
          
            const pendingRefundableAmount =
            dispatchedAmount > 0 && (refundAmountInitiated !== refundableAmount)
                ? refundableAmount-refundAmountInitiated
                : dispatchedAmount <= 0 && (refundAmountInitiated !== (refundableAmount+shippingCharge))
                ? (refundableAmount-refundAmountInitiated) + shippingCharge 
                : 0 ;

                setpendingAmount(pendingRefundableAmount);
        }
        
    },[totalDispatchedAmount, totalRefundableAmount, shippingCharge,refundAmountInitiated])


    useEffect(() => {
        if (orderDetail.order_id) {
            setLoading(false);
            setOrderStatus(orderDetail.orderStatus);
            setDropStatus(orderDetail.orderStatus);
        }
        if (orderItems) {
            setEditableWeights(orderItems.map(item => item.productWeight))
        }
    }, [orderDetail]);

    useEffect(() => {
        if (orderDetail && (orderDetail.orderStatus === 'Processing' || orderDetail.orderStatus === 'Packed')) {
            setLoading(false);
            toast.dismiss();
            setTimeout(() => {
                toast.error('Cannot refund because the order did not dispatched!', {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
            navigate('/admin/refund');
        }
    }, [orderDetail])

    // const submitRefundHandler = () => {
    //     const totalDispatchedAmount = Number(getpackedOrderData && getpackedOrderData.totalDispatchedAmount);
    //     const totalRefundableAmount = Number(getpackedOrderData && getpackedOrderData.totalRefundableAmount);
    //     const shippingCharge = Number(orderDetail.shippingPrice);

    //     let RefundableAmount;

    //     // Check if totalDispatchedAmount is greater than 0
    //     if (totalDispatchedAmount > 0) {
    //         // Use totalRefundableAmount if dispatched amount is greater than 0
    //         RefundableAmount = totalRefundableAmount;
    //     } else {
    //         // Add shipping charge to totalRefundableAmount if no dispatched amount
    //         RefundableAmount = totalRefundableAmount + shippingCharge;
    //     }

    //     setRefundLoading(true); // Set loading state
    //     if (RefundableAmount > 0) {
    //         dispatch(initRefund({ order_id: id, RefundableAmount }));
    //     }
    //     else {
    //         toast.dismiss();
    //         setTimeout(() => {
    //             toast.error('Cannot refund because of invalid refund amount!', {
    //                 position: 'bottom-center',
    //                 type: 'error',
    //                 autoClose: 700,
    //                 transition: Slide,
    //                 hideProgressBar: true,
    //                 className: 'small-toast',
    //             });
    //         }, 300);
    //         setRefundLoading(false);
    //     }
    // }

    const handleCancel = () => {
        setShowModal(false);
        setShowPending(false);
    }

    const handleRefundClick = () => {
        setShowModal(true);
    };

    const handlePendingRefund = () => {
        setShowPending(true);
    };

    const handleContinueRefund = () => {
        setRefundLoading(true);

        if (refundMethod === "wallet") {
            // Process wallet refund
            dispatch(
                initRefund({
                    order_id: orderDetail.order_id,
                    refundType: "wallet",
                    amount: refundAmount,
                    onlineRefundAmount: 0,
                    walletRefundAmount: refundAmount,
                    userId: orderDetail?.user_id,
                    userinfo: orderDetail?.user,
                })
            );
        } else if (refundMethod === "online") {

            // const onlineRefundAmount = Math.min(paidOnline, refundAmount);
            // const walletRefundAmount = refundAmount - onlineRefundAmount;


            // dispatch(
            //     initRefund({
            //         order_id: orderDetail.order_id,
            //         refundType: "online",
            //         amount: refundAmount,
            //         onlineRefundAmount,
            //         walletRefundAmount,
            //         userId: orderDetail?.user_id,
            //         userinfo: orderDetail?.user,
            //     })
            // );
            toast.dismiss();
            setTimeout(() => {
                toast.warn('Online refund service is temporarily unavailable. Please choose a different refund method.', {
                    position: 'bottom-center',
                    // type: 'warn',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onClose: () => { setRefundLoading(false); }
                });
            }, 300);

        }

        // setLoading(false);
        // setShowModal(false);
        // toast.success("Refund initiated successfully!");
    };

    const handleContinuePendingRefund = () => {

        setRefundLoading(true);

        if (refundMethod === "wallet") {
            // Process wallet refund
            dispatch(
                initPendingRefund({
                    order_id: orderDetail.order_id,
                    refundType: "wallet",
                    amount: pendingAmount,
                    onlineRefundAmount: 0,
                    walletRefundAmount: pendingAmount,
                    userId: orderDetail?.user_id,
                    userinfo: orderDetail?.user,
                })
            );

            // toast.dismiss();
            // setTimeout(() => {
            //     toast.warn('Pending Refund amount is ready to refund.', {
            //         position: 'bottom-center',
            //         // type: 'warn',
            //         autoClose: 700,
            //         transition: Slide,
            //         hideProgressBar: true,
            //         className: 'small-toast',
            //         onClose: () => { setRefundLoading(false); }
            //     });
            // }, 300);


        } else if (refundMethod === "online") {
            toast.dismiss();
            setTimeout(() => {
                toast.warn('Online refund service is temporarily unavailable. Please choose a different refund method.', {
                    position: 'bottom-center',
                    // type: 'warn',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onClose: () => { setRefundLoading(false); }
                });
            }, 300);

        }
    };

    

    useEffect(() => {
        if (refundData) {
            toast.dismiss();
            setTimeout(() => {
                toast.success(refundData, {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearRefundError()); setShowModal(false); setShowPending(true); },
                    onClose: () => { setRefundLoading(false); }
                });
                dispatch(updatedPackedOrder({}));
                dispatch(adminOrders());
            }, 300);
            // setRefundLoading(false);

            return;

        }
        dispatch(orderDetailAction(id));
        dispatch(getPackedOrder({ order_id: id }))
        dispatch(getporterOrder({ order_id: id }))
        setRefreshData(true)

    }, [dispatch, id, porterOrderDetail, refundData]);

    useEffect(() => {
        if (error) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(error, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearError()) },
                    onClose: () => { setRefundLoading(false); }
                });
            }, 300);
            // setRefundLoading(false);
        }
        if (refundError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(refundError, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearRefundError()) },
                    onClose: () => { setRefundLoading(false); }
                });
            }, 300);
            // setRefundLoading(false);
        }
    }, [refundError, error])

    useEffect(() => {
        if (porterOrderData && refreshData) {
            dispatch(createPorterOrderResponse({ order_id: porterOrderData && porterOrderData.order_id, porterOrder_id: porterOrderData?.porterOrder?.order_id }))
        }
    }, [porterOrderData])

    useEffect(() => {
        if (refreshData && porterOrderResponse) {
            dispatch(getporterOrder({ order_id: id }))
            setRefreshData(false)
        }
    }, [refreshData, porterOrderResponse]);

    const capitalizeFirstLetter = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const onlineAmount = Math.min(paidOnline ? paidOnline : 0, refundAmount ? refundAmount : 0);
    const walletAmount = refundAmount - onlineAmount;

    return (
        <div>
            <MetaData
                title="Refund Order"
                description="Process individual order refunds. Manage refunds based on order details and customer requests, ensuring a smooth refund process."
            />


            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div>

                <div className="col-12 col-md-10 smalldevice-space container order-detail-container loader-parent">
                    <div className="">
                        <span className="back-button" onClick={() => navigate(-1)}>
                            <ArrowBackIcon fontSize="small" />
                            <span>Back</span>
                        </span>

                    </div>
                    {
                        loading ? (
                            <div className="container loader-loading-center">
                                <Loader />
                            </div>
                        )
                            : (
                                <Fragment>
                                    <div className="col-12 col-lg-12 mt-5 order-details">
                                        <h1 className="order-id-title mb-4">Order ID: {orderDetail.order_id}</h1>

                                        <h4 className="shipping-title mb-2">Shipping Info</h4>
                                        <div className="shipping-info">
                                            <div><b>Name:</b> {shippingInfo.name}</div>
                                            <div><b>Phone:</b> {shippingInfo.phoneNo}</div>
                                            <div className='order-address-formatted'>
                                                <b>Address:</b>
                                                {shippingInfo.address && `${shippingInfo.address},`}
                                                {shippingInfo.area && `${shippingInfo.area},`}
                                                {shippingInfo.landmark && `${shippingInfo.landmark},`}
                                                {shippingInfo.city && `${shippingInfo.city}`}
                                                {shippingInfo.postalCode && -`${shippingInfo.postalCode}`}
                                            </div>
                                            <div><b>Total Amount:</b> ₹ {parseFloat(totalPrice).toFixed(2)}</div>
                                            <div><b>Online Payment:</b> ₹ {orderDetail?.paidOnline}</div>
                                            <div><b>Wallet Payment:</b> ₹ {orderDetail?.paidWallet}</div>
                                            {orderDetail && orderDetail?.statusResponse && orderDetail?.statusResponse?.payment_method ? (
                                                <div><b>Payment Mode:</b> {orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.payment_method}</div>

                                            ) : (
                                                <div><b>Payment Mode:</b> {orderDetail && orderDetail?.payment_method}</div>
                                            )

                                            }
                                        </div>
                                        <hr />

                                        <div className="status-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div><b>Payment Status:</b></div>
                                                <div style={{ color: orderDetail && orderDetail.paymentStatus === 'CHARGED' ? 'green' : 'red', marginLeft: '10px' }}>
                                                    <b>{orderDetail && orderDetail.paymentStatus === 'CHARGED' ? 'Success' : orderDetail ? orderDetail.paymentStatus : 'Pending'}</b>
                                                </div>
                                            </div>
                                            {getpackedOrderData && getpackedOrderData.totalRefundableAmount > 0 && (
                                                <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                                    <div><b>Refund Status:</b></div>
                                                    <div style={{ color: orderDetail && orderDetail?.statusResponse && orderDetail?.statusResponse?.refunds && orderDetail?.statusResponse?.refunds[0]?.status === 'SUCCESS' ? 'green' : getpackedOrderData && getpackedOrderData?.refundStatus === 'SUCCESS' ? 'green' : 'red', marginLeft: '10px' }}>
                                                        <b>{orderDetail && orderDetail?.statusResponse?.refunds ? (
                                                            orderDetail && orderDetail?.statusResponse && orderDetail?.statusResponse?.refunds ? orderDetail?.statusResponse?.refunds[0].status : 'Processing'
                                                        ) : (
                                                            getpackedOrderData && getpackedOrderData?.refundStatus ? getpackedOrderData?.refundStatus : 'Processing'
                                                        )

                                                        }
                                                        </b>
                                                    </div>
                                                </div>
                                            )}
                                        </div>


                                        <hr />
                                        <h4 className=" order-items my-4">Order Items:</h4>

                                        <div className="invoice-table-container">
                                            <div className="updatetable-responsive">
                                                {
                                                    getpackedOrderData && getpackedOrderData.totalRefundableAmount > 0 ? (

                                                        <table className="updatetable updatetable-bordered">
                                                            <thead>
                                                                <tr>
                                                                    {getpackedOrderData && getpackedOrderData.dispatchedTable && (
                                                                        <>
                                                                            <th>Image</th>
                                                                            <th>Name</th>
                                                                            <th>Price</th>
                                                                            <th>Ordered Quantity</th>
                                                                            <th>Refund Quantity</th>
                                                                            <th>Refundable Amount</th>
                                                                        </>
                                                                    )}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {getpackedOrderData && getpackedOrderData.dispatchedTable && (
                                                                    getpackedOrderData.dispatchedTable
                                                                        .filter(item => item.refundableWeight > 0) // Filter rows with refundable weight
                                                                        .map((item, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <img src={item.image} alt={item.name} className="updateTableproduct-image" />
                                                                                </td>
                                                                                <td>{item && item.range ? `${capitalizeFirstLetter(item.name)} (${item.range})` : `${capitalizeFirstLetter(item.name)}`} </td>
                                                                                <td>₹ {parseFloat(item.pricePerKg).toFixed(2)}</td>
                                                                                <td>{item.orderedWeight} {item.measurement}</td>
                                                                                <td>{item.refundableWeight} {item.measurement}</td>
                                                                                <td>₹ {parseFloat(item.pricePerKg * item.refundableWeight).toFixed(2)}</td>
                                                                            </tr>
                                                                        ))
                                                                )}
                                                                {/* Calculate total refundable amount */}
                                                                <tr>
                                                                    <td colSpan="5" style={{ textAlign: 'right' }}>
                                                                        <strong>Total Refundable Amount</strong>
                                                                    </td>
                                                                    <td className="amount">
                                                                        <strong>

                                                                            ₹ {getpackedOrderData && getpackedOrderData.totalDispatchedAmount > 0
                                                                                ? getpackedOrderData.totalRefundableAmount
                                                                                : getpackedOrderData &&
                                                                                (Number(getpackedOrderData && getpackedOrderData.orderDetail.shippingPrice) +
                                                                                    Number(getpackedOrderData && getpackedOrderData.totalRefundableAmount))}
                                                                        </strong>
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.amount_refunded && orderDetail.statusResponse.amount_refunded > 0 && orderDetail.statusResponse.refunds && orderDetail.statusResponse.refunds[0].status === 'SUCCESS' ? (<tr>
                                                                        <td colSpan="5" style={{ textAlign: 'right' }}>
                                                                            <strong>Amount Refunded</strong>
                                                                        </td>
                                                                        <td className="amount">

                                                                            {
                                                                                orderDetail.statusResponse && orderDetail.statusResponse.amount_refunded ? (
                                                                                    <strong>
                                                                                        ₹ {orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.amount_refunded ? parseFloat(orderDetail.statusResponse.amount_refunded + getpackedOrderData?.refundedWallet).toFixed(2) : 0
                                                                                        }
                                                                                    </strong>
                                                                                ) : (
                                                                                    <strong>
                                                                                        ₹ {getpackedOrderData && getpackedOrderData?.refundedWallet ? parseFloat(getpackedOrderData?.refundedWallet).toFixed(2) : 0}
                                                                                    </strong>

                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="5" style={{ textAlign: 'right' }}>
                                                                                <strong>Amount Refunded</strong>
                                                                            </td>
                                                                            <td className="amount">
                                                                                <strong>
                                                                                    ₹ {getpackedOrderData && getpackedOrderData?.refundedWallet ? parseFloat(getpackedOrderData?.refundedWallet).toFixed(2) : 0}
                                                                                </strong>
                                                                            </td>
                                                                        </tr>
                                                                    )

                                                                }

                                                            </tbody>
                                                        </table>


                                                    ) : (
                                                        <>
                                                            there is no refund data
                                                        </>
                                                    )
                                                }

                                            </div>
                                        </div>

                                        <hr />
                                        <div>
                                            {
                                                (orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.refunds || getpackedOrderData && getpackedOrderData?.refundAmountInitiated > 0) ? (
                                                    <button className='btn btn-primary' style={{ cursor: 'not-allowed' }} disabled={true}>Already initiated</button>
                                                ) : (
                                                    <button className='btn btn-primary' onClick={handleRefundClick}
                                                        style={{ cursor: (dropStatus === "Refund" || refundloading) ? 'not-allowed' : 'pointer' }}
                                                        // disabled={true}
                                                        disabled={orderDetail && (orderDetail.orderStatus === 'Processing' || orderDetail.orderStatus === 'Packed' || refundloading) ? true : false}
                                                    >
                                                        {refundloading ? <LoaderButton fullPage={false} size={20} /> : (
                                                            <span> Refund</span>
                                                        )

                                                        }

                                                    </button>
                                                )

                                            }
                                            {getpackedOrderData && Number(getpackedOrderData?.totalRefundableAmount) > 0 && (getpackedOrderData?.refundAmountInitiated !== Number(getpackedOrderData?.totalRefundableAmount)) &&  Number(getpackedOrderData?.totalDispatchedAmount) > 0 && pendingAmount > 0 ? (
                                                <button className="btn btn-primary float-right" onClick={handlePendingRefund} >
                                                    Refund Pending Amount
                                                </button>
                                            ): Number(getpackedOrderData?.totalDispatchedAmount) <= 0 && Number(getpackedOrderData?.totalRefundableAmount) > 0 && (getpackedOrderData?.refundAmountInitiated !== (Number(getpackedOrderData?.totalRefundableAmount) + shippingCharge)) && pendingAmount > 0 ? (
                                                <button className="btn btn-primary float-right" onClick={handlePendingRefund} >
                                                Refund Pending Amount
                                            </button>
                                            ):(
                                                <>
                                                </>
                                            )

                                            }



                                        </div>

                                        {/* <button className="btn btn-primary" onClick={handleRefundClick}>
                                            Refund
                                        </button> */}

                                    </div>
                                </Fragment>
                            )
                    }


                </div>
                {isModalOpen && (
                    <div className="modal" tabIndex="-1" role="dialog" style={modalStyle}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div>Refund For order: {orderDetail.order_id}</div>
                                    <button type="button" className="close" onClick={handleCancel}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                {/* <div className="modal-body">
                                <h4>Refund Details</h4>
                                <p className="refund-model-p">Total Refundable Amount: ₹{refundAmount.toFixed(2)}</p>
                                    {paidOnline <= 0 ? (
                                        <label>
                                            <input
                                                type="radio"
                                                name="refundMethod"
                                                value="wallet"
                                                checked={refundMethod === "wallet"}
                                                onChange={() => setRefundMethod("wallet")}
                                            />
                                            Refund via Wallet
                                        </label>
                                    ) : (
                                        <>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="refundMethod"
                                                        value="wallet"
                                                        checked={refundMethod === "wallet"}
                                                        onChange={() => setRefundMethod("wallet")}
                                                    />
                                                    Refund via Wallet
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="refundMethod"
                                                        value="online"
                                                        checked={refundMethod === "online"}
                                                        onChange={() => setRefundMethod("online")}
                                                    />
                                                    Refund via Online
                                                </label>
                                            </div>
                                        </>
                                    )}
                                    {refundMethod === "wallet" && (
                                        <div className="refund-details">
                                            <p refund-model-p>Refund Amount via Wallet: ₹{refundAmount.toFixed(2)}</p>
                                            <p refund-model-p>Online Refund Amount: ₹0.00</p>
                                        </div>
                                    )}
                                    {refundMethod === "online" && ( 
                                        <div className="refund-details">
                                            <p refund-model-p>Refund Amount via Online: ₹{onlineAmount.toFixed(2)}</p>
                                            <p refund-model-p>Refund Amount via Wallet: ₹ {walletAmount.toFixed(2)}</p>
                                        </div>
                                    )}
                                </div> */}

                                <div className="modal-body">
                                    <h4 className="refund-h4">Refund Details</h4>
                                    <p className="refund-para">Total Refundable Amount: ₹{refundAmount.toFixed(2)}</p>
                                    {paidOnline <= 0 ? (
                                        <label className="refund-label">
                                            <input
                                                type="radio"
                                                name="refundMethod"
                                                value="wallet"
                                                checked={refundMethod === "wallet"}
                                                onChange={() => setRefundMethod("wallet")}
                                            />
                                            Refund via Wallet
                                        </label>
                                    ) : (
                                        <>
                                            <div>
                                                <label className="refund-label">
                                                    <input
                                                        type="radio"
                                                        name="refundMethod"
                                                        value="wallet"
                                                        checked={refundMethod === "wallet"}
                                                        onChange={() => setRefundMethod("wallet")}
                                                    />
                                                    Refund via Wallet
                                                </label>
                                            </div>
                                            <div>
                                                <label className="refund-label">
                                                    <input
                                                        type="radio"
                                                        name="refundMethod"
                                                        value="online"
                                                        checked={refundMethod === "online"}
                                                        onChange={() => setRefundMethod("online")}
                                                    />
                                                    Refund via Online
                                                </label>
                                            </div>
                                        </>
                                    )}
                                    {refundMethod === "wallet" && (
                                        <div className="refund-details">
                                            <p>Refund Amount via Wallet: ₹{refundAmount.toFixed(2)}</p>
                                            <p>Online Refund Amount: ₹0.00</p>
                                        </div>
                                    )}
                                    {refundMethod === "online" && (
                                        <div className="refund-details">
                                            <p>Refund Amount via Online: ₹{onlineAmount.toFixed(2)}</p>
                                            <p>Refund Amount via Wallet: ₹ {walletAmount.toFixed(2)}</p>
                                        </div>
                                    )}
                                </div>



                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={handleContinueRefund} disabled={refundloading}>
                                        {refundloading ? <LoaderButton fullPage={false} size={20} /> : (
                                            <span>  Continue</span>
                                        )
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {isPendingOpen && pendingAmount > 0 &&(
                    <div className="modal" tabIndex="-1" role="dialog" style={modalStyle}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div>Refund For order: {orderDetail.order_id}</div>
                                    <button type="button" className="close" onClick={handleCancel}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <h4 className="refund-h4">Refund Details</h4>
                                    <p className="refund-para">Total Pending Refundable Amount: ₹{pendingAmount.toFixed(2)}</p>
                                  
                                        <label className="refund-label">
                                            <input
                                                type="radio"
                                                name="refundMethod"
                                                value="wallet"
                                                checked={refundMethod === "wallet"}
                                                onChange={() => setRefundMethod("wallet")}
                                            />
                                            Refund via Wallet
                                        </label>
                                    
                                    {refundMethod === "wallet" && (
                                        <div className="refund-details">
                                            <p>Refund Amount via Wallet: ₹{pendingAmount.toFixed(2)}</p>
                                        </div>
                                    )}
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={handleContinuePendingRefund} disabled={refundloading}>
                                        {refundloading ? <LoaderButton fullPage={false} size={20} /> : (
                                            <span>  Continue</span>
                                        )
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );



};

const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)'
};


export default RefundOrder;