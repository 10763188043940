
import React, { Fragment, useEffect, useState } from 'react';
import MetaData from '../Layouts/MetaData';
import { Link, useNavigate } from 'react-router-dom';
import StepsCheckOut from './StepsCheckOut';
import axios from 'axios';
import { Slide, toast } from 'react-toastify';
import Loader from '../Layouts/Loader'; // Ensure this path is correct based on your project structure
import { createOrder } from '../../actions/orderActions';
import { useDispatch, useSelector } from 'react-redux';
import { orderCompleted } from '../../slices/cartSlice';
import { useLocation } from 'react-router-dom';
import { loadUser } from '../../actions/userActions';
import store from '../../store';
import { getProducts } from '../../actions/productsActions';
import CryptoJS from 'crypto-js';
import LoaderButton from '../Layouts/LoaderButton';
import { getAnnouncements } from '../../actions/announcementAction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes, addDays, isWithinInterval, startOfDay, endOfDay, format } from 'date-fns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NumberInput from '../Layouts/NumberInput';


const ConfirmOrder = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {
        shippingCharge = 0,
        porterShippingCharge = 0,
        defaultAddress = null,
        subtotal = 0,
        total = 0,
        items = [],
        originalSubtotal = 0,
        originalTotal = 0,
    } = location.state || {};

    // console.log("shippingCharge",shippingCharge)
    // const [cartItems, setItems] = useState(() => {
    //     return JSON.parse(localStorage.getItem("cartItems")) || [];
    // });
    const { items: cartItems } = useSelector(state => state.cartState);
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
    const { user } = useSelector(state => state.authState);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [OrderedDateUF, setOrderedDateUF] = useState(null);
    const [OrderedDate, setOrderedDate] = useState(null);
    const queryParams = new URLSearchParams(location.search);
    const message = queryParams.get('message');
    const [showModal, setShowModal] = useState(false);
    const [isLeaveDay, setIsLeaveDay] = useState(false);
    const isReducedCost = parseFloat(originalTotal - total).toFixed(2);
    const [orderDescription, setOrderDescription] = useState('');
    const [useWallet, setUseWallet] = useState(false);
    const [walletUsage, setWalletUsage] = useState(0);
    const remainingAmount = total - walletUsage;
    const [WalletBalance, setWalletBalance] = useState(null);
    const {
        getAnnouncement,
        getAnnouncementLoading
    } = useSelector(state => state.announcementState);
    const [disabledDates, setDisabledDates] = useState([]);
    useEffect(() => {
        if (!getAnnouncement) {
            dispatch(getAnnouncements())
        }
    }, [])

    useEffect(() => {
        if (!defaultAddress && !shippingCharge) {
            navigate('/cart');
        }
        if (!cartItems.length) {
            toast.dismiss();
            setTimeout(() => {
                toast.error('Cart is empty. Please add at least one item to proceed! ', {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
            navigate('/cart');
        }
    }, [defaultAddress, shippingCharge, cartItems, navigate]);

    const initPayment = async (data) => {
        if (data && data.payment_links && data.payment_links.web) {
            window.location.href = data.payment_links.web;
            // window.open(data.payment_links.web, '_blank');
        } else {
            alert('Failed to initiate payment: ' + (data.message || 'Unknown error'));
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     if (user && total > 0) {
    //         setWalletUsage(Math.min(user.walletBalance || 0, total));
    //     }
    // }, [user, total]);


    // Function to decrypt the data
    const decryptData = (walletBalance, encryptionKey) => {
        const bytes = CryptoJS.AES.decrypt(walletBalance, encryptionKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    };
    const handleWalletToggle = () => {
        if (useWallet) {
            setWalletUsage(0); // Reset wallet usage when unchecked
        } else {
            // const walletbalance = decryptData(user?.walletBalance, encryptionKey)
            // setWalletUsage(Math.min(walletbalance || 0, total)); // Set wallet usage
            const walletbalance = decryptData(user?.walletBalance, encryptionKey);
            let newWalletUsage = Math.min(walletbalance || 0, total);
            setWalletUsage(parseFloat(newWalletUsage.toFixed(2))); // Ensure 2 decimal places
        }
        setUseWallet(!useWallet); // Toggle useWallet state
        // setUseWallet((prev) => !prev);
    };

    useEffect(() => {
        const walletbalance = decryptData(user?.walletBalance, encryptionKey);
        setWalletBalance(walletbalance)

    }, [user])

    // Handle wallet usage change
    const handleWalletUsageChange = (e) => {
        // const value = parseFloat(e.target.value) || 0;
        // const walletbalance = decryptData(user?.walletBalance, encryptionKey)
        // if (value <= Math.min(walletbalance || 0, total)) {
        //     setWalletUsage(value);
        //     // setWalletUsage(parseFloat(value.toFixed(2)));
        // }
        let value = e.target.value;
        if (value === "") {
            setWalletUsage("");
            return;
        }

        // Restrict input to 2 decimal places
        if (value.includes(".")) {
            const [integerPart, decimalPart] = value.split(".");
            if (decimalPart.length > 2) {
                value = `${integerPart}.${decimalPart.substring(0, 2)}`;
            }
        }

        value = parseFloat(value) || 0;
        const walletbalance = decryptData(user?.walletBalance, encryptionKey);

        if (value <= Math.min(walletbalance || 0, total)) {
            setWalletUsage(value);
        }
    };


    // const handleWalletUsageChange = (e) => {
    //     const inputValue = parseFloat(e.target.value) || 0;
    //     const maxUsage = Math.min(user.walletBalance || 0, total);

    //     if (inputValue <= maxUsage) {
    //         setWalletUsage(inputValue);
    //     } else {
    //         setWalletUsage(maxUsage);
    //     }
    // };


    // Encryption key - ensure you keep this secure and do not expose it in the frontend

    const staticKeys = [
        process.env.REACT_APP_ENCRYPTION_KEY_1,
        process.env.REACT_APP_ENCRYPTION_KEY_2,
        process.env.REACT_APP_ENCRYPTION_KEY_3,
        process.env.REACT_APP_ENCRYPTION_KEY_4,
        process.env.REACT_APP_ENCRYPTION_KEY_5,
        process.env.REACT_APP_ENCRYPTION_KEY_6,
        process.env.REACT_APP_ENCRYPTION_KEY_7,
        process.env.REACT_APP_ENCRYPTION_KEY_8,
        process.env.REACT_APP_ENCRYPTION_KEY_9,
        process.env.REACT_APP_ENCRYPTION_KEY_10
    ];

    const resetTime = (date) => {
        const resetDate = new Date(date);
        resetDate.setHours(0, 0, 0, 0); // Set time to midnight
        return resetDate;
    };

    const processPayment = async () => {
        // Validate if the OrderedDate matches any announcement dates
        const currentDate = new Date();
        const currentHour = currentDate.getHours();
        let orderDate;

        // Determine initial delivery date
        if (currentHour < 21) {
            orderDate = new Date(currentDate);
            orderDate.setDate(orderDate.getDate() + 1); // Next day
        } else {
            orderDate = new Date(currentDate);
            orderDate.setDate(orderDate.getDate() + 2); // Day after tomorrow
        }

        const isWithinLeaveDate = (date) => {
            let leaveMessage = '';
            let isLeave = false;

            getAnnouncement &&
                getAnnouncement.forEach(({ startDate, endDate, type }) => {
                    if (type === 'Announcement') {
                        const leaveStart = resetTime(new Date(startDate));
                        const leaveEnd = resetTime(new Date(endDate));
                        const checkDate = resetTime(date);

                        if (checkDate >= leaveStart && checkDate <= leaveEnd) {
                            isLeave = true;
                            const displayStart = new Date(leaveStart);
                            const displayEnd = new Date(leaveEnd);
                            displayStart.setDate(displayStart.getDate() - 1);
                            displayEnd.setDate(displayEnd.getDate() - 1);

                            leaveMessage = `Orders could not be placed from ${format(displayStart, 'MMM d')} to ${format(
                                displayEnd,
                                'MMM d'
                            )}. We will be available from ${format(leaveEnd, 'MMM d')}.`;
                        }
                    }
                });

            return { isLeave, leaveMessage };
        };

        // Validate if the ordered date falls within any leave date
        const { isLeave, leaveMessage } = isWithinLeaveDate(orderDate);

        if (isLeave) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(leaveMessage, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
            return; // Stop further processing
        }
        if (remainingAmount > 0) {
            if (remainingAmount < 1) {
                toast.dismiss();
                setTimeout(() => {
                    toast.error('The amount must be at least one rupee to proceed with online payment...', {
                        position: 'bottom-center',
                        type: 'error',
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: 'small-toast',
                    });
                }, 300);
                return;
            }
            setLoading(true);
            const randomKey = staticKeys[Math.floor(Math.random() * staticKeys.length)];
            const encryptData = (data, randomKey) => {
                return CryptoJS.AES.encrypt(data.toString(), randomKey).toString();
            };
            const generateSignature = (data, randomKey) => {
                return CryptoJS.HmacSHA256(data, randomKey).toString();
            };
            const encryptedItemsPrice = encryptData(subtotal, randomKey);
            const encryptedShippingPrice = encryptData(shippingCharge, randomKey);
            const encryptedporterShippingCharge = encryptData(porterShippingCharge, randomKey);
            const encryptedTotalPrice = encryptData(total, randomKey);
            const signature = generateSignature(`${subtotal}${shippingCharge}${total}`, randomKey);
            const encryptedviaOnline = encryptData(remainingAmount, randomKey);
            const encryptedviaWallet = encryptData(walletUsage, randomKey);
            // Encrypt the selected random key with your master encryption key
            const plainText = CryptoJS.AES.encrypt(randomKey, encryptionKey).toString();
            const filteredCartItems = cartItems.filter(item => item.stocks === "Stock");

            const reqdata = {
                shippingInfo: defaultAddress && defaultAddress,
                user,
                user_id: user._id,
                cartItems: filteredCartItems,
                itemsPrice: encryptedItemsPrice,
                taxPrice: 0.0,
                shippingPrice: encryptedShippingPrice,
                porterShippingCharge: encryptedporterShippingCharge,
                totalPrice: encryptedTotalPrice,
                signature,
                plainText,
                OrderedDate,
                orderDescription,
                // viaOnline: remainingAmount,
                // viaWallet: walletUsage,
                viaOnline: encryptedviaOnline,
                viaWallet: encryptedviaWallet,
                paidOnline: 0,
                paidWallet: 0,
            };

            sessionStorage.setItem('orderInfo', JSON.stringify(reqdata));

            try {
                const orderUrl = '/api/v1/payment/orders';
                const { data } = await axios.post(orderUrl, reqdata, { withCredentials: true });
                if (data && data.sessionResponse) {
                    const order = {
                        order_id: data.sessionResponse.order_id,
                        user_id: user._id,
                        user: user,
                        cartItems: filteredCartItems,
                        shippingInfo: defaultAddress && defaultAddress,
                        itemsPrice: parseFloat(subtotal),
                        taxPrice: 0.0,
                        shippingPrice: parseFloat(shippingCharge),
                        porterShippingCharge: parseFloat(porterShippingCharge),
                        totalPrice: parseFloat(total),
                        paymentStatus: data.sessionResponse.status,
                        viaOnline: remainingAmount,
                        viaWallet: walletUsage,
                        paidOnline: 0,
                        paidWallet: 0,
                    };

                    dispatch(createOrder(order));
                } else {
                    toast.dismiss();
                    setTimeout(() => {
                        toast.error('Failed to Create the Order', {
                            position: 'bottom-center',
                            type: 'error',
                            autoClose: 700,
                            transition: Slide,
                            hideProgressBar: true,
                            className: 'small-toast',
                        });
                    }, 300);
                }

                if (data && data.sessionResponse) {
                    const payloadAmount = parseFloat(data.sessionResponse.sdk_payload.payload.amount).toFixed(2);
                    const totalAmount = parseFloat(remainingAmount).toFixed(2);

                    if (payloadAmount === totalAmount) {
                        initPayment(data.sessionResponse);
                    } else {
                        toast.dismiss();
                        setTimeout(() => {
                            toast.error('Mismatch initial Amount, possible data tampering detected', {
                                position: 'bottom-center',
                                type: 'error',
                                autoClose: 700,
                                transition: Slide,
                                hideProgressBar: true,
                                className: 'small-toast',
                            });
                        }, 300);
                        setLoading(false);
                    }
                }
            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.message) {
                    toast.dismiss();
                    setTimeout(() => {
                        toast.error(error && error.response.data.message, {
                            position: 'bottom-center',
                            type: 'error',
                            autoClose: 700,
                            transition: Slide,
                            hideProgressBar: true,
                            className: 'small-toast',
                        });
                    }, 300);
                }
                setLoading(false);
                setShowModal(false);
            }
        }
        else {
            // toast.dismiss();
            // setTimeout(() => {
            //     toast.error("this payment will go via direct because of wallet usage", {
            //         position: 'bottom-center',
            //         type: 'error',
            //         autoClose: 700,
            //         transition: Slide,
            //         hideProgressBar: true,
            //         className: 'small-toast',
            //     });
            // }, 300);
            setLoading(true);
            const randomKey = staticKeys[Math.floor(Math.random() * staticKeys.length)];
            const encryptData = (data, randomKey) => {
                return CryptoJS.AES.encrypt(data.toString(), randomKey).toString();
            };
            const generateSignature = (data, randomKey) => {
                return CryptoJS.HmacSHA256(data, randomKey).toString();
            };
            const encryptedItemsPrice = encryptData(subtotal, randomKey);
            const encryptedShippingPrice = encryptData(shippingCharge, randomKey);
            const encryptedporterShippingCharge = encryptData(porterShippingCharge, randomKey);
            const encryptedTotalPrice = encryptData(total, randomKey);
            const signature = generateSignature(`${subtotal}${shippingCharge}${total}`, randomKey);
            const encryptedviaOnline = encryptData(remainingAmount, randomKey);
            const encryptedviaWallet = encryptData(walletUsage, randomKey);
            // Encrypt the selected random key with your master encryption key
            const plainText = CryptoJS.AES.encrypt(randomKey, encryptionKey).toString();
            const filteredCartItems = cartItems.filter(item => item.stocks === "Stock");


            const reqdata = {
                shippingInfo: defaultAddress && defaultAddress,
                user,
                user_id: user._id,
                cartItems: filteredCartItems,
                itemsPrice: encryptedItemsPrice,
                taxPrice: 0.0,
                shippingPrice: encryptedShippingPrice,
                porterShippingCharge: encryptedporterShippingCharge,
                totalPrice: encryptedTotalPrice,
                signature,
                plainText,
                OrderedDate,
                orderDescription,
                // viaOnline: remainingAmount,
                // viaWallet: walletUsage,
                viaOnline: encryptedviaOnline,
                viaWallet: encryptedviaWallet,
                paidOnline: 0,
                paidWallet: 0,
            };

            sessionStorage.setItem('orderInfo', JSON.stringify(reqdata));

            try {
                const orderUrl = '/api/v1/payment/wallet/orders';
                const { data } = await axios.post(orderUrl, reqdata, { withCredentials: true });

                if (data && data.payment && data.payment.paymentStatus === 'CHARGED') {
                    const payloadAmount = parseFloat(data.payment.totalPrice).toFixed(2);
                    const totalAmount = parseFloat(walletUsage).toFixed(2);

                    if (payloadAmount === totalAmount) {
                        navigate(`/payment/confirm/${encodeURIComponent(data.encryptedOrderId)}`)
                    } else {
                        toast.dismiss();
                        setTimeout(() => {
                            toast.error('Mismatch initial Amount, possible data tampering detected', {
                                position: 'bottom-center',
                                type: 'error',
                                autoClose: 700,
                                transition: Slide,
                                hideProgressBar: true,
                                className: 'small-toast',
                            });
                        }, 300);
                        setLoading(false);
                    }
                }
                else {
                    toast.dismiss();
                    setTimeout(() => {
                        toast.error('Failed to create order', {
                            position: 'bottom-center',
                            type: 'error',
                            autoClose: 700,
                            transition: Slide,
                            hideProgressBar: true,
                            className: 'small-toast',
                        });
                    }, 300);
                    setLoading(false);
                }
            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.message) {
                    toast.dismiss();
                    setTimeout(() => {
                        toast.error(error && error.response.data.message, {
                            position: 'bottom-center',
                            type: 'error',
                            autoClose: 700,
                            transition: Slide,
                            hideProgressBar: true,
                            className: 'small-toast',
                        });
                    }, 300);
                }
                setLoading(false);
                setShowModal(false);
            }

        }
    };
    useEffect(() => {
        const formatDateToCustomFormat = (OrderedDateUF) => {

            const year = OrderedDateUF.getFullYear();
            const month = String(OrderedDateUF.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            const day = String(OrderedDateUF.getDate()).padStart(2, '0');
            const hours = String(OrderedDateUF.getHours()).padStart(2, '0');
            const minutes = String(OrderedDateUF.getMinutes()).padStart(2, '0');
            const seconds = String(OrderedDateUF.getSeconds()).padStart(2, '0');

            // Create a custom format similar to ISO without altering timezone
            return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+00:00`;
        };
        if (OrderedDateUF) {
            setOrderedDate(formatDateToCustomFormat(OrderedDateUF))
        }
    }, [OrderedDateUF])

    const handelopenModal = (getAnnouncement) => {
        const currentDate = new Date();
        const currentHour = currentDate.getHours();
        let orderDate;

        // Determine initial delivery date
        if (currentHour < 21) {
            orderDate = new Date(currentDate);
            orderDate.setDate(orderDate.getDate() + 1); // Next day
        } else {
            orderDate = new Date(currentDate);
            orderDate.setDate(orderDate.getDate() + 2); // Day after tomorrow
        }

        // Function to reset the time to 00:00:00 for accurate date comparison
        // Function to check if the date falls within a leave period
        // const isWithinLeaveDate = (date) => {
        //     let isLeave = false;
        //     getAnnouncement && getAnnouncement.forEach(({ startDate, endDate, type }) => {
        //         if (type === 'Announcement') {
        //             const leaveStart = resetTime(new Date(startDate));
        //             const leaveEnd = resetTime(new Date(endDate));
        //             const checkDate = resetTime(date); // Reset the time of the check date    
        //             if (checkDate >= leaveStart && checkDate <= leaveEnd) {
        //                 isLeave = true;
        //             }
        //         }


        //     });
        //     return isLeave;
        // };

        // // Check if orderDate is within leave periods and log the result

        // // Adjust delivery date if it falls within leave periods
        // while (isWithinLeaveDate(orderDate)) {

        //     const conflictingLeave = getAnnouncement && getAnnouncement.find(({ startDate, endDate }) => {
        //         const leaveStart = resetTime(new Date(startDate));
        //         const leaveEnd = resetTime(new Date(endDate));
        //         const checkDate = resetTime(orderDate);

        //         return checkDate >= leaveStart && checkDate <= leaveEnd;
        //     });

        //     // if (conflictingLeave) {
        //     //     const leaveEnd = new Date(conflictingLeave.endDate);
        //     //     leaveEnd.setHours(0, 0, 0, 0); // Set leaveEnd time to midnight for consistency
        //     //     // Skip to the day after the conflicting leave's endDate
        //     //     if (orderDate.getDate() === leaveEnd.getDate() && orderDate.getMonth() === leaveEnd.getMonth() && orderDate.getFullYear() === leaveEnd.getFullYear()) {
        //     //         // If the orderDate is the same as leaveEnd, move it to the next day
        //     //         orderDate = new Date(leaveEnd);
        //     //         orderDate.setDate(orderDate.getDate() + 1); // Skip to the day after

        //     //     } else {
        //     //         // Regular case: just skip to the day after leaveEnd
        //     //         orderDate = new Date(leaveEnd);
        //     //         orderDate.setDate(orderDate.getDate() + 1); // Skip to the day after
        //     //     }
        //     // }
        // }
        setOrderedDateUF(orderDate);
        const isWithinLeaveDate = (date) => {
            let leaveMessage = '';
            let isLeave = false;

            getAnnouncement &&
                getAnnouncement.forEach(({ startDate, endDate, type }) => {
                    if (type === 'Announcement') {
                        const leaveStart = resetTime(new Date(startDate));
                        const leaveEnd = resetTime(new Date(endDate));
                        const checkDate = resetTime(date);

                        if (checkDate >= leaveStart && checkDate <= leaveEnd) {
                            isLeave = true;
                            const displayStart = new Date(leaveStart);
                            const displayEnd = new Date(leaveEnd);
                            displayStart.setDate(displayStart.getDate() - 1);
                            displayEnd.setDate(displayEnd.getDate() - 1);

                            leaveMessage = `Orders could not be placed from ${format(displayStart, 'MMM d')} to ${format(
                                displayEnd,
                                'MMM d'
                            )}. We will be available from ${format(leaveEnd, 'MMM d')}.`;
                        }
                    }
                });

            return { isLeave, leaveMessage };
        };

        // Validate if the ordered date falls within any leave date
        const { isLeave, leaveMessage } = isWithinLeaveDate(orderDate);

        if (isLeave) {
            setIsLeaveDay(true);
            setOrderDescription(leaveMessage);
            setShowModal(true);
        } else {
            const formatDate = (date) => {
                const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                const dayName = days[date.getDay()];
                const day = String(date.getDate()).padStart(2, '0');
                const month = months[date.getMonth()];
                const year = date.getFullYear();

                return `${dayName}, ${day}/${month}/${year}`;
            };

            // Set the description with the adjusted date
            setOrderDescription(`The order will be delivered on: ${formatDate(orderDate)}`);
            setShowModal(true);
        }


    };

    const handleCancelModal = () => {
        setShowModal(false);
        setIsLeaveDay(false);
    }

    useEffect(() => {
        if (message) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(message, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
        }
    }, [navigate, message]);

    const capitalizeFirstLetter = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const openModalWithValidation = () => {
        handelopenModal(getAnnouncement && getAnnouncement);
    };
    useEffect(() => {
        const disabled = getAnnouncement && getAnnouncement.map((announcement) => {
            if (announcement.type === 'Announcement') {
                return {
                    start: startOfDay(new Date(announcement.startDate)), // Start of the day for startDate
                    end: endOfDay(new Date(announcement.endDate)), // End of the day for endDate
                };
            }
            return null; // Optional, if you want to filter out non-matching announcements
        }).filter(item => item !== null); // Optional: to remove null values

        setDisabledDates(disabled);
    }, []);


    // Calculate tomorrow's date and 14 days from tomorrow
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // Tomorrow

    const maxDate = addDays(tomorrow, 13); // 14 days from tomorrow

    // Disable dates between announcement's start and end date (inclusive)
    const isDisabledDate = (date) => {
        const currentDate = startOfDay(date); // Strip time for comparison
        return disabledDates.some(({ start, end }) =>
            isWithinInterval(currentDate, { start, end })
        );
    };

    // Separate handler for delivery date changes
    // const handleDeliveryDate = (date) => {
    //     // Adjust the time for 12:00 PM
    //     const adjustedDate = setHours(setMinutes(date, 0), 12);
    //     setDeliveryDate(adjustedDate);
    //     setOrderedDateUF(adjustedDate);
    //     const formattedDate = adjustedDate.toDateString();
    //     console.log("formattedDate",formattedDate)
    //     setOrderDescription(The order will be delivered on: ${formattedDate});

    // };
    // const handleDeliveryDate = (date) => {
    //     // Adjust the time for 12:00 PM
    //     const adjustedDate = setHours(setMinutes(date, 0), 12);
    //     setDeliveryDate(adjustedDate);
    //     setOrderedDateUF(adjustedDate);

    //     // Manually format the date as 'Fri, 13/Dec/2024'
    //     const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    //     const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    //     const dayName = days[adjustedDate.getDay()];
    //     const day = String(adjustedDate.getDate()).padStart(2, '0');
    //     const month = months[adjustedDate.getMonth()];
    //     const year = adjustedDate.getFullYear();

    //     const formattedDate = `${dayName}, ${day}/${month}/${year}`;
    //     console.log("formattedDate", formattedDate);

    //     setOrderDescription(`The order will be delivered on: ${formattedDate}`);
    // };


    return (
        <Fragment>
            <Fragment>
                <MetaData
                    title="Confirm Your Order"
                    description="Verify your order details before finalizing your purchase. Review your selected items, shipping information, and total cost before confirming."
                />

                <div className="products_heading">Confirm Order</div>
                <div className=''>
                    <span className="back-button" onClick={() => navigate(-1)}>
                        <ArrowBackIcon fontSize="small" />
                        <span>Back</span>
                    </span>
                </div>
                <StepsCheckOut shipping confirmOrder />
                <div className="container confirm-order-container">

                    <div className="row justify-content-center">
                        <div className="col-10 col-md-10 col-lg-8 order-confirm" id='order_summary'>
                            <h4 className="mb-3 confirmorder_title">Shipping Info</h4>
                            <div className="confirmorder_name"><b>Name:</b> {defaultAddress && defaultAddress.name}</div>
                            <div className="confirmorder_phone"><b>Phone:</b> {defaultAddress && defaultAddress.phoneNo}</div>
                            {
                                defaultAddress && (
                                    <div className="mb-4 address-formatted confirmorder_address">
                                        <b>Address:</b>
                                        {defaultAddress.address && `${defaultAddress.address},`}
                                        {defaultAddress.area && `${defaultAddress.area},`}
                                        {defaultAddress.landmark && `${defaultAddress.landmark},`}
                                        {defaultAddress.city && `${defaultAddress.city}`}
                                        {defaultAddress.postalCode && `-${defaultAddress.postalCode}`}
                                    </div>
                                )
                            }

                            <hr />
                            <h4 className="mt-4 confirmorder_title">Your Cart Items:</h4>
                            <hr />
                            {cartItems && cartItems.filter(item => item.stocks === "Stock").map(item => (
                                <Fragment key={item.product}>
                                    <div className="cart-item my-1">
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                            <div className="col-4 col-lg-2">
                                                <img src={item.image} alt={item.name} height="45" width="65" />
                                            </div>
                                            <div className="col-3 col-lg-4 confirmorder_name">
                                                {item && item.range
                                                    ? `${capitalizeFirstLetter(item.name)} (${item.range})`
                                                    : capitalizeFirstLetter(item.name)}
                                            </div>
                                            {/* <div className="col-3 col-lg-3 confirmorder_price">
                                                <div style={{ textDecoration: 'line-through', color: 'red',fontSize:'14px' }}>{item.productWeight} x Rs.{item.finalPrice} = <b>Rs.{(item.productWeight * item.finalPrice).toFixed(2)}</b></div>
                                            </div> */}
                                            <div className="col-5 col-lg-4 confirmorder_price">
                                                <div >{item.productWeight} x {item.offer > 0 && <b style={{ textDecoration: 'line-through', color: 'red', fontSize: '14px', whiteSpace: 'nowrap' }}>₹ {item.price}</b>} ₹ {item.finalPrice} = <b>₹ {(item.productWeight * item.finalPrice).toFixed(2)}</b></div>
                                            </div>

                                        </div>
                                    </div>
                                    <hr />
                                </Fragment>
                            ))}
                        </div>
                        <div className="col-10 col-md-10  col-lg-3 my-4">
                            <div id="order_summary" style={{ display: 'flex', flexDirection: 'column' }}>
                                <h4 className="confirmorder_title">Order Summary</h4>
                                <hr />
                                <div>
                                    <p className="confirmorder_name">Subtotal: <span className="order-summary-values">₹ {subtotal}</span></p>
                                    <p className="confirmorder_name">Shipping: <span className="order-summary-values">₹ {shippingCharge && shippingCharge.toFixed(2)}</span></p>
                                    {/* <p style={{ marginBottom: '30px' }}>
                                            Shipping:
                                            {subtotal >= 500 && subtotal < 999 ? (
                                                <div style={{ display: 'flex', flexDirection: 'column', float: 'right' }}>
                                                    <span
                                                        className="order-summary-values"
                                                        style={{ textDecoration: 'line-through', color: 'red', fontSize: '14px' }}
                                                    >
                                                        ₹{shippingCharge && shippingCharge.toFixed(2)}
                                                    </span>
                                                    <span className="order-summary-values">
                                                        ₹{(shippingCharge / 2).toFixed(2)}
                                                    </span>
                                                </div>
                                            ) : subtotal >= 1000 ? (
                                                <div style={{ display: 'flex', flexDirection: 'column', float: 'right' }}>
                                                    <span
                                                        className="order-summary-values"
                                                        style={{ textDecoration: 'line-through', color: 'red', fontSize: '14px' }}
                                                    >
                                                        ₹{shippingCharge && shippingCharge.toFixed(2)}
                                                    </span>
                                                    <span className="order-summary-values">₹0.00</span>
                                                </div>
                                            ) : (
                                                <span className="order-summary-values">₹{shippingCharge && shippingCharge.toFixed(2)}</span>
                                            )}
                                        </p> */}
                                    <hr />
                                </div>

                                <div >
                                    <p className="confirmorder_name">Total:
                                        <div style={{ display: 'flex', flexDirection: 'column', float: 'right' }} className="order-summary-values">
                                            {/* {isReducedCost > 0 && ( */}
                                            <div style={{ float: 'right', display: 'flex', flexDirection: 'column' }}>
                                                {isReducedCost > 0 && (
                                                    <span className="order-summary-values" style={{ float: 'left', textDecoration: 'line-through', color: 'red' }}>₹ {originalTotal}</span>
                                                )

                                                }
                                                <span className="order-summary-values" style={{ color: 'green' }}>₹{total}</span>

                                            </div>

                                        </div>
                                    </p>
                                    <p className="confirmorder_name" style={{ fontSize: '0.8rem', color: '#6c757d' }}>
                                        (Inclusive of all taxes)
                                    </p>
                                    <hr />
                                </div>

                                {/* {user.walletBalance > 0 && (
                                    <div style={{ marginTop: '5px' }}>
                                        <label style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="checkbox" // Changed from radio to checkbox
                                                checked={useWallet}
                                                onChange={handleWalletToggle}
                                                style={{ marginRight: '8px' }}
                                            />
                                            Use Wallet
                                        </label>
                                        {useWallet ? (
                                            <></>
                                        ) : (
                                            <hr />
                                        )}

                                    </div>
                                )} */}

                                {/* {useWallet && (
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                                        <div style={{ marginTop: '3px' }}>
                                            <p className="confirmorder_name">Balance:
                                                <span className="order-summary-values">Rs.{parseFloat(user.walletBalance).toFixed(2)}</span>
                                            </p>
                                        </div>
                                        <div style={{ marginTop: '3px' }}>
                                            <p className="confirmorder_name" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                Use:
                                                <span className="order-summary-values">
                                                    <NumberInput
                                                        type="number"
                                                        id="walletUsage"
                                                        value={walletUsage > 0 ? walletUsage : ''}
                                                        placeholder={walletUsage > 0 ? '' : 0}
                                                        onChange={handleWalletUsageChange}
                                                        max={Math.min(user.walletBalance || 0, total)}
                                                        style={{ width: '100px', marginLeft: '10px' }}
                                                    />
                                                </span>
                                            </p>
                                        </div>
                                        <div style={{ marginTop: '3px' }}>
                                            <p className="confirmorder_name">
                                                Pay Online: <span className="order-summary-values">Rs.{remainingAmount.toFixed(2)}</span>
                                            </p>
                                            <hr />
                                        </div>
                                    </div>
                                )} */}
                                {WalletBalance > 0 && (
                                    <div id="wallet_container_large" className="wallet-container-modal ">
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={useWallet}
                                                onChange={handleWalletToggle}
                                            />
                                            Use Wallet
                                        </label>
                                        {useWallet && (
                                            <div className="wallet-details">
                                                <p>Balance: <span className="order-summary-values">₹ {parseFloat(WalletBalance).toFixed(2)}</span></p>

                                                <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    Use:
                                                    <span className="order-summary-values">
                                                        <NumberInput
                                                            type="number"
                                                            id="walletUsage"
                                                            value={walletUsage > 0 ? walletUsage : ''}
                                                            placeholder={walletUsage > 0 ? '' : 0}
                                                            onChange={handleWalletUsageChange}
                                                            max={Math.min(user.walletBalance || 0, total)}
                                                            step="0.01"
                                                        />
                                                    </span>
                                                </p>

                                                <p className="pay-online">
                                                    Pay Online: <span>₹ {remainingAmount.toFixed(2)}</span>
                                                </p>
                                                <hr />
                                            </div>
                                        )}
                                        {/* {useWallet ? null : <hr />} */}
                                    </div>
                                )}


                                {/* <hr /> */}
                                {porterShippingCharge ? (
                                    <button id="proceed_btn" className="btn btn-block" onClick={openModalWithValidation} disabled={loading}>
                                        {loading ? <LoaderButton fullPage={false} size={20} /> : (
                                            <span>  Proceed to Payment</span>
                                        )
                                        }
                                    </button>
                                ) : (
                                    <button id="proceed_btn" className="btn btn-block" disabled>
                                        Proceed to Payment
                                    </button>
                                )}

                                {(WalletBalance > 0 || porterShippingCharge) && (
                                    <div id="proceed_btn_container" className={useWallet ? "expanded" : ""}>

                                        {/* Use Wallet checkbox */}
                                        <div id="wallet_fixed_container" onClick={handleWalletToggle}>
                                            <label>
                                                <input type="checkbox" checked={useWallet} readOnly />
                                                Use Wallet
                                            </label>
                                        </div>

                                        {/* Wallet Details (Appears inside proceed container) */}
                                        {useWallet && (
                                            <div className="wallet-container-modal show">
                                                <p>Balance: ₹ {parseFloat(WalletBalance).toFixed(2)}</p>
                                                <p>
                                                    Use:
                                                    <NumberInput
                                                        type="number"
                                                        id="walletUsage"
                                                        value={walletUsage > 0 ? walletUsage : ''}
                                                        placeholder={walletUsage > 0 ? '' : 0}
                                                        onChange={handleWalletUsageChange}
                                                        max={Math.min(WalletBalance || 0, total)}
                                                        step="0.01"
                                                    />
                                                </p>
                                                <p className="pay-online">
                                                    Pay Online: ₹ {remainingAmount.toFixed(2)}
                                                </p>
                                            </div>
                                        )}

                                        {/* Proceed Button */}
                                        {porterShippingCharge && (
                                            <button id="proceed_btn_fixed" className="btn btn-block" onClick={openModalWithValidation} disabled={loading}>
                                                {/* {loading ? "Loading..." : "Proceed to Payment"} */}
                                                {loading ? <LoaderButton fullPage={false} size={20} /> : (
                                                    <span>  Proceed to Payment <strong>₹ {total}</strong></span>
                                                )
                                                }
                                            </button>
                                        )}
                                    </div>
                                )}
                                {/* ✅ Wallet Modal (Only for Small Screens) */}
                                {/* {useWallet && window.innerWidth <= 900 && (
                                    <div className="wallet-modal small-screen" role="dialog">
                                        <div className="wallet-modal-content">
                                            <h5>Use Wallet</h5>
                                            <p>Balance: ₹ {parseFloat(WalletBalance).toFixed(2)}</p>
                                            <p>
                                                Use:
                                                <NumberInput
                                                    type="number"
                                                    id="walletUsage"
                                                    value={walletUsage > 0 ? walletUsage : ''}
                                                    placeholder={walletUsage > 0 ? '' : 0}
                                                    onChange={handleWalletUsageChange}
                                                    max={Math.min(WalletBalance || 0, total)}
                                                    step="0.01"
                                                />
                                            </p>
                                            <p className="pay-online">
                                                Pay Online: ₹ {remainingAmount.toFixed(2)}
                                            </p>
                                            <button className="btn btn-secondary" onClick={() => handleWalletToggle(false)}>Close</button>
                                        </div>
                                    </div>
                                )} */}


                                {showModal && (
                                    <div className="modal" tabIndex="-1" role="dialog" style={modalStyle}>
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Confirm Order</h5>
                                                    {
                                                        !loading ? (
                                                            <button type="button" className="close" onClick={handleCancelModal} disabled={loading}>
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        ) : <></>
                                                    }

                                                </div>
                                                {/* <div className="modal-body">
                                                    <p>{orderDescription && orderDescription}</p>
                                                    <div style={{ display: 'flex', flexDirection: 'row', marginRight: '20px' }}>
                                                        <label htmlFor="deliveryDate" style={{ marginRight: '10px' }}> Select Delivery Date:</label>
                                                        <DatePicker
                                                            selected={deliveryDate}
                                                            onChange={handleDeliveryDate}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="form-control date-input"
                                                            placeholderText="dd/mm/yyyy"
                                                            minDate={tomorrow}
                                                            maxDate={maxDate}
                                                            filterDate={(date) => !isDisabledDate(date)}

                                                        />
                                                    </div>
                                                </div> */}

                                                <div className="modal-body">
                                                    {
                                                        isLeaveDay ? (
                                                            <p style={{ color: 'red' }}>{orderDescription && orderDescription}</p>
                                                        ) : (
                                                            <p>{orderDescription && orderDescription}</p>
                                                        )
                                                    }

                                                    {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '20px' }}>
                                                        <label htmlFor="deliveryDate" style={{ marginRight: '10px' }}>Select Delivery Date:</label>
                                                        <DatePicker
                                                            selected={deliveryDate}
                                                            onChange={handleDeliveryDate}
                                                            dateFormat="dd/MM/yyyy"
                                                            minDate={tomorrow}
                                                            maxDate={maxDate}
                                                            filterDate={(date) => !isDisabledDate(date)}
                                                            customInput={
                                                                <span
                                                                    style={{
                                                                        fontSize: '24px',
                                                                        cursor: 'pointer',
                                                                        display: 'inline-block'
                                                                    }}
                                                                >
                                                                    📅
                                                                </span>
                                                            }
                                                        />
                                                    </div> */}
                                                </div>

                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" onClick={handleCancelModal} disabled={loading}>Cancel</button>
                                                    <button type="button" className="btn btn-success" onClick={processPayment} disabled={loading || isLeaveDay} style={{ cursor: (loading || isLeaveDay) ? 'not-allowed' : 'pointer' }}>
                                                        {loading ? <LoaderButton fullPage={false} size={20} /> : (
                                                            <span>  Continue</span>
                                                        )

                                                        }

                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                    {/* )} */}
                </div>
            </Fragment>
        </Fragment>

    );
};
const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)'
};

export default ConfirmOrder;