
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Slide, toast } from 'react-toastify';
import { addCartItem, addToCart } from '../../actions/cartActions';
import NumberInput from '../Layouts/NumberInput';
import { useLocation } from 'react-router-dom';
import WeightInput from '../Layouts/WeightInput';
import { clearAddCart } from '../../slices/cartSlice';
const Product = ({ products, onLoginClick }) => {
    const [weight, setWeight] = useState({});
    const [quantity, setQuantity] = useState(1);
    const dispatch = useDispatch();
    const location = useLocation();
    const [weightvalue, setweightvalue] = useState(false);
    const [weighttoast, setWeightToast] = useState(false);
    const [correctWeight, setcorrectWeight] = useState(false);
    const { isAuthenticated, loading, user } = useSelector(state => state.authState);
    const { addLoading, addResponse, addError, items } = useSelector(state => state.cartState);
    

    useEffect(() => {
        const initialWeights = products && products.reduce((acc, product) => {
            acc[product._id] = ''; // Set each product's weight as an empty string
            return acc;
        }, {});
        setWeight(initialWeights);
    }, [products]);

    useEffect(() => {
        if (addResponse) {
            toast.dismiss();
            setTimeout(() => {
                toast.success(addResponse, {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearAddCart()) }
                });
            }, 300);
        }
        if (addError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(addError, {
                    position: 'top-right',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast-input',
                    onOpen: () => { dispatch(clearAddCart()) }
                });
                setweightvalue(false);
            }, 300);


        }

    }, [addResponse, addError])

    // const handleWeightChange = (
    //     productId,
    //     value,
    //     productCategory,
    //     productMeasurement,
    //     maximumQuantity
    // ) => {
    //     let validValue;
    //     if (productMeasurement === "Kg") {
    //         // Allow leading zero, optional decimal, and up to two decimal places
    //         validValue = value.match(/^(0|[1-9]\d*)?(\.\d{0,2})?$/) ? value : weight[productId];
    //     } else {
    //         // Only whole numbers allowed
    //         validValue = value.match(/^\d*$/) ? value : weight[productId];
    //     }

    //     // Allow empty value for resetting
    //     if (value === "") {
    //         setWeight((prevWeights) => ({ ...prevWeights, [productId]: "" }));
    //         return;
    //     }

    //     const weightValue = parseFloat(validValue);

    //     // Prevent invalid values
    //     if (isNaN(weightValue) || weightValue < 0) {
    //         return;
    //     }

    //     if (productMeasurement) {
    //         if (weightValue > maximumQuantity) {
    //             setweightvalue(true);
    //             toast.dismiss();
    //             if (!weightvalue) {
    //                 setTimeout(() => {
    //                     toast.error(`Cannot exceed ${maximumQuantity} ${productMeasurement}`, {
    //                         position: 'top-right',
    //                         type: 'error',
    //                         autoClose: 700,
    //                         transition: Slide,
    //                         hideProgressBar: true,
    //                         className: 'small-toast-input',
    //                     });
    //                     setweightvalue(false);
    //                 }, 300);
    //             }

    //             setWeight((prevWeights) => ({ ...prevWeights, [productId]: "" }));
    //             return;
    //         }
    //     }

    //     setWeight((prevWeights) => ({ ...prevWeights, [productId]: validValue }));
    // };

    const handleWeightChange = (
        productId,
        value,
        productCategory,
        productMeasurement,
        maximumQuantity,
        minimumQuantity
    ) => {
        let validValue;
        if (productMeasurement === "Kg") {
            // Allow leading zero, optional decimal, and up to two decimal places
            validValue = value.match(/^(0|[1-9]\d*)?(\.\d{0,2})?$/) ? value : weight[productId];
        } else {
            // Only whole numbers allowed
            validValue = value.match(/^\d*$/) ? value : weight[productId];
        }

        // Allow empty value for resetting
        if (value === "") {
            setWeight((prevWeights) => ({ ...prevWeights, [productId]: "" }));
            return;
        }

        const weightValue = parseFloat(validValue);

        // Prevent invalid values
        if (isNaN(weightValue) || weightValue < 0) {
            return;
        }

        if (productMeasurement) {
            if (weightValue < minimumQuantity) {
                setweightvalue(true);
                toast.dismiss();
                if (!weightvalue) {
                    setTimeout(() => {
                        toast.error(`Cannot less than ${minimumQuantity} ${productMeasurement}`, {
                            position: 'top-right',
                            type: 'error',
                            autoClose: 700,
                            transition: Slide,
                            hideProgressBar: true,
                            className: 'small-toast-input',
                        });
                        setweightvalue(false);
                    }, 300);
                }

                setWeight((prevWeights) => ({ ...prevWeights, [productId]: "" }));
                return;
            }
        }

        if (productMeasurement) {
            if (weightValue > maximumQuantity) {
                setweightvalue(true);
                toast.dismiss();
                if (!weightvalue) {
                    setTimeout(() => {
                        toast.error(`Cannot exceed ${maximumQuantity} ${productMeasurement}`, {
                            position: 'top-right',
                            type: 'error',
                            autoClose: 700,
                            transition: Slide,
                            hideProgressBar: true,
                            className: 'small-toast-input',
                        });
                        setweightvalue(false);
                    }, 300);
                }

                setWeight((prevWeights) => ({ ...prevWeights, [productId]: "" }));
                return;
            }
        }

        setWeight((prevWeights) => ({ ...prevWeights, [productId]: validValue }));
    };

    const calculateRate = (price, weight) => {
        return (price * weight).toFixed(2);
    };



    const handleAddToCart = (product, productId) => {
        const productWeight = weight[product._id];

        if (!user|| !isAuthenticated) {
            // toast.dismiss();
            // setTimeout(() => {
            //     toast.error('Please login to add items to cart!', {
            //         position: 'top-right',
            //         type: 'error',
            //         autoClose: 700,
            //         transition: Slide,
            //         hideProgressBar: true,
            //         className: 'small-toast-input',
            //     });
            //     setcorrectWeight(false);
            // }, 300);
            // return
            onLoginClick();
            
        }
        else {

            if (product && product.measurement) {
                // For Keerai, bundle validation
                if (!isNaN(productWeight) && productWeight <= 0) {
                    setWeightToast(true);
                    setWeight(prevWeights => ({ ...prevWeights, [productId]: '' }));
                    if (!weighttoast) {
                        toast.dismiss();
                        setTimeout(() => {
                            if (product.measurement) {
                                toast.error(product.measurement === 'Kg' ? `Please select atleast  ${product.minimumQuantity} ${product.measurement}` : ` Please select atleast 1 ${product.measurement}`, {
                                    position: 'bottom-center',
                                    type: 'error',
                                    autoClose: 700,
                                    transition: Slide,
                                    hideProgressBar: true,
                                    className: 'small-toast',
                                });
                            }
                            setWeightToast(false);
                        }, 300);
                    }
                    return;
                }

                // Adding items to cart except kg
                if (product.measurement !== 'kg' && productWeight >= product.minimumQuantity && productWeight <= product.maximumQuantity) {
                    // dispatch(addCartItem({ productId: product._id, quantity, productWeight }));
                    dispatch(addToCart({ userId: user?._id, productId: product._id, quantity, productWeight }));
                    // toast.dismiss();
                    // setTimeout(() => {
                    //     toast.success('Item added to cart successfully!', {
                    //         position: 'bottom-center',
                    //         type: 'success',
                    //         autoClose: 700,
                    //         transition: Slide,
                    //         hideProgressBar: true,
                    //         className: 'small-toast',
                    //     });
                    // }, 300);
                    setQuantity(1);
                    setWeight(prevWeights => ({ ...prevWeights, [product._id]: '' }));
                    return;
                }


            }

            // For non-Keerai products


            if (product.measurement === 'Kg' && productWeight >= product.minimumQuantity) {
                // dispatch(addCartItem({ productId: product._id, quantity, productWeight }));
                dispatch(addToCart({ userId: user?._id, productId: product._id, quantity, productWeight }));
                // toast.dismiss();
                // setTimeout(() => {
                //     toast.success('Item added to cart successfully!', {
                //         position: 'bottom-center',
                //         type: 'success',
                //         autoClose: 700,
                //         transition: Slide,
                //         hideProgressBar: true,
                //         className: 'small-toast',
                //     });
                // }, 300);
                setQuantity(1);
                setWeight(prevWeights => ({ ...prevWeights, [product._id]: '' }));
                return;
            } else {
                setcorrectWeight(true);
                if (!correctWeight) {
                    toast.dismiss();
                    setTimeout(() => {
                        toast.error(`Weight should be atleast ${product.minimumQuantity} ${product.measurement}!`, {
                            position: 'bottom-center',
                            type: 'error',
                            autoClose: 700,
                            transition: Slide,
                            hideProgressBar: true,
                            className: 'small-toast',
                        });
                        setcorrectWeight(false);
                    }, 300);
                }
                return;
            }
        }

    };

    const capitalizeFirstLetter = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <div className="container mt-2  table-product">
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Products Image</th>
                            <th>Products Name</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Rate (As Per Weight)</th>
                            <th>Stock</th>
                            <th>Add to Cart</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products && products.map((product, index) => (
                            <tr key={product._id}>
                                <td className="serial-number">{index + 1}</td>
                                <td className="products-image" >
                                    {product.offer > 0 && product.finalPrice > 0 && (
                                        <div style={{
                                            position: 'relative',
                                            width: '40px',
                                            height: '60px',
                                            backgroundColor: 'green',
                                            color: 'white',
                                            fontSize: '12px',
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            lineHeight: '15px',
                                            borderRadius: '0px',
                                            clipPath: 'polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0% 85%)', // Curved arrow shape
                                            overflow: 'hidden',
                                        }}>
                                            {/* Coin Icons */}
                                            <div style={{
                                                position: 'absolute',
                                                top: '-10px',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                display: 'flex',
                                                gap: '2px',
                                            }}>
                                                <div style={{
                                                    width: '15px',
                                                    height: '15px',
                                                    backgroundColor: 'gold',
                                                    borderRadius: '50%',
                                                }}></div>
                                                <div style={{
                                                    width: '15px',
                                                    height: '15px',
                                                    backgroundColor: 'gold',
                                                    borderRadius: '50%',
                                                }}></div>
                                                <div style={{
                                                    width: '15px',
                                                    height: '15px',
                                                    backgroundColor: 'gold',
                                                    borderRadius: '50%',
                                                }}></div>
                                            </div>

                                            {/* Best Offer Text */}
                                            <div style={{
                                                position: 'absolute',
                                                top: '10px',
                                                width: '100%',
                                            }}>
                                                <div style={{ fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center', textTransform: 'uppercase' }}>OFFER</div>
                                                {/* <span style={{ fontSize: '8px', display: 'block', textTransform: 'uppercase' }}>OFFER</span> */}
                                                <div style={{
                                                    position: 'relative',
                                                    bottom: '5px',
                                                    marginTop: '10px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    fontSize: '12px',
                                                    animation: 'scrollDown 2s linear infinite',
                                                }}>
                                                    {product.offer}%
                                                </div>
                                            </div>

                                            {/* Percentage Off */}

                                        </div>


                                    )}

                                    {product.images[0] && product.images[0].image && (
                                        <img
                                            className="img-size"
                                            style={{
                                                minHeight: '80px', minWidth: '100px', opacity: 1,
                                                transition: 'opacity 0.3s ease',
                                            }}
                                            src={product?.images[0]?.image}
                                            alt={capitalizeFirstLetter(product.englishName)}
                                        />
                                    )}
                                </td>

                                <td className="Products Name" style={{ fontSize: '17px' }}>
                                    {product && product.range
                                        ? `${capitalizeFirstLetter(product.englishName)}/${capitalizeFirstLetter(product.tamilName)} (${product.range})`
                                        : `${capitalizeFirstLetter(product.englishName)}/${capitalizeFirstLetter(product.tamilName)}`
                                    }
                                </td>
                                <td className="Price" style={{ whiteSpace: 'nowrap' }}>
                                    {
                                        product?.offer > 0 && product.finalPrice > 0 ? (
                                            <>
                                                <div style={{ textDecoration: 'line-through', color: 'red' }}>
                                                ₹ {product.price} (per {product?.measurement})
                                                </div>
                                                <div>
                                                ₹ {product.finalPrice} (per {product?.measurement})
                                                </div>

                                            </>
                                        ) : (
                                            <>
                                                ₹ {product.finalPrice} (per {product?.measurement})
                                            </>
                                        )
                                    }
                                    {/* Rs.{product.price}(per{" "} {product && product.measurement}
                                    ) */}
                                </td>

                                <WeightInput
                                    product={product}
                                    weight={weight}
                                    handleWeightChange={handleWeightChange}
                                />
                                <td className="Rate (As Per Weight)">
                                    {weight[product._id] ? `₹ ${calculateRate(product.finalPrice, weight[product._id])}` : '₹ 0.00'}
                                </td>
                                <td className="Stock"
                                    style={{
                                        color: product.stocks === 'Stock' ? 'green' : 'red'
                                    }}
                                >{product.stocks}</td>
                                <td className="Add to Cart">

                                    <button
                                        className={product.stocks === 'Stock' ? "btn-add" : "btn-add-no"}
                                        onClick={() => handleAddToCart(product)}
                                        // onClick={() => {
                                        //     if (!user || !isAuthenticated) {
                                        //       onLoginClick(); // Trigger the login modal if user is not logged in
                                        //     } else {
                                        //       handleAddToCart(product); // Add to cart if user is logged in
                                        //     }
                                        //   }}
                                        style={{
                                            backgroundColor: product.stocks === 'Stock' ? "#02441E" : "",
                                            color: "white",
                                            borderRadius: "40px",
                                            cursor: product.stocks === 'Stock' ? 'pointer' : 'not-allowed',
                                            padding: '7px',
                                        }}
                                        disabled={product.stocks === 'No Stock'}
                                        
                                    >
                                        Add
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Product;