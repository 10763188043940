import React, { useEffect, useRef, useState } from "react";
import NumberInput from "./NumberInput"; // Assuming you have a NumberInput component

const WeightInput = ({ product, weight, handleWeightChange }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1); // Tracks the focused option index
  const dropdownRef = useRef(null);
  // console.log("product", product)

  // Generate dropdown options based on the product's measurement
  // const options =
  //   product.measurement === "Kg"
  //     ? [...Array(6).keys()].map((i) => ((i + 1) * 0.5).toString())
  //     : [...Array(3).keys()].map((i) => (i + 1).toString());

  const minQty = Number(product.minimumQuantity) || 0.25; // Ensure valid number
  const maxQty = Number(product.maximumQuantity) || 5;   // Ensure valid max quantity
  
  // Ensure maxQty is greater than minQty to avoid errors
  if (maxQty < minQty) {
      console.error("Invalid quantity range:", { minQty, maxQty });
  }
  
  // Generate options based on measurement type
  const options =
    product.measurement === "Kg"
      ? minQty <= 0.25
        ? Array.from({ length: 6 }, (_, i) => (0.5 + i * 0.5).toFixed(2)) // Start at 0.5, increase by 0.5, length 6
        : Array.from({ length: 5 }, (_, i) => (minQty + i * 0.5).toFixed(2)) // Start at minQty, increase by 0.5, length 5
      : Array.from({ length: 3 }, (_, i) => (minQty + i).toString()); // Other measurements, increase by 1, length 3


  const handleKeyDown = (e) => {
    if (!showDropdown) return;

    if (e.key === "ArrowDown") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex < options.length - 1 ? prevIndex + 1 : 0
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : options.length - 1
      );
    } else if (e.key === "Enter" && focusedIndex !== -1) {
      handleWeightChange(
        product && product._id,
        options[focusedIndex],
        product && product.category,
        product && product.measurement,
        product && product.maximumQuantity
      );
      setShowDropdown(false);
    } else if (e.key === "Escape") {
      setShowDropdown(false);
    }
  };

  const handleOptionClick = (option) => {
    handleWeightChange(
      product && product._id,
      option,
      product && product.category,
      product && product.measurement,
      product && product.maximumQuantity
    );
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowDropdown(true);
    };

    if (showDropdown) {
      window.addEventListener("scroll", handleScroll);
    }

    // Clean up the event listener when component unmounts or dropdown closes
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showDropdown]);

  return (
    <td className="Weight" style={{ position: "relative" }}>
      <NumberInput
        value={weight[product._id] || ""}
        style={{ marginBottom: '5px', textAlign: 'center' }}
        onChange={(e) =>
          handleWeightChange(
            product && product._id,
            e.target.value,
            product && product.category,
            product && product.measurement,
            product && product.maximumQuantity
          )
        }
        onFocus={() => setShowDropdown(true)}
        onClick={() => setShowDropdown(true)}
        // onBlur={() => setTimeout(() => setShowDropdown(false), 150)}
        onBlur={(e) => {
          // Check if blur is happening due to clicking inside the dropdown
          if (dropdownRef.current?.contains(e.relatedTarget)) {
            return;
          }
          setShowDropdown(false); // Close only if focus moves outside
        }}
        onKeyDown={handleKeyDown}
        className="form-select no-arrow-input form-control custom-placeholder"
        placeholder={`Select/type`}
        min="0.25"
        type="number"

      />

      {showDropdown && (
        <div>
          <ul
            ref={dropdownRef}
            style={{
              position: "absolute",
              maxWidth: '100px',
              minWidth: '100px',
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#fff",
              zIndex: 10,
              listStyle: "none",
              margin: 0,
              padding: 0,
              borderLeft: "solid transparent",
              borderRight: "solid transparent",
              borderBottom: "solid #fff",
              boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",
              borderTop: "1px solid transparent", // Changed to borderTop for upward arrow
              height: 'auto',
              overflowY: "auto",

            }}
          >

            {options.map((option, index) => (
              <li
                key={option}
                style={{
                  padding: "10px 14px",
                  cursor: "pointer",
                  margin: '3px',
                  borderRadius: '5px',
                  fontSize: '13px',
                  backgroundColor: focusedIndex === index ? "#e9ecef" : "#fff",
                  color: focusedIndex === index ? "#495057" : "#000",
                  transition: "background-color 0.2s, color 0.2s",
                }}
                onMouseDown={(e) => e.preventDefault()}
                onMouseEnter={() => setFocusedIndex(index)}
                onClick={() => handleOptionClick(option)}
              >
                {option} {product && product.measurement}
              </li>
            ))}
          </ul>
        </div>
      )}
    </td>
  );
};

export default WeightInput;