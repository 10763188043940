import React, { useEffect, Fragment, useState, useMemo } from 'react';
import { Button, Container, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { deleteProduct, getAdminProducts, updateProductList, } from '../../actions/productsActions';
import Loader from '../Layouts/Loader';
import { MDBDataTable } from 'mdbreact';
import { Slide, toast } from 'react-toastify';
import Sidebar from "../admin/Sidebar";
import { clearError } from '../../slices/productsSlice';
import { clearProductDeleted, clearProductState,clearError as clearProductStateError } from "../../slices/productSlice";
import MetaData from '../Layouts/MetaData';
import LoaderButton from '../Layouts/LoaderButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSearchParams } from "react-router-dom";
import { getMeasurements } from '../../actions/measurementActions';
import NumberInput from '../Layouts/NumberInput';


const ProductList = ({ isActive, setIsActive }) => {
    const { products, loading, deleteloading, error } = useSelector(state => state.productsState);
    const { isProductDeleted, error: productError, loadingUpdate, productUpdateList, errorUpdate } = useSelector(state => state.productState);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [category, setCategory] = useState(searchParams.get("category") || "");
    const { getcategory } = useSelector(state => state.categoryState)
    const [measurement, setMeasurement] = useState(searchParams.get("measurement") || "");
    const { getmeasurement } = useSelector(state => state.measurementState); // Assuming measurement data is in Redux
    const [isEditing, setIsEditing] = useState(false);
    const [editedProducts, setEditedProducts] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        if (!getmeasurement) {
            dispatch(getMeasurements())
        }
    }, [getmeasurement])

    useEffect(() => {
        setEditedProducts(
            products
                ?.filter((product) =>
                    category ? product.category === category : true
                )
                ?.filter((product) =>
                    measurement ? product.measurement === measurement : true
                )
                ?.map((product) => ({
                    ...product,
                    tempBuyingPrice: product.buyingPrice,
                    tempSellingPrice: product.price,
                }))
        );
    }, [products, category, measurement]);


    const toggleEditMode = () => {
        setIsEditing((prev) => !prev);
        setSelectedItems([]);
    };

    const handlePriceChange = (id, value) => {
        setEditedProducts((prevProducts) => {
            return prevProducts.map((product) => {
                if (product._id === id) {
                    const originalProduct = products.find(p => p._id === id);
                    const percentage = originalProduct?.percentage || 0; // Default to 0 if missing

                    // If value is empty or invalid, set selling price to 0
                    const buyingPrice = parseFloat(value);
                    const sellingPrice = isNaN(buyingPrice) ? 0 : (buyingPrice * (1 + percentage / 100)).toFixed(2);


                    return {
                        ...product,
                        tempBuyingPrice: value,
                        tempSellingPrice: sellingPrice,
                    };
                }
                return product;
            });
        });
    };

    const handleUpdateProducts = async () => {
        const updatedProducts = editedProducts.filter(product => selectedItems.includes(product._id));

        if (updatedProducts.length === 0) {
            toast.dismiss();
            setTimeout(() => {
                toast.error("No products selected for update!", {
                    position: "bottom-center",
                    type: "error",
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: "small-toast",
                });
            }, 300);
            return;
        }
        try {
            dispatch(updateProductList({ updatedProducts }))


        } catch {
            toast.dismiss();
            setTimeout(() => {
                toast.error("Failed to update products!", {
                    position: "bottom-center",
                    type: "error",
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: "small-toast",
                    onOpen: () => { dispatch(clearProductState()) }
                });
            }, 300);
        }
    };

    useEffect(()=>{
        if(productUpdateList){
            toast.dismiss();
                setTimeout(() => {
                    toast.success(productUpdateList, {
                        position: "bottom-center",
                        type: "success",
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: "small-toast",
                        onOpen: () => { 
                            dispatch(clearProductState());
                            setIsEditing(false);
                            setEditedProducts([]);
                            setSelectedItems([]);

                        }
                    });
    
                    // Fetch updated product list after a short delay
                    setTimeout(() => {
                        dispatch(getAdminProducts());
                    }, 700);
                }, 300);
        }
        if(errorUpdate){
            toast.dismiss();
                setTimeout(() => {
                    toast.error(errorUpdate, {
                        position: "bottom-center",
                        type: "error",
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: "small-toast",
                        onOpen: () => { dispatch(clearProductState()) }
                    });
                }, 300);
        }

    },[productUpdateList, errorUpdate ])




    const handleCheckboxChange = (id) => {
        setSelectedItems((prev) =>
            prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
        );
    };

    const handleSelectAll = () => {
        setSelectedItems(
            selectedItems.length === editedProducts.length ? [] : editedProducts.map((p) => p._id)
        );
    };

    // Function to handle search input change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filter products based on search query

    const filteredProducts = useMemo(() =>
        editedProducts
            .filter(product =>
                product.englishName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                product.tamilName.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .sort((a, b) => a.englishName.localeCompare(b.englishName)), // Sort by Name (Ascending)
        [editedProducts, searchQuery]
    );

    const renderNormalTable = () => (
        <div>
            <input
                type="text"
                placeholder="Search product..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="normaltable-search mb-3"
                style={{ width: "300px" }}
            />
            <table className="table table-bordered productEdit-table">
                <thead>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                onChange={handleSelectAll}
                                checked={selectedItems.length === editedProducts.length && editedProducts.length > 0}
                            />
                        </th>
                        {/* <th>S.No</th> */}
                        <th>Image</th>
                        <th>Name</th>
                        <th>Buying Price</th>
                        <th>Selling Price</th>

                    </tr>
                </thead>
                <tbody>
                    {filteredProducts?.map((product, index) => (
                        <tr key={product._id}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedItems.includes(product._id)}
                                    onChange={() => handleCheckboxChange(product._id)}
                                />
                            </td>
                            {/* <td>{index + 1}</td> */}
                            <td>
                                <img
                                    src={product.images[0]?.image}
                                    alt={product.name}
                                    style={{ width: "45px", height: "45px", objectFit: "cover" }}
                                />
                            </td>
                            <td>{product.englishName}/{product.tamilName}</td>
                            <td>
                                <NumberInput
                                    type="number"
                                    value={product.tempBuyingPrice}
                                    onChange={(e) => handlePriceChange(product._id, e.target.value)}
                                    className="no-arrow-input-table form-control"
                                    disabled={!selectedItems.includes(product._id)}
                                />
                            </td>
                            <td>₹ {product.tempSellingPrice} (per {product.measurement})</td>

                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Update Button */}
            <button
                className="btn btn-primary mt-3"
                onClick={handleUpdateProducts}
                disabled={loadingUpdate}
            >

                {loadingUpdate ? <LoaderButton fullPage={false} size={20} /> : (
                    <span>  Update Selected Products</span>
                )

                }
            </button>
        </div>
    );


    const setProducts = () => {
        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 's_no',
                    sort: 'disabled'
                },
                {
                    label: 'Image',
                    field: 'image',
                    sort: 'disabled'
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'disabled'
                },
                {
                    label: 'Buying Price',
                    field: 'price',
                    sort: 'disabled'
                },
                {
                    label: 'Selling Price',
                    field: 'sellprice',
                    sort: 'disabled'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    sort: 'disabled'
                }
            ],
            rows: []
        }

        // Filter products based on selected category
        let filteredProducts = products;
        if (category && category !== "All") {
            filteredProducts = products?.filter(product => product.category === category);
        }
        if (measurement && measurement !== "All") {
            filteredProducts = filteredProducts?.filter(product => product.measurement === measurement);
        }
        let allproducts = [...filteredProducts]?.sort((a, b) => a.englishName.localeCompare(b.englishName))
        allproducts && allproducts.forEach((product, index) => {
            data.rows.push({
                s_no: index + 1,
                image: (
                    <img
                        src={product.images[0].image}
                        alt={product.name}
                        style={{ width: '45px', height: '45px', objectFit: 'cover' }}
                    />
                ),
                name: product && product.range
                    ? `${(product.englishName)}/${(product.tamilName)} (${product.range})`
                    : `${(product.englishName)}/${(product.tamilName)}`,
                price: `₹ ${product.buyingPrice} (per ${product.measurement})`,
                sellprice: `₹ ${product.price} (per ${product.measurement})`,
                actions: (
                    <Fragment>
                        <Link to={`/admin/product/${product._id}`} className="btn btn-primary py-1 px-2 ml-2">
                            <i className="fa fa-pencil"></i>
                        </Link>
                        <Button onClick={() => handleDeleteClick(product._id)} className="btn btn-danger py-1 px-2 ml-2">
                            <i className="fa fa-trash"></i>
                        </Button>
                    </Fragment>
                )
            })
        })

        return data;
    }

    useEffect(() => {
        if ( productError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error( productError, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearProductStateError()) }
                });
            }, 300);
            return
        }
        if ( error) {
            toast.dismiss();
            setTimeout(() => {
                toast.error( error, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearError()) }
                });
            }, 300);
            return
        }
        if (isProductDeleted) {
            toast.dismiss();
            setTimeout(() => {
                toast.success('Product Deleted Successfully!', {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => dispatch(clearProductDeleted())
                });
                setTimeout(() => {
                    dispatch(getAdminProducts())
                }, 700);
            }, 300);
            return;
        }
    }, [dispatch, isProductDeleted, productError,error])

    useEffect(() => {
        if (!products) {
            dispatch(getAdminProducts())
        }
    }, [products])

    const handleDeleteClick = (id) => {
        setProductToDelete(id);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        dispatch(deleteProduct({ id: productToDelete }));
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };
    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        setCategory(selectedCategory);

        // Preserve existing search params
        const params = {};
        if (selectedCategory) params.category = selectedCategory;
        if (measurement) params.measurement = measurement; // Keep previous measurement filter

        setSearchParams(params);
    };

    useEffect(() => {
        const categoryFromURL = searchParams.get("category");
        if (categoryFromURL) {
            setCategory(categoryFromURL);
        }
        const measurementFromURL = searchParams.get("measurement");
        if (measurementFromURL) {
            setMeasurement(measurementFromURL);
        }
    }, [searchParams]);

    const handleMeasurementChange = (e) => {
        const selectedMeasurement = e.target.value;
        setMeasurement(selectedMeasurement);

        const params = {};
        if (category) params.category = category;
        if (selectedMeasurement) params.measurement = selectedMeasurement;

        setSearchParams(params);
    };


    return (
        <div>
            <MetaData
                title="Product List"
                description="Manage your product inventory. View, update, or remove products from your catalog to keep your store fresh and relevant."
            />


            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div >
                <div className="col-12 col-md-10 smalldevice-space loader-parent">
                    <div className="column-layout ">
                        <h1 className="mb-3 admin-dashboard-x">Product List</h1>
                        <button className="btn btn-primary" onClick={toggleEditMode}>
                            {isEditing ? "Exit Edit Mode" : "Edit"}
                        </button>
                    </div>

                    <div className="header-container">
                        {/* Back Button */}
                        <div className="">
                            <span className="back-button" onClick={() => navigate(-1)}>
                                <ArrowBackIcon fontSize="small" />
                                <span>Back</span>
                            </span>

                        </div>

                        {/* Filter */}
                        <div className="filter-container">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <label htmlFor="categoryFilter" className="form-label">Filter by Category</label>
                                <select
                                    onChange={handleCategoryChange}
                                    className="form-select"
                                    id="categoryFilter"
                                    value={category}
                                    required
                                >
                                    <option value="">All</option>
                                    {getcategory?.map(categoryItem => (
                                        <option key={categoryItem._id} value={categoryItem.category}>{categoryItem.category}</option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <label htmlFor="measurementFilter" className="form-label" style={{ marginLeft: '10px' }}>Filter by Measurement</label>
                                <select onChange={handleMeasurementChange} className="form-select" id="measurementFilter" value={measurement} required>
                                    <option value="">All</option>
                                    {getmeasurement?.map(measure => (
                                        <option key={measure._id} value={measure.measurement}>{measure.measurement}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>


                    <Fragment>
                        {loading ? (<div className="container loader-loading-center">
                            <Loader />
                        </div>) : isEditing ? (
                            renderNormalTable()
                        ) : (
                            <div className='mdb-table ' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <MDBDataTable

                                    data={setProducts()}
                                    bordered
                                    noBottomColumns
                                    hover
                                    className="px-3 product-table"
                                />
                            </div>)
                        }
                    </Fragment>

                </div>

                {showModal && (
                    <div className="modal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Confirm Delete</h5>
                                    <button type="button" className="close" onClick={handleCancelDelete}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure you want to delete this product?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" onClick={handleConfirmDelete} disabled={deleteloading}>
                                        {deleteloading ? <LoaderButton fullPage={false} size={20} /> : (
                                            <span>  OK</span>
                                        )

                                        }

                                    </button>
                                    <button type="button" className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProductList;